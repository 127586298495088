import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUser } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';

library.add({
  faUser
});


class ModalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      message: props.message
    };

    this.handleClose = this.handleClose.bind(this);

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.show !== this.state.show ) {
        this.setState({
          show: nextProps.show,
        });
        return true;
    }
    return false;
  }

  handleClose( event ) {
    this.setState({ show: false });
    event.preventDefault();
  }

  // handleShow( event ) {
  //   this.setState({ show: true });
  //   event.preventDefault();
  // }

  handleSubmit( event ) {
    event.preventDefault();

  }

  render() {
    return (
      <div className="modal show-modal modal-pay" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
        <div className="modal-content">
          <span className="close-button" onClick={ e => this.handleClose(e) }>&times;</span>
          <br />
          <div  style={ this.state.message != 1 ? { display: 'block' } : { display: 'none' } }>
            <h3 className="text-center">Come andrà il mio matrimonio?</h3>
              <p className="text-center">Scoprilo con il gioco di carte di bemylove.it!<br />Calcola la percentuale di Amore, Fortuna, Salute e Denaro che avrai nel tuo matrimonio!</p><br />
              <h4 className="text-center">Cosa aspetti? Iscriviti gratuitamente e gioca con noi!</h4><br />
              <div className="row">
                <div className="col-lg-12">
                  <p className="text-center margin-bottom-0">Vuoi sposarti?</p>
                  <Link to="/registrati">
                    <div className="btn-submit center-single-btn margin-top-0" style={{backgroundColor : '#ef5aa7', marginTop : '20px'}}>
                        Sì, lo voglio! <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          <div  style={ this.state.message == 1 ? { display: 'block' } : { display: 'none' } }>
            <h3 className="text-center">Minilove</h3>
              <p className="text-center">Divertitevi a creare i pupazzetti con le vostre facce!</p><br />
              <h4 className="text-center">Cosa aspettate? Iscrivetevi gratuitamente e divertitevi con noi!</h4><br />
              <div className="row">
                <div className="col-lg-12">
                  <p className="text-center margin-bottom-0">Vuoi sposarti?</p>
                  <Link to="/registrati">
                    <div className="btn-submit center-single-btn margin-top-0" style={{backgroundColor : '#ef5aa7', marginTop : '20px'}}>
                        Sì, lo voglio! <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ModalCard;
