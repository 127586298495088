import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class EventsLocationSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>I luoghi degli eventi - bemylove - Nessuno si perderà!</title>
          <meta name="description" content="Che matrimonio sarebbe senza gli invitati presenti al vostro ricevimento o presso le vostre nozze?" />
          <meta property="og:title" content="I luoghi degli eventi - bemylove - Nessuno si perderà!" />
          <meta property="og:url" content="https://bemylove.it/i-luoghi-degli-eventi" />
          <link rel="canonical" href="https://bemylove.it/i-luoghi-degli-eventi"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">I luoghi degli eventi</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/iw8RMbvzPNA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Cosa c’è di più utile per un invitato a un matrimonio di conoscere
                perfettamente il luogo dove si svolgeranno le nozze ed il ricevimento?<br /><br />
                Non ci saranno più segreti infatti, gli invitati conosceranno i
                luoghi principali riguardo il vostro matrimonio e con un semplice
                click e grazie a Google Maps, nessun invitato andrà perso!<br /><br />
                In questa sezione potrai far conoscere agli invitati l’indirizzo
                dello sposo, della sposa, indirizzo della chiesa e del ricevimento
                delle vostre nozze con un click 😊<br /><br />
                Che matrimonio sarebbe senza gli invitati presenti al vostro ricevimento
                o presso le vostre nozze? Tutti ma proprio tutti grazie agli
                indirizzi che metterete in <strong>bemylove</strong> tutti
                troveranno la retta via grazie a Google Maps. Nessuno vi chiamerà e vi disturberà per
                conoscere i luoghi e se lo vorrano, potranno portarvi i regali
                direttamente a casa vostra!<br /><br />
                <h3>L’esperienza di Andrea (lo sposo):</h3>
                “Quando abbiamo deciso di sposarci, non vedevamo l’ora di iniziare
                i preparativi per il nostro matrimonio. Purtroppo però il
                problema più grosso che dovevamo affrontare era: come faranno
                i parenti dello sposo ad arrivare in tutti i luoghi del
                matrimonio? Io sono originario di un piccolo paesino in Puglia,
                mentre mia moglie è siciliana e decidemmo di celebrare le nostre nozze
                in provincia di Palermo.<br /><br />
                Utilizzando <strong>bemylove</strong> è stato possibile, tra le varie funzioni,
                inserire anche tutti i luoghi del nostro matrimonio. Inserire i
                luoghi del matrimonio é davvero facile, basta inserire al momento
                dell’iscrizione l’indirizzo completo dei luoghi, in modo tale
                che gli invitati, cliccando sull’apposita icona, possano
                visualizzare il luogo tramite accesso a Google Maps. Dal nostro
                indirizzo di casa, al luogo della cerimonia, fino al luogo del
                ristorante. ogni invitato ha avuto accesso alle informazioni
                sui luoghi delle nostre nozze direttamente dal proprio smartphone.”
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EventsLocationSite;
