import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class GiftsSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Lista nozze - bemylove - Comunica con i tuoi invitati</title>
          <meta name="description" content="Siete stanchi della solita lista nozze tradizionale? Perché non dare ai tuoi invitati la possibilità di vedere in tempo reale cosa possono ancora regalarti?" />
          <meta property="og:title" content="Lista nozze - bemylove - Comunica con i tuoi invitati" />
          <meta property="og:url" content="https://bemylove.it/lista-nozze" />
          <link rel="canonical" href="https://bemylove.it/lista-nozze"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Lista nozze</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/HT_jPO9vKFU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Siete stanchi della solita <strong>lista nozze</strong> tradizionale? Perché non dare
                ai tuoi invitati la possibilità di vedere in tempo reale cosa
                possono ancora regalarti con accesso direttamente dal proprio
                smartphone?<br /><br />
                Con <strong>bemylove</strong> puoi creare la lista nozze su misura per te!<br /><br />
                Inserisci i regali che desideri, informa i tuoi invitati sul luogo
                dove poterli acquistare e sul prezzo. Niente di più utile per i
                regali di nozze! I tuoi invitati finalmente sapranno dove recarsi
                per la <strong>lista nozze</strong>, avranno accesso agli oggetti scelti da voi,
                il loro prezzo e l'indirizzo del negozio dove acquistarli!<br /><br />
                In alternativa, potranno farvi un versamento volontario
                direttamente sul vostro conto!<br /><br />
                <h3>L’esperienza di Enrico (lo zio della sposa):</h3>
                "Quando mia nipote Marika ci ha invitato al suo matrimonio eravamo
                tutti molto felici per lei. Non appena ci ha dato il codice per
                l’accesso alla piattaforma io ero molto scettico nell’usarla in
                quanto non sono molto bravo con gli strumenti di ultima
                generazione quali pc e cellulari. Devo però dire che la sezione
                che mi ha colpito di più è stata la sezione <strong>lista nozze</strong>. Marika
                e il suo compagno avevano inserito minuziosamente tutte le
                informazioni sugli oggetti che desideravano ricevere come regalo
                per le loro nozze. Hanno potuto inserire le informazioni sugli
                oggetti desiderati in un modo davvero intuitivo, modificando,
                aggiungendo o eliminando le varie voci a piacimento. La descrizione
                dell’oggetto, il luogo dove acquistarlo, il prezzo… avevano
                aggiunto tutte le informazioni utili per permetterci di acquistare
                il dono perfetto per le nozze!<br />
                Cliccando sull’icona del luogo del negozio, attraverso un
                collegamento a Google Maps, é stato possibile vedere dove si
                trovava il negozio e raggiungerlo facilmente.<br />
                Era inoltre sorprendente vedere che man mano che i regali venivano
                acquistati dagli invitati, gli sposi potevano aggiornare la lista
                e quindi far vedere cosa ancora era possibile acquistare per loro.<br />
                Alla fine però, essendo io amante dei regali tradizionali, ho
                optato per regalare loro del denaro cosicché possano acquistare
                ciò che più a loro serve."
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default GiftsSite;
