import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class WhoWeAreSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Chi siamo? - bemylove</title>
          <meta name="description" content="Ero da sola in casa, quando ad un tratto ricevetti una bellissima notizia da parte della mia migliore amica Luisa. Finalmente Gianni..." />
          <meta property="og:title" content="Chi siamo? - bemylove" />
          <meta property="og:url" content="https://bemylove.it/chi-siamo" />
          <link rel="canonical" href="https://bemylove.it/chi-siamo"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Chi siamo?</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white">
              <div className="content-img">
                <img src="img/sabrina.png" className="sabrina" width="250" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Tutto iniziò la sera del 7 Aprile del 2018. Ero da sola in casa,
                quando ad un tratto ricevetti una bellissima notizia da parte
                della mia migliore amica Luisa. Finalmente Gianni, il suo fidanzato
                storico, aveva deciso di fare il grande passo! Quel weekend
                l’aveva portata perfino a Parigi, e proprio in cima alla Tour Eiffel,
                si era inginocchiato, mostrandole un bellissimo anello e
                chiedendole di passare il resto della vita insieme.
                Ovviamente la risposta di Luisa fu “Sì!”, d’altronde, chi avrebbe
                mai potuto rifiutare dopo un tale romantico gesto? Da quel giorno
                partì l’organizzazione del più emozionante matrimonio a cui io
                abbia mai preso parte, non solo perché la sposa era la mia migliore
                amica, ma perché fu lo spunto per la creazione di <strong>bemylove</strong>.<br /><br />
                Luisa e Gianni decisero di sposarsi nel più breve tempo possibile
                e le nozze furono fissate per l’agosto seguente, dunque giusto il
                tempo di stilare il programma e lista degli invitati, decidere la
                location della cerimonia e del banchetto. Ma con così poco tempo a
                disposizione, come poter sperare nella perfetta riuscita di un
                matrimonio?<br /><br />
                Fu proprio questa necessità che mi spinse a creare <strong>bemylove</strong>.
                </p>
              </div>
            </div>
            <div className="col-lg-12 bg-white">
              <div>
                <p>
                La sezione che più è stata d’aiuto agli sposi nell’organizzazione
                del matrimonio, è stata la Lista degli impegni. Chi avrebbe
                ricordato a Gianni di noleggiare l’auto? Dove avrebbero trovato
                il tempo persino di ingaggiare una band? Solo tramite la Lista
                degli impegni Luisa e Gianni riuscirono a stilare un vero e
                proprio vademecum delle cose da fare per organizzare il matrimonio:
                dal fioraio alle bomboniere, dall’acquisto delle fedi alla
                prenotazione della chiesa, alla scelta della location. Tutto
                andò secondo i piani e nulla fu lasciato al caso! Inoltre,
                attraverso la possibilità di creare la lista degli invitati e
                tenerla costantemente aggiornata, fu un gioco da ragazzi per
                Luisa e Gianni avere sotto controllo la situazione delle presenze
                di quel giorno. Riuscirono persino a rientrare di gran lunga nel
                budget previsto, monitorando puntualmente le loro spese
                attraverso la funzione Controllo del budget ed evitando così
                le spese dell’ultimo minuto. Si sa, per due ragazzi giovani
                che stanno iniziando a progettare la loro vita insieme, ogni
                centesimo conta. A detta degli invitati giustamente, le
                funzioni che trovarono più utili di <strong>bemylove</strong> sono state dapprima
                le informazioni sui luoghi del matrimonio. Tutti, volendo essere
                puntuali alla cerimonia e curiosi di iniziare il banchetto
                insieme agli sposi, hanno usato la funzione I luoghi degli eventi,
                tramite la quale il collegamento a GoogleMaps permette di
                raggiungere facilmente il luogo della chiesa e il ristorante.
                Anche la funzione Menù di <strong>bemylove</strong> ha riscosso molto successo
                tra gli invitati. Attraverso l’accesso a loro dedicato, questi
                ultimi hanno potuto consultare il Menù del banchetto in modo
                tale da poter informare tempestivamente gli sposi per eventuali
                allergie. Non meno importate è stata giudicata la sezione
                Lista nozze. Da veri e propri ragazzi del ventunesimo secolo,
                Luisa e Gianni rifiutavano di stilare una vera e propria lista
                nozze tradizionale. Hanno quindi usato la funzione Lista nozze
                di <strong>bemylove</strong>, dove hanno aggiunto i loro oggetti desiderati, il
                prezzo e il costo dell’oggetto. E se tutto ciò non fosse ancora
                abbastanza, c’è anche la possibilità per gli invitati di fare
                un bonifico al conto degli sposi. Niente di più utile per un
                regalo perfetto. Su <strong>bemylove</strong> gli invitati hanno anche trovato
                la sezione Programma dove hanno potuto avere accesso a tutte le
                informazioni inerenti gli orari degli eventi a cui avrebbero
                partecipato al matrimonio. Dall’entrata in chiesa della sposa
                al lancio del bouquet, Luisa e Gianni hanno aggiunto le informazioni
                sul loro giorno più importante, in modo tale che fosse ricordato
                piacevolmente da tutti i loro invitati.
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default WhoWeAreSite;
