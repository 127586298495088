import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Results from '../../../partials/results/Results';
import Countdown from '../../../partials/countdown/Countdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      general: {},
      urlChiesa: "/",
      urlRicevimento: "/",
      urlCasaSposo: "/",
      urlCasaSposa: "/",
      resultsText: "Informazioni aggiornate!",
      updateInfo: false,
      loadInfo: false,
      image: "",
      nameImage: "",
      hideImage: true,
      waitSave: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setUrlChiesa = this.setUrlChiesa.bind(this);
    this.setUrlRicevimento = this.setUrlRicevimento.bind(this);
    this.setUrlCasaSposo = this.setUrlCasaSposo.bind(this);
    this.setUrlCasaSposa = this.setUrlCasaSposa.bind(this);
    this.getImage = this.getImage.bind(this);

    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-general/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            let hideImage = true;
            if( response.data.rls.image != null ) {
              hideImage = false;
            }
            this.setState({
              general: response.data.rls,
              urlChiesa: "https://www.google.it/maps/place/"+response.data.rls.chiesa,
              urlRicevimento: "https://www.google.it/maps/place/"+response.data.rls.ricevimento,
              urlCasaSposo: "https://www.google.it/maps/place/"+response.data.rls.casaSposo,
              urlCasaSposa: "https://www.google.it/maps/place/"+response.data.rls.casaSposa,
              hideImage: hideImage
            });

            this.setValue();

            let valueBase64 = {
              id: this.state.id,
              image: this.state.general.image,
              path: 'photo',
              token: localStorage.getItem("bml_session")
            }

            axios.post(process.env.REACT_APP_API_URL + '/get-base64/', valueBase64 )
                .then(response => {
                  var img = document.querySelector('#preview');
                  img.style.backgroundImage  = 'url("' + response.data.rls + '")';
                });

            //var img = document.querySelector('#preview');
            //img.src = process.env.REACT_APP_MEDIA_URL + 'photo/' + this.state.id + '/' + this.state.general.image;
            //img.style.backgroundImage  = 'url('+process.env.REACT_APP_MEDIA_URL + 'photo/' + this.state.id + '/' + this.state.general.image+')';
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });

  }

  setUrlChiesa() {
    this.setState({
      urlChiesa: "https://www.google.it/maps/place/"+this.refs.chiesa.value
    });
  }

  setUrlRicevimento() {
    this.setState({
      urlRicevimento: "https://www.google.it/maps/place/"+this.refs.ricevimento.value
    });
  }

  setUrlCasaSposo() {
    this.setState({
      urlCasaSposo: "https://www.google.it/maps/place/"+this.refs.casaSposo.value
    });
  }

  setUrlCasaSposa() {
    this.setState({
      urlCasaSposa: "https://www.google.it/maps/place/"+this.refs.casaSposa.value
    });
  }

  setValue() {
    this.refs.sposo.value = this.state.general.sposo;
    this.refs.sposa.value = this.state.general.sposa;
    this.refs.date.value = this.state.general.data;
    this.refs.chiesa.value = this.state.general.chiesa;
    //geoChiesa: '',
    this.refs.ricevimento.value = this.state.general.ricevimento;
    //geoRicevimento: '',
    this.refs.casaSposo.value = this.state.general.casaSposo;
    this.refs.casaSposa.value = this.state.general.casaSposa;
    this.refs.iban.value = this.state.general.iban;
    this.refs.email.value = this.state.general.email;
    this.refs.telefono.value = this.state.general.telefono;
    this.refs.descrizione.value = this.state.general.descrizione;
  }

  handleSubmit(event) {
    this.setState({
      loadInfo: true,
      waitSave: true
    });
    let input = {
      sposo: this.refs.sposo.value,
      sposa: this.refs.sposa.value,
      data: this.refs.date.value,
      chiesa: this.refs.chiesa.value,
      geoChiesa: '',
      ricevimento: this.refs.ricevimento.value,
      geoRicevimento: '',
      casaSposo: this.refs.casaSposo.value,
      casaSposa: this.refs.casaSposa.value,
      iban: this.refs.iban.value,
      email: this.refs.email.value,
      telefono: this.refs.telefono.value,
      descrizione: this.refs.descrizione.value,
      id: this.state.general.id,
      image: this.state.image,
      nameImage: this.state.nameImage,
      oldImage: false,
      bigImage: false,
      token: localStorage.getItem("bml_session")
    };

    axios.post(process.env.REACT_APP_API_URL + '/general/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              general: input,
              updateInfo: true,
              oldImage: false,
              waitSave: false
            });
            document.querySelectorAll("#preview-1")[0].removeAttribute("src");

            setTimeout( () => {
            this.setState({
              updateInfo: false,
              loadInfo: false
            });
          }, 3000);
          }
          else {
            // NO
            this.setState({
              updateInfo: true,
              waitSave: true
            });
            setTimeout( () => {
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }, 2000);
          }

        });
    event.preventDefault();
  }

  getImage( event ) {
    var img = document.querySelector('#preview');
    var img1 = document.querySelector('#preview-1');
    var files = event.target.files;
    if(files[0].size > 5000000) {

      img1.src = "";
      this.setState({
        bigImage: true,
        oldImage: false,
        hideImage: false
      });
      return;
    }
    else {
      this.setState({
        oldImage: true,
        bigImage: false,
        hideImage: false
      });
    }
    if (files.length > 0) {
      var file = files[0];
      //$('#snackbar').removeClass('active');
      if(file.name.indexOf('.') == -1) {
        //this.errorSnackbarService.snackbarMessage(-1, 'Nel nome dell\'immagine manca l\'estensione es: ".jpg" o ".png"', 10000);
        return;
      }

      var reader = new FileReader();
      reader.onload = (function (aImg, aImg1) {
        return function (e) {
          aImg1.src = e.target.result;
          aImg.style.backgroundImage  = 'url('+e.target.result+')';
        };
      })(img, img1);

      reader.readAsDataURL(file);

      setTimeout( () => {
        this.setState({
          image: reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""),
          nameImage: file.name
        });
      }, 1500);
    }

  }

  render() {
      let results;
      if ( this.state.loadInfo == true ) {
          results = <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
      }
    return (
      <div className="General" >
        <div id="register" className="container">
        <h2 className="text-right">
          Informazioni Generali
        </h2>

          <form onSubmit={this.handleSubmit}>
            { /* INFO SPOSI */}
            <div className="row-content">
              <div className="row">
                <div className="title-section">
                  <p>Info sposi <FontAwesomeIcon icon="ring" /><FontAwesomeIcon icon="ring" className="my-ring"/></p>
                  <hr />
                </div>
              </div>
              <div className="row content-img-general">
                <div className="col-lg-12 text-center" style={ this.state.hideImage ? { display: 'none'} : { display: 'block'} }>
                <div id="preview" className="chat-img imageGeneral" style={ this.state.oldImage == true ? { display: 'none'} : { display: 'block'} }></div>
                <img src="" id="preview-1" className="chat-img" width="250" />
                <div style={ !this.state.oldImage ? { display: 'none'} : { display: 'block', paddingTop: '10px' } }>
                  Clicca su "Salva" per salvare
                </div>
                <div style={ !this.state.bigImage ? { display: 'none'} : { display: 'block'} }>
                  Immagine troppo grande.
                </div>
                <br /><br />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="data">Data Matrimonio*</label>
                    <input ref="date" type="date" id="data" aria-describedby="Data Matrimonio" placeholder="Data Matrimonio" required />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="data">La vostra immagine <FontAwesomeIcon icon="heart" /></label>
                    <input type="file" name="file" id="file" className="" onChange={ this.getImage } />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="sposo">Nome e Cognome Sposo*</label>
                    <input ref="sposo" type="text" id="sposo" aria-describedby="Nome e Cognome dello Sposo" required />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="sposa">Nome e Cognome Sposa*</label>
                    <input ref="sposa" type="text" id="sposa" aria-describedby="Nome e Cognome della Sposa" required />
                  </div>
                </div>
              </div>
            </div>

            { /* INFO LUOGHI */}
            <div className="row-content">
              <div className="row">
                <div className="title-section title-section-second">
                  <p>Info Luoghi <FontAwesomeIcon icon="map-marker-alt" /></p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="chiesa">Indirizzo Cerimonia</label>
                    <input type="text" id="chiesa" ref="chiesa" aria-describedby="Indirizzo della Chiesa" onChange={ this.setUrlChiesa } />
                    <input type="hidden" id="geoChiesa" value="" />
                    <div className="button text-center margin-top-5">
                      <a href={ this.state.urlChiesa } target="_blank" rel="noopener noreferrer" className="tooltip" >
                        <div>
                          Controlla <FontAwesomeIcon icon="external-link-alt" />
                        </div>
                        <span className="tooltiptext" style={ {top: '-137px'} }>Assicurati che su Maps di veda esattamente il luogo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="ricevimento">Indirizzo Ricevimento</label>
                    <input type="text" id="ricevimento" ref="ricevimento" aria-describedby="Indirizzo Ricevimento" onChange={ this.setUrlRicevimento } />
                    <input type="hidden" id="geoRicevimento" value="" />
                    <div className="button text-center margin-top-5">
                      <a href={ this.state.urlRicevimento } target="_blank" rel="noopener noreferrer" className="tooltip" >
                        <div>
                          Controlla <FontAwesomeIcon icon="external-link-alt" />
                        </div>
                        <span className="tooltiptext" style={ {top: '-137px'} }>Assicurati che su Maps di veda esattamente il luogo</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="casaSposo">Indirizzo Casa Sposo</label>
                    <input type="text" id="casaSposo" ref="casaSposo" aria-describedby="Indirizzo della Casa dello Sposo" onChange={ this.setUrlCasaSposo } />
                    <div className="button text-center margin-top-5">
                      <a href={ this.state.urlCasaSposo } target="_blank" rel="noopener noreferrer" className="tooltip" >
                        <div>
                          Controlla <FontAwesomeIcon icon="external-link-alt" />
                        </div>
                        <span className="tooltiptext" style={ {top: '-137px'} }>Assicurati che su Maps di veda esattamente il luogo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="casaSposa">Indirizzo Casa Sposa</label>
                  <input type="text" ref="casaSposa" id="casaSposa" aria-describedby="Indirizzo della Casa della Sposa" onChange={ this.setUrlCasaSposa } />
                  <div className="button text-center margin-top-5">
                    <a href={ this.state.urlCasaSposa } target="_blank" rel="noopener noreferrer" className="tooltip" >
                      <div>
                        Controlla <FontAwesomeIcon icon="external-link-alt" />
                      </div>
                      <span className="tooltiptext" style={ {top: '-137px'} }>Assicurati che su Maps di veda esattamente il luogo</span>
                    </a>
                  </div>
                </div>
                </div>
              </div>
            </div>

            { /* ALTRE INFO */}
            <div className="row-content">
              <div className="col">
                <div className="title-section title-section-five">
                  <p>Altre Info <FontAwesomeIcon icon="info-circle" /></p>
                  <hr />
                </div>
              </div>
              <div className="row general-white">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <p>Codice accesso Sposi</p>
                    <p className="border-bottom-color" >{ this.state.general.loginSposi }</p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <p>Codice accesso Invitati</p>
                    <p className="border-bottom-color" >{ this.state.general.loginInvitati }</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="iban">IBAN</label>
                    <input autoComplete="off" ref="iban" type="text" id="iban" aria-describedby="Inserisci qui l'IBAN" maxLength="27" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email">Email*</label>
                    <input ref="email" type="email" id="email" aria-describedby="Inserisci qui l'email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="telefono">Telefono*</label>
                    <input ref="telefono" type="tel" id="telefono" aria-describedby="Inserisci qui il telefono" required minLength="10" maxLength="13" pattern="[+]+[0-9]{12}"/>
                    <small><i>(compreso il prefisso internazionale Es. +39)</i></small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      bemyStory <br />
                      <small><i>(Raccontate come vi siete conosciuti, raccontate agli
                        invitati come vorreste che fosse il giorno più importante della
                        vostra vita se ad esempio, vorreste che tutti gli invitati quel
                        giorno siano in costume, questo è lo spazio giusto per comunicarlo!)</i></small>
                    </label>
                    <textarea ref="descrizione" id="descrizione" ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="col button-submit button-hover-submit">
              <button type="submit" style={ this.state.waitSave ? { display: 'none'} : { display: 'block' } }>Salva<br /><FontAwesomeIcon icon="check" /></button>
            </div>
            <p className="waitSave text-center" style={ !this.state.waitSave ? { display: 'none'} : { display: 'block' } }>Salvataggio in corso...</p>

          </form>

        </div>
        { results }
      </div>
    );
  }
}

export default General;
