import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleTwelve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Fedi nunziali: tutti i consigli per scegliere quelle perfette per voi!</title>
          <meta name="description" content="L’anello simboleggia da sempre l’importanza di un amore senza fine. La scelta delle fedi nuziali è un momento importantissimo nell’organizzazione del matrimonio, in quanto si tratta di un oggetto con un’importanza simbolica elevata, che vi accompagnerà per tutta la vita." />
          <meta property="og:title" content="Fedi nunziali: tutti i consigli per scegliere quelle perfette per voi!" />
          <meta property="og:url" content="https://bemylove.it/blog/fedi-nunziali-tutti-i-consigli-per-scegliere-quelle-perfette-per-voi" />
          <link rel="canonical" href="https://bemylove.it/blog/fedi-nunziali-tutti-i-consigli-per-scegliere-quelle-perfette-per-voi" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/twelve/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Fedi nunziali: tutti i consigli per scegliere quelle perfette per voi!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  10/01/2021
                </time>
              </div>
              <div className="content">
                L’anello simboleggia da sempre l’importanza di un amore senza fine.
                La scelta delle fedi nuziali è un momento importantissimo
                nell’organizzazione del matrimonio, in quanto si tratta di un
                oggetto con un’importanza simbolica elevata, che vi accompagnerà
                per tutta la vita.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Avete già scelto le vostre fedi? Non dimenticare di aggiungere
                  questa attività sulla lista degli impegni di Bemylove!
                </a>
                <hr />
                Scegliere una fede nuziale, però, non è sempre facile come si pensa;
                la scelta della fede è una decisione strettamente soggettiva e
                non deve essere influenzata da tradizioni o pressioni da parti
                esterne alla coppia. La fede deve essere scelta seguendo i propri
                gusti e desideri, cercando che sia – se non uguale – il più simile
                possibile a quella del partner. <br />
                Ecco alcuni consigli per scegliere quelle perfette per voi.
                <br /><br />
                <div>
                  <img src="../media/blog/twelve/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <strong>A chi spetta l’acquisto delle fedi nunziali?</strong><br />
                Le regole del galateo dicono che l’acquisto delle fedi spetti allo sposo.
                Eppure negli ultimi tempi si è imposta la tendenza tra gli sposi
                a dividere equamente la spesa per questi oggetti così importanti.
                Infatti, in media, una coppia di fedi in oro costa tra le 300 e
                le 800 euro. C’è inoltre la possibilità che uno dei testimoni si
                proponga per l’acquisto delle fedi.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <strong>Quanto tempo prima bisogna acquistare le fedi?</strong><br />
                Come la location, l’abito della sposa e il catering, è bene non
                rimandare l’acquisto delle fedi all’ultimo momento. Sarebbe ideale
                sceglierle almeno 3 mesi prima del giorno del vostro Si, poiché
                dovrete tenere in conto che, ad esempio, dovrete decidere materiali,
                personalizzazione, misure o inoltre i produttori potrebbero
                richiedere anche un mese e mezzo di tempo per produrre il modello
                che avete scelto, se non immediatamente disponibile.
                <br /><br />
                <img src="../media/blog/twelve/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                <strong>Oro o altri materiali? </strong><br />
                È molto importante scegliere il materiale delle fedi che intendiamo
                acquistare. Il più utilizzato è l’oro giallo, bianco o rosa
                (o una combinazione dei tre!), di varia caratura a seconda del budget.
                Esistono però anche altri materiali che vengono impiegati per la
                realizzazione delle fedi, come ad esempio l’argento, il titanio
                o il platino, tutte alternative ottime ad un costo decisamente
                più accessibile.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <strong>Stile classico o moderno?</strong><br />
                Lo stile delle fedi deve assolutamente piacere ad entrambi poiché
                porterete questo anello per tutta la vita. In primo luogo, bisogna
                scegliere la forma, tra fedi rotonde e quadrate, ma anche con
                superficie piatta o arrotondata. Anche per la finitura ci sono
                diverse possibilità: lucidatura, satinatura, effetto ghiaccio,
                sabbia opaca, pennello, fiorentina, punteggiata o smaltata.
                Le fedi nuziali classiche rappresentano un semplice cerchio,
                senza ulteriori lavorazioni e proprio per questo design pulito
                e sobrio sono ancora quelle più vendute. Tra le fedi classiche
                c’è la francesina, un modello sottile e leggermente bombato e
                la fede mantovana, un modello leggermente più alto e piatto.
                Le fedi particolari, invece, possono avere diversi colori di ori,
                pietre incastonate o dimensioni non classiche.
                <br /><br />
                <img src="../media/blog/twelve/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                <strong>Personalizzazione: cosa scrivere?</strong><br />
                Personalizzare le proprie fedi nuziali è una tradizione alla quale
                non si può scappare. All’interno è usuale incidere il nome della
                persona amata e la data del matrimonio ma sono sempre più
                frequenti le incisioni di parole significative per i due innamorati
                o intere frasi, anche in latino. Qui non ci sono regole ma solo
                gusto personale. Anche se in molti ti chiederanno il significato
                della tua incisione, ricordatevi che la personalizzazione è la
                chiave per scegliere anche il dettaglio più piccolo di un matrimonio
                da favola.
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleTwelve;
