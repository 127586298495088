import React, { Component } from 'react';

import { ReactComponent as Logo } from '../../svg/bemylove_logo_2.svg';
import Cta from '../partials/Cta';

import { Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

library.add(
  faMapMarkedAlt,
  faMale,
  faFemale
)

class Header extends Component {
  constructor(props) {

    super(props);

    this.state = {
      menu: false,
      isMobile: window.innerWidth < 1000 ? '50' : '60',
      colorNav: props.ctrl == 1 ? false : true,
      showCookie: true,
      register: 'none'
    };

    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  componentDidMount() {

    if( localStorage.getItem('bml_cookie') && localStorage.getItem('bml_cookie') == 1 ) {
      this.setState({
        showCookie: false
      });
    }

    if( localStorage.getItem('bml_session') ) {
      this.setState({
        register: 'none'
      });
    }
    else {
      if( this.state.isMobile == '50' ) {
        this.setState({
          register: 'none'
        });
      }
      else {
        this.setState({
          register: 'block'
        });
      }
    }

    window.addEventListener("scroll", (event) => {
      if( window.scrollY > 150 && this.props.ctrl == 0 ) {
        this.setState({
          colorNav: false
        });
        if( document.getElementById("nav-site") != null ) {
          document.getElementById("nav-site").classList.add("run");
          document.getElementById("nav-site").classList.remove("start");
          document.getElementById("nav-bar").classList.add("run");
          document.getElementById("nav-bar").classList.remove("start");
          document.getElementById("list-menu-site").classList.add("run");
          document.getElementById("list-menu-site").classList.remove("start");
          document.getElementById("list-menu-site").classList.add("list-menu-site-hover-one");
          document.getElementById("list-menu-site").classList.remove("list-menu-site-hover-two");
        }
      }
      else if( this.props.ctrl == 0 ){
        this.setState({
          colorNav: true
        });
        if( document.getElementById("nav-site") != undefined ) {
          document.getElementById("nav-site").classList.add("start");
          document.getElementById("nav-site").classList.remove("run");
          document.getElementById("nav-bar").classList.add("start");
          document.getElementById("nav-bar").classList.remove("run");
          document.getElementById("list-menu-site").classList.add("start");
          document.getElementById("list-menu-site").classList.remove("run");
          document.getElementById("list-menu-site").classList.add("list-menu-site-hover-two");
          document.getElementById("list-menu-site").classList.remove("list-menu-site-hover-one");
        }
      }

    });
  }

  openMenu() {
    this.setState({
      menu: !this.state.menu
    });
  }

  handleClose() {
    localStorage.setItem("bml_cookie", 1);
    this.setState({
      showCookie: false
    });
  }

  getLabel() {
    if( localStorage.getItem('bml_session') ) {
      return "Il mio Account";
    }
    else {
      return "Login";
    }
  }

  getClass() {
    if( localStorage.getItem('bml_session') ) {
      return "col-lg-6 col-sm-9 col-9 content-nav-brand";
    }
    else {
      return "col-lg-4 col-sm-9 col-9 content-nav-brand";
    }
  }

  render() {
    return (
      <div>
        <div className="banner-cookie" style={this.state.showCookie ? { display: 'block' } : { display: 'none' } }>
          <p className="text-center">
            I cookie ci permettono offrire i nostri servizi. Nell'utilizzare i
            nostri servizi, accetti l'uso che facciamo dei cookie.
            <Link to="/cookies" className="legal-link" target="_blank">
              Ulteriori informazioni
            </Link>
            <span className="close-button" onClick={this.handleClose}>&times;</span>
          </p>
        </div>

        <nav id="nav-site" className="row nav-site padding-top-5 padding-bottom-10 start">
          <div className={ this.getClass() }>
            <div className="nav-brand">
              <Link to="/">
                <Logo className="logo" width={ this.state.isMobile } height={ this.state.isMobile } /> <span id="title">bemylove</span><br /><small className="made-in-italy"></small>
              </Link>
            </div>
          </div>

          <div className="col-lg-3 menu-1"style={ this.state.isMobile == '60' ? { display : 'block' } : { display : 'none' } }>
            <Link to="/cose-da-sapere-prima-di-sposarsi">
              <span>Cose da sapere prima di sposarsi</span>
            </Link>
          </div>

          <div className="col-lg-2 menu-1"style={ this.state.isMobile == '60' ? { display : 'block' } : { display : 'none' } }>
            <Link to="/login">
              <span>{ this.getLabel() } <FontAwesomeIcon icon="sign-in-alt" /></span>
            </Link>
          </div>

          <div className="col-lg-2 menu-1" style={{ display : this.state.register }}>
            <Link to="/registrati">
              <span>Registrati <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" /></span>
            </Link>
          </div>

          <div className="col-lg-1 col-sm-3 col-3 padding-top-10 content-nav-brand">
            <span id="nav-bar" className={this.state.menu ? 'change navbar-toggler start' : 'navbar-toggler start'} onClick={this.openMenu}>
              <div className="bar1" style={ this.state.colorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : '#d77eac' } }></div>
              <div className="bar2" style={ this.state.colorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : '#d77eac' } }></div>
              <div className="bar3" style={ this.state.colorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : '#d77eac' } }></div>
            </span>
          </div>

          {/*
          <div id="nav-desktop" className={this.state.colorNav ? 'nav-desktop-hover-1' : 'nav-desktop-hover-2'}>
            <ul>
              <li className="li-brand">
                <div className="nav-brand-desktop">
                  <Link to="/">
                    <Logo className="logo" width={ this.state.isMobile } height={ this.state.isMobile } /> <span id="title">bemylove</span><br /><small className="made-in-italy">100% made in italy</small>
                  </Link>
                </div>
              </li>
              <li className={this.state.tab === 'chi-siamo' ? 'active nav-item' : 'nav-item'}>
                <Link to="/chi-siamo" className="">Chi siamo?</Link>
              </li>
              <li className="submenu">
                Come aiutiamo gli sposi?
                <ul>
                  <li className={this.state.tab === 'inizia' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/inizia" className="">Inizia</Link>
                  </li>
                  <li className={this.state.tab === 'lista-nozze' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/lista-nozze" className="">Lista nozze</Link>
                  </li>
                  <li className={this.state.tab === 'programma-delle-nozze' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/programma-delle-nozze" className="">Programma delle nozze</Link>
                  </li>
                  <li className={this.state.tab === 'lista-invitati' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/lista-invitati">Lista invitati</Link>
                  </li>
                  <li className={this.state.tab === 'menu-di-nozze' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/menu-di-nozze" className="">Menù di nozze</Link>
                  </li>
                  <li className={this.state.tab === 'controllo-budget' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/controllo-budget" className="">Controllo del Budget</Link>
                  </li>
                  <li className={this.state.tab === 'lista-impegni' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/lista-impegni" className="">Lista degli impegni</Link>
                  </li>
                  <li className={this.state.tab === 'i-luoghi-degli-eventi' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/i-luoghi-degli-eventi" className="">I luoghi degli eventi</Link>
                  </li>
                  <li className={this.state.tab === 'chat' ? 'active nav-item' : 'nav-item'}>
                    <Link to="/chat" className="">Chat</Link>
                  </li>
                </ul>
              </li>
              <li className={this.state.tab === 'come-andra-il-mio-matrimonio' ? 'active nav-item' : 'nav-item'} style={{ display: 'block', lineHeight: 1 }}>
                <Link to="/come-andra-il-mio-matrimonio" className="">Come andrà il mio matrimonio?</Link>
              </li>
              <li className={this.state.tab === 'minilove' ? 'active nav-item' : 'nav-item'} style={{ display: 'block' }}>
                <Link to="/minilove" className="">MiniLove</Link>
              </li>
              <li className="nav-item">
                <Link to="/login" className="">Il mio Account <FontAwesomeIcon icon="sign-in-alt" /></Link>
              </li>
              <li className={this.state.tab === 'chat' ? 'active nav-item' : 'nav-item'} style={ this.state.isMobile == '50' ? { display : 'block' } : { display : 'none' } }>
                <Link to="/login">
                  <span>{ this.getLabel() } <FontAwesomeIcon icon="sign-in-alt" /></span>
                </Link>
              </li>
            </ul>
          </div>
          */}
        </nav>
        <div id="list-menu-site" className="row list-menu-site start list-menu-site-hover-two" style={this.state.menu ? { display: 'block' } : { display: 'none' } }>
          <ul>
            <li className={this.state.tab === 'cose-da-sapere-prima-di-sposarsi' ? 'active nav-item' : 'nav-item'}>
              <Link to="/cose-da-sapere-prima-di-sposarsi" className="">Cose da sapere prima di sposarsi</Link>
            </li>
            <li className={this.state.tab === 'chi-siamo' ? 'active nav-item' : 'nav-item'}>
              <Link to="/chi-siamo" className="">Chi siamo?</Link>
            </li>
            <li className={this.state.tab === 'inizia' ? 'active nav-item' : 'nav-item'}>
              <Link to="/inizia" className="">Inizia</Link>
            </li>
            <li className={this.state.tab === 'lista-nozze' ? 'active nav-item' : 'nav-item'}>
              <Link to="/lista-nozze" className="">Lista nozze</Link>
            </li>
            <li className={this.state.tab === 'programma-delle-nozze' ? 'active nav-item' : 'nav-item'}>
              <Link to="/programma-delle-nozze" className="">Programma delle nozze</Link>
            </li>
            <li className={this.state.tab === 'lista-invitati' ? 'active nav-item' : 'nav-item'}>
              <Link to="/lista-invitati">Lista invitati</Link>
            </li>
            <li className={this.state.tab === 'menu-di-nozze' ? 'active nav-item' : 'nav-item'}>
              <Link to="/menu-di-nozze" className="">Menù di nozze</Link>
            </li>
            <li className={this.state.tab === 'controllo-budget' ? 'active nav-item' : 'nav-item'}>
              <Link to="/controllo-budget" className="">Controllo del Budget</Link>
            </li>
            <li className={this.state.tab === 'lista-impegni' ? 'active nav-item' : 'nav-item'}>
              <Link to="/lista-impegni" className="">Lista degli impegni</Link>
            </li>
            <li className={this.state.tab === 'i-luoghi-degli-eventi' ? 'active nav-item' : 'nav-item'}>
              <Link to="/i-luoghi-degli-eventi" className="">I luoghi degli eventi</Link>
            </li>
            <li className={this.state.tab === 'chat' ? 'active nav-item' : 'nav-item'}>
              <Link to="/chat" className="">Chat</Link>
            </li>
            <li className={this.state.tab === 'come-andra-il-mio-matrimonio' ? 'active nav-item' : 'nav-item'} style={{ display: 'block' }}>
              <Link to="/come-andra-il-mio-matrimonio" className="">Come andrà il mio matrimonio?</Link>
            </li>
            <li className={this.state.tab === 'minilove' ? 'active nav-item' : 'nav-item'} style={{ display: 'block' }}>
              <Link to="/minilove" className="">MiniLove</Link>
            </li>
            <li className={this.state.tab === 'login' ? 'active nav-item' : 'nav-item'} style={ this.state.isMobile == '50' ? { display : 'block' } : { display : 'none' } }>
              <Link to="/login">
                <span>{ this.getLabel() } <FontAwesomeIcon icon="sign-in-alt" /></span>
              </Link>
            </li>
            <li className={this.state.tab === 'registrati' ? 'active nav-item' : 'nav-item'} style={{ display : this.state.register }}>
              <Link to="/registrati">
                <span>Registrati <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" /></span>
              </Link>
            </li>
          </ul>
        </div>
        <Cta ctrl={ this.props.ctrl } />
      </div>
    );
  }
}

export default Header;
