import React, { Component } from 'react';
import axios from 'axios';
import GiftLists from '../../../partials/gifts/GiftLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Results from '../../../partials/results/Results';
import VideoTutorial from '../../../partials/video-tutorial/VideoTutorial';


class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allGifts: [],
      updateInfo: false,
      resultsText: "Lista nozze aggiornata!",
      loadInfo: false
    };

    this.updateGifts = this.updateGifts.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-gifts/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allGifts: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  updateGifts( event ) {
    this.setState({
      loadInfo: true
    });
    let input = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      gifts: this.state.allGifts
    };

    axios.post(process.env.REACT_APP_API_URL + '/update-gifts/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              updateInfo: true
            });

            setTimeout( () => {
            this.setState({
              updateInfo: false,
              loadInfo: false
            });
            }, 2000);
          }
          else {
            // NO
            this.setState({
              updateInfo: true,
              loadInfo: false
            });
            setTimeout( () => {
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }, 2000);
          }

        });
    event.preventDefault();
  }

  render() {
    let results;
    if ( this.state.loadInfo == true ) {
        results = <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
    }
    return (
      <div id="register" className="List" >
        <h2 className="text-right">
          Lista nozze 
          <VideoTutorial url="https://youtu.be/HT_jPO9vKFU" />
        </h2>
        <GiftLists allGifts={ this.state.allGifts } user_id={ this.state.id } isRegister="0" />
        <div className="row text-center">
          <div className="col center-single-btn">
            <button type="submit" className="button" onClick={ this.updateGifts }>Salva Cambiamenti <FontAwesomeIcon icon="chevron-right" /></button>
          </div>
        </div>
        { results }
      </div>
    );
  }
}

export default List;
