import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class ProgramsSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Programma delle nozze - bemylove</title>
          <meta name="description" content="Con la sezione Programma nessun invitato andrà perso! Infatti grazie a Google Maps tutti troveranno le location delle nozze." />
          <meta property="og:title" content="Programma delle nozze - bemylove" />
          <meta property="og:url" content="https://bemylove.it/programma-delle-nozze" />
          <link rel="canonical" href="https://bemylove.it/programma-delle-nozze"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Programma delle nozze</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/15Emsd82seI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                <h3>L’esperienza di Claudia (la sposa):</h3>
                “Quando Antonio mi ha chiesto di sposarlo ero felicissima e non
                vedevo l’ora che arrivasse il grande giorno. Per questo, essendo
                lui molto occupato a lavoro, ho deciso di dedicarmi al 100%
                all’organizzazione del matrimonio e quindi ho iniziato a cercare
                su internet una piattaforma che potesse aiutarmi per arrivare
                al giorno del Sì, senza aver dimenticato nulla!<br /><br />
                Tra le varie sezioni che mi hanno aiutato nella preparazione delle
                nozze, la compilazione del programma è stata la più divertente.
                Generalmente si tende a evitare di svelare troppi dettagli
                delle nozze, ma mi piaceva l’idea che i miei invitati avessero
                accesso alle informazioni sul nostro matrimonio.<br /><br />
                Ho potuto inserire dettagli quali orari, luoghi e descrizioni
                degli eventi in programma direttamente con un click! Nessuna
                delle mie conoscenti lo aveva mai fatto e quindi è spesso capitato
                di esserci trovati impreparati durante qualche matrimonio.
                Ad esempio, io e Antonio abbiamo scelto di festeggiare le nozze
                in una bellissima villa con piscina alle porte di Milano.
                Perchè non aggiungere un emozionante tuffo di mezzanotte? I nostri
                invitati sono stati informati tramite la sezione programma di
                <strong>bemylove</strong> e quindi nessuno ha dimenticato di portare il costume!<br /><br />
                Inoltre per i nostri parenti e amici che non sono originari della
                zona, <strong>bemylove</strong> è stato essenziale per aiutarli a trovare i luoghi
                giusti tramite l’accesso a Google Maps. Chi avrebbe mai potuto
                guidarli a destinazione se non avessimo inserito tutte le
                informazioni necessarie su <strong>bemylove</strong>?<br /><br />
                Inserire tutte le informazioni nella sezione programma è stato
                molto facile. Dopo aver aggiunto l’orario (approssimativo ovviamente!),
                bisogna inserire il titolo dell’evento, ad esempio “pranzo nunziale”
                o ancora “aperitivo in Giardino” ed inoltre il luogo, aggiungendo
                l’indirizzo completo. Cliccando su aggiungi, le voci verranno
                automaticamente inserite alla lista e ci sarà successivamente
                la possibilità di modificarle o eliminarle a piacimento.
                Sarà infine possibile cliccare su Salva cambiamenti per far
                apparire il vostro Programma completo direttamente sugli
                smartphone dei vostri invitati!”
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ProgramsSite;
