import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faThList, faUtensils } from '@fortawesome/free-solid-svg-icons/index';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Results from '../../../partials/results/Results';

library.add({
  faTrash,
  faUtensils,
  faThList
});

var menu = {
  id: 0,
  what: '',
  type: 0
};

var type = ['Antipasti', 'Primi', 'Secondi', 'Contorni', 'Dolci', 'Bevande'];

const DragHandle = SortableHandle((singleMenu) =>
        <div className="col-lg-1 col-12 drag" style={ window.innerWidth < 1000 ? { display: 'none' } : { display: 'block' }}>
          <FontAwesomeIcon icon="grip-lines" className="margin-icons" />
        </div>
  );


const SortableMenu = SortableElement(({menutList, index, removeMenu}) =>
    <div className="row" key={index}>
      <DragHandle singleMenu={ menutList } />
      <div className="col-lg-10 col-10">
        <div className="row" style={{ borderBottom: "0" }}>
          <div className="col-lg-4 col-12">
            <div className="d-flex justify-content-start"><FontAwesomeIcon icon="th-list" className="margin-icons" /> { type[menutList.type] }</div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-start"><FontAwesomeIcon icon="utensils" className="margin-icons" /> { menutList.what }</div>
          </div>
        </div>
      </div>
      <div className="col-lg-1 col-12 text-center tooltip">
        <FontAwesomeIcon icon="trash" className="margin-top-50" onClick={()=> removeMenu(index)} />
        <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-20px', left: '40px' } }>Elimina questo elemento</span>
      </div>
    </div>
  );

const SortableMenuList = SortableContainer(({allMenu, removeMenu}) => {
  return (
    <div className="content-elms">
      {allMenu.map((value, index) => (
        <SortableMenu key={`item-${index}`} index={index} menutList={value} removeMenu={removeMenu} />
      ))}
    </div>
  );
});

class MenuLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allMenu: this.props.allMenu,
      resultsText: "Clicca su \"Salva cambiamenti\" per confermare!",
      updateInfo: false
    };

    this.whatMenuChange = this.whatMenuChange.bind(this);

    this.addMenu = this.addMenu.bind(this);
    this.removeMenu = this.removeMenu.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.allMenu !== this.state.allMenu ) {
        this.setState({
          allMenu: nextProps.allMenu
        });
        return true;
    }
    return false;
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ allMenu }) => ({
      allMenu: arrayMove(allMenu, oldIndex, newIndex),
    }));

    this.props.allMenu.length = 0;

    this.state.allMenu.forEach( menuPush => {
      this.props.allMenu.push(menuPush);
    });

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);
  };

  whatMenuChange(event) {
    menu.what = event.target.value;
  }

  addMenu() {

    menu.type = this.refs.MenuType.value;

    if( menu.what !== '' ) {

      let tmpMenu = this.state.allMenu;

      tmpMenu.push(menu);

      this.setPropsState( tmpMenu );

      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);

      menu = {
        id: 0,
        what: '',
        type: 0
      };

      this.refs.MenuWhat.value = '';
      this.refs.MenuType.value = 0;
    }
  }

  removeMenu(index) {

    let elm = this.state.allMenu;

    elm.splice( index , 1);

    this.setPropsState( elm );

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);

  }

  setPropsState( menu ) {
    this.setState({allMenu: menu});

    if( this.state.allMenu.length !== this.props.allMenu.length ) {
      this.props.allMenu.length = 0;

      this.state.allMenu.forEach( menuPush => {
        this.props.allMenu.push(menuPush);
      });
    }
  }

  render() {
    return (
      <div>
        { /* MENU */}
        <div className="row-content">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="whatMenu">Cosa?</label>
                <input type="text" aria-describedby="Cosa?" ref="MenuWhat" onChange={ this.whatMenuChange } />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="typeMenu">Tipo di pasto?</label><br />
                <select className="select" ref="MenuType">
                  {type.map((value, index) => (
                    <option key={index} value={index}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="center-single-btn button" onClick={this.addMenu}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
          </div>
          <SortableMenuList allMenu={this.state.allMenu} onSortEnd={this.onSortEnd} removeMenu={this.removeMenu} useDragHandle  />
        </div>
        <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
      </div>
  )};
}

export default MenuLists;
