import React, { Component } from 'react';
import axios from 'axios';

import MetaTags from 'react-meta-tags';

class ConfirmAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: this.props.match.params.otp
    };
  }

  componentDidMount() {
    let value = {
      otp: this.state.otp
    }
    axios.post(process.env.REACT_APP_API_URL + '/set-account/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            setTimeout( () => {
              this.props.history.push(
                '/login',
                {
                  account: true
                }
              );
            }, 5000);

          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  render() {
    return (
      <div id="register" className="ConfirmInvite">
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <div className="row">
          <div className="content-elms">
            <h2 className="text-center col-lg-12">Grazie!</h2>
            <p className="text-center">Il tuo account è adesso abilitato!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmAccount;
