import React, { Component } from 'react';
import axios from 'axios';
import MenuLists from './MenuLists'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Results from '../../../partials/results/Results';
import VideoTutorial from '../../../partials/video-tutorial/VideoTutorial';


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allMenu: [],
      updateInfo: false,
      resultsText: "Menu aggiornato!"
    };

    this.updateMenu = this.updateMenu.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-menu/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allMenu: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  updateMenu( event ) {
    let input = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      menu: this.state.allMenu
    };

    axios.post(process.env.REACT_APP_API_URL + '/update-menu/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              updateInfo: true
            });
            setTimeout( () => {
            this.setState({
              updateInfo: false
            });
            }, 2000);
          }
          else {
            // NO
            this.setState({
              updateInfo: true
            });
            setTimeout( () => {
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }, 2000);
          }

        });
    event.preventDefault();
  }

  render() {
    return (
      <div id="register" className="List" >
      <h2 className="text-right">
        Menu di nozze
        <VideoTutorial url="https://youtu.be/3jvYdC_9kFw" />
      </h2>
        <MenuLists allMenu={ this.state.allMenu } />
        <div className="row text-center">
          <div className="col center-single-btn">
            <button
              type="submit"
              className="button"
              onClick={ this.updateMenu }>
              Salva Cambiamenti <FontAwesomeIcon icon="chevron-right" />
            </button>
          </div>
          <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
        </div>
      </div>
    );
  }
}

export default Menu;
