import React, { Component } from 'react';
import axios from 'axios';
import ProgramLists from '../partials/programs/ProgramLists';
import GiftLists from '../partials/gifts/GiftLists';
import RegisterSuccess from './RegisterSuccess';
import RegisterError from './RegisterError';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import VideoTutorial from '../partials/video-tutorial/VideoTutorial';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndoAlt,
  faChevronRight,
  faUsersCog,
  faRing,
  faMapMarkerAlt,
  faExternalLinkAlt,
  faPlus,
  faGifts,
  faInfoCircle,
  faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

import MetaTags from 'react-meta-tags';

library.add({
  faUndoAlt,
  faChevronRight,
  faUsersCog,
  faRing,
  faMapMarkerAlt,
  faExternalLinkAlt,
  faPlus,
  faGifts,
  faInfoCircle,
  faClipboardList
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
      badTime: false,
      allPrograms: [],
      error: false,
      badPrice: false,
      allGifts: [],
      loginSposi: '',
      loginInvitati: '',
      registerSuccessSubmit: false,
      registerErrorSubmit: false,
      registerErrorMessage: '',
      urlChiesa: "/",
      urlRicevimento: "/",
      urlCasaSposo: "/",
      urlCasaSposa: "/",
      friendCode: localStorage.getItem('bml_friend') ? localStorage.getItem('bml_friend') : ''
    };


    this.handleSubmit = this.handleSubmit.bind(this);
    this.setUrlChiesa = this.setUrlChiesa.bind(this);
    this.setUrlRicevimento = this.setUrlRicevimento.bind(this);
    this.setUrlCasaSposo = this.setUrlCasaSposo.bind(this);
    this.setUrlCasaSposa = this.setUrlCasaSposa.bind(this);

    this.setTest = this.setTest.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(event) {
    let input = {
      sposo: this.refs.sposo.value,
      sposa: this.refs.sposa.value,
      data: this.refs.date.value,
      chiesa: this.refs.chiesa.value,
      geoChiesa: '',
      ricevimento: this.refs.ricevimento.value,
      geoRicevimento: '',
      casaSposo: this.refs.casaSposo.value,
      casaSposa: this.refs.casaSposa.value,
      iban: this.refs.iban.value,
      email: this.refs.email.value,
      telefono: this.refs.telefono.value,
      descrizione: this.refs.descrizione.value,
      programs: this.state.allPrograms,
      gifts: this.state.allGifts,
      friendCode: this.state.friendCode
    };

    axios.post(process.env.REACT_APP_API_URL + '/register/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              registerSuccessSubmit: true,
              loginSposi: response.data.loginSposi,
              loginInvitati: response.data.loginInvitati,
              id: response.data.id,
              email: response.data.email,
              otp: response.data.otp
            });

            this.sendEmail();
          }
          else {
            // NO
            this.setState({
              registerErrorSubmit: true,
              registerErrorMessage: response.data.error.rls
            });
          }

        });
    event.preventDefault();
  }

  sendEmail() {

    let input = {
      user_id: this.state.id,
      email: this.state.email,
      otp: this.state.otp,
      url: window.location.host
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-register/', input);

    let inputAdmin = {
      email: this.state.email,
      ctrl: 'registrazione'
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-admin/', inputAdmin);

  }

  setUrlChiesa() {
    this.setState({
      urlChiesa: "https://www.google.it/maps/place/"+this.refs.chiesa.value
    });
  }

  setUrlRicevimento() {
    this.setState({
      urlRicevimento: "https://www.google.it/maps/place/"+this.refs.ricevimento.value
    });
  }

  setUrlCasaSposo() {
    this.setState({
      urlCasaSposo: "https://www.google.it/maps/place/"+this.refs.casaSposo.value
    });
  }

  setUrlCasaSposa() {
    this.setState({
      urlCasaSposa: "https://www.google.it/maps/place/"+this.refs.casaSposa.value
    });
  }

  setTest() {
    this.refs.sposo.value = 'Nome e Cognome Sposo';
    this.refs.sposa.value = 'Nome e Cognome Sposa';
    this.refs.date.value = '2019-03-10';
    this.refs.chiesa.value = 'via della chiesa';
    //geoChiesa: '',
    this.refs.ricevimento.value = 'via del ricevimento';
    //geoRicevimento: '',
    this.refs.casaSposo.value = 'via dello sposo';
    this.refs.casaSposa.value = 'via della sposa';
    this.refs.iban.value = 'ilmioiban';
    this.refs.email.value = 'test@test.com';
    this.refs.telefono.value = '+393333333333';
     this.refs.descrizione.value = 'Una descrizione di test';
  }

  render() {
    return (
      <div id="register" className="Register back">
        <MetaTags>
          <title>Registrati - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Registrati - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>

        <nav className="navbar fixed-top navbar-expand-lg">
          <div className="nav-brand">
            <Link to="/">
              <img src="/img/bemylove_logo.png" width="80" /> bemylove<br /><small className="made-in-italy">100% made in italy</small>
            </Link>
          </div>
        </nav>

        <div className="container" style={ !this.state.registerSuccessSubmit && !this.state.registerErrorSubmit ? { display: 'block' } : { display: 'none' } }>
          <h2 className="text-right">
            <strong>Registrati</strong>
            <VideoTutorial url="https://youtu.be/I6oPDcZi6h4" />
          </h2>

            <form onSubmit={this.handleSubmit}>

            { /* INFO SPOSI */}
            <div className="row-content">
              <div className="row">
                <div className="title-section">
                  <p>Info sposi <FontAwesomeIcon icon="ring" /><FontAwesomeIcon icon="ring" className="my-ring"/></p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 offset-lg-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="data">Data Matrimonio*</label>
                    <input ref="date" type="date" id="data" aria-describedby="Data Matrimonio" placeholder="Data Matrimonio" required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="sposo">Nome e Cognome Sposo*</label>
                    <input ref="sposo" type="text" id="sposo" aria-describedby="Nome e Cognome dello Sposo" required />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="sposa">Nome e Cognome Sposa*</label>
                    <input ref="sposa" type="text" id="sposa" aria-describedby="Nome e Cognome della Sposa" required />
                  </div>
                </div>
              </div>
            </div>

          { /* INFO LUOGHI */}
          <div className="row-content">
            <div className="row">
              <div className="title-section title-section-second">
                <p>Info Luoghi <FontAwesomeIcon icon="map-marker-alt" /></p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="chiesa">Indirizzo Cerimonia</label>
                  <input type="text" id="chiesa" ref="chiesa" aria-describedby="Indirizzo della Chiesa" onChange={ this.setUrlChiesa } />
                  <input type="hidden" id="geoChiesa" value="" />
                  <div className="btn-edit text-center margin-top-5" style={ this.state.urlChiesa === '/' ? { display: 'none' } : { display: 'block' } } >
                    <a href={ this.state.urlChiesa } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="ricevimento">Indirizzo Ricevimento</label>
                  <input type="text" id="ricevimento" ref="ricevimento" aria-describedby="Indirizzo Ricevimento" onChange={ this.setUrlRicevimento } />
                  <input type="hidden" id="geoRicevimento" value="" />
                  <div className="btn-edit text-center margin-top-5" style={ this.state.urlRicevimento === '/' ? { display: 'none' } : { display: 'block' } } >
                    <a href={ this.state.urlRicevimento } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="casaSposo">Indirizzo Casa Sposo</label>
                  <input type="text" id="casaSposo" ref="casaSposo" aria-describedby="Indirizzo della Casa dello Sposo" onChange={ this.setUrlCasaSposo } />
                  <div className="btn-edit text-center margin-top-5" style={ this.state.urlCasaSposo === '/' ? { display: 'none' } : { display: 'block' } } >
                    <a href={ this.state.urlCasaSposo } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="casaSposa">Indirizzo Casa Sposa</label>
                <input type="text" ref="casaSposa" id="casaSposa" aria-describedby="Indirizzo della Casa della Sposa" onChange={ this.setUrlCasaSposa } />
                <div className="btn-edit text-center margin-top-5" style={ this.state.urlCasaSposa === '/' ? { display: 'none' } : { display: 'block' } } >
                  <a href={ this.state.urlCasaSposa } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                </div>
              </div>
              </div>
            </div>
          </div>

        { /* PROGRAMMA */}
        <ProgramLists allPrograms={ this.state.allPrograms } isRegister="1" />

        { /* LISTA NOZZE */}
        <GiftLists allGifts={ this.state.allGifts } isRegister="1" />

        { /* ALTRE INFO */}
        <div className="row-content">
          <div className="col">
            <div className="title-section title-section-five">
              <p>Altre Info <FontAwesomeIcon icon="info-circle" /></p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="iban">IBAN</label>
                <input autoComplete="off" ref="iban" type="text" id="iban" aria-describedby="Inserisci qui l'IBAN" maxLength="27" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input ref="email" type="email" id="email" aria-describedby="Inserisci qui l'email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="telefono">Telefono*</label>
                <input ref="telefono" type="tel" id="telefono" aria-describedby="Inserisci qui il telefono" required minLength="10" maxLength="13" pattern="[+]+[0-9]{12}"/>
                <small><i>(compreso il prefisso internazionale Es. +39)</i></small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="descrizione">
                  bemyStory <br />
                  <small><i>(Raccontate come vi siete conosciuti, raccontate agli
                    invitati come vorreste che fosse il giorno più importante della
                    vostra vita se ad esempio, vorreste che tutti gli invitati quel
                    giorno siano in costume, questo è lo spazio giusto per comunicarlo!)</i></small>
                </label>
                <textarea ref="descrizione" id="descrizione" rows="6"></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p>
                Con l'invio del seguente modulo, dichiaro di aver preso visione della
                <Link to="/policy-privacy" className="legal-link" target="_blank">
                  Policy Privacy
                </Link> e delle
                <Link to="/condizioni-legali" className="legal-link" target="_blank">
                  Condizioni Legali
                </Link>.
              </p>
            </div>
          </div>
        </div>
        <div className="col center-single-btn">
          <button type="submit" className="button">Registrati <FontAwesomeIcon icon="chevron-right" /></button>
        </div>
      </form>
    </div>

    <RegisterSuccess loginSposi={this.state.loginSposi} loginInvitati={this.state.loginInvitati} />
    <RegisterError registerErrorMessage={this.state.registerErrorMessage} />
    </div>
    );
  }
}

export default Register;
