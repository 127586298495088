import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

class ArticleThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Perché la sposa lancia il bouquet? Ti sveliamo le origini di una tradizione antichissima</title>
          <meta name="description" content="Tutte le spose a fine ricevimento lanciano il bouquet per tradizione ma ecco perché nessuna vuole rinunciarci." />
          <meta property="og:title" content="Perché la sposa lancia il bouquet? Ti sveliamo le origini di una tradizione antichissima" />
          <meta property="og:url" content="https://bemylove.it/blog/perche-la-sposa-lancia-il-bouquet" />
          <link rel="canonical" href="https://bemylove.it/blog/perche-la-sposa-lancia-il-bouquet" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/three/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Perché la sposa lancia il bouquet? Ti sveliamo le origini di una tradizione antichissima</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  11/10/2020
                </time>
              </div>
              <div className="content">
                Tutte le spose a fine ricevimento lanciano il bouquet per
                tradizione ma ecco perché nessuna vuole rinunciarci.
              </div>
              {/* Massimo 160 caratteri. */}
              <hr />
              <div className="content">
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Avete già scelto i fiori che adorneranno vostro bouquet? Con
                  la lista degli impegni di Bemylove controllate tutta
                  l’organizzazione del vostro giorno più bello!
                </a>
                <hr />
                Non esiste sposa senza il suo bouquet di fiori e tutti conoscono
                la tradizione, ormai radicatissima, che la sposa lanci il suo
                bouquet alla fine del banchetto. La donna che riuscirà a prenderlo,
                riceverà una proposta di matrimonio entro la fine dell'anno.
                Vi siete mai chieste qual è l’origine di questa famosa tradizione?
                Perché la sposa lancia il suo bouquet?
                <br /><br />
                In realtà non è facile stabilire da dove questa tradizione abbia
                origine, ma ci sono varie teorie.
                Sappiamo innanzitutto che si ha notizia di questo rito già dal
                XIV secolo. In quel tempo, in Europa, infatti, era uso comune
                che la sposa lanciasse agli invitati un pezzo del proprio abito.
                L’abito nuziale, però, era uno degli indumenti più preziosi per
                una donna e tagliarlo poteva essere visto come un vero e proprio
                spreco, quindi si decise di passare al lancio dei fiori.
                <br /><br />
                <img src="../media/blog/three/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Sempre in quell’epoca, in Francia, spesso gli uomini invitati
                alle nozze alla fine della cerimonia rincorrevano la sposa per
                strapparle un pezzo di abito nuziale come portafortuna. Con il
                tempo, questa tradizione cadde in disuso perché considerata come
                poco ortodossa e si passò al lancio della giarrettiera. Ma, nel
                giro di poco tempo, anche quest’ultima non venne più praticata
                e si ritenne più opportuno lanciare il bouquet di fiori.
                <br /><br />
                <img src="../media/blog/three/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Un’altra teoria vuole invece che anticamente la sposa regalasse
                il proprio bouquet ad una donna a lei cara, la sua migliore amica,
                come buon augurio di ricevere presto una proposta di
                matrimonio entro l’anno. Il punto era che, solitamente,
                una sposa aveva al suo fianco molte donne nubili che le
                stavano a cuore, quindi proprio da qui si optò per un lancio
                alla cieca del bouquet, lasciando la decisione alla buona sorte.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/three/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Se non siete amanti delle tradizioni, è comunque possibile
                trovare delle alternative a questo rito. Ad esempio potete
                regalare il bouquet ad una persona specifica, potete dividerlo
                equamente e regalare i fiori a tante invitate oppure, se non
                riuscite proprio a separarvi dal vostro amato bouquet potete
                conservarlo come ricordo del vostro giorno più bello!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleThree;
