import React, { Component } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NoAccess from './NoAccess';
import AccessMarried from './AccessMarried';
import AccessInvited from './AccessInvited';
import Chat from './Chat';
import VideoTutorial from '../../partials/video-tutorial/VideoTutorial';


class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      allMessages: [],
      type: props.type,
      user: '',
    };

    this.getComponent = this.getComponent.bind(this);

  }

  getComponent() {
    if( this.props.history.location.state.user == null ) {
      switch( this.state.type ) {
        case 'sposi':
          return <AccessMarried
                    id={ this.state.id }
                    type={ this.state.type }
                    user={ this.state.user }
                    history={this.props.history}
                    email=""
                    level="" />;
        case 'invitati':
          return <AccessInvited
                    id={ this.state.id }
                    type={ this.state.type }
                    user={ this.state.user }
                    history={this.props.history}
                    email=""
                    level="" />;
        default:
          return <NoAccess />;
      }
    }
    else {
      return <Chat
              id={ this.state.id }
              user={ this.props.history.location.state.user }
              type={ this.state.type }
              level={ this.props.history.location.state.level }
              email={ this.props.history.location.state.email } />;
    }
  }

  render() {
    return (
      <div id="register" className="Message" >
        <h2 className="text-right">
          Chat 
          <VideoTutorial url="https://youtu.be/AyhAJeHZJ7g" />
        </h2>
        <div className="row-content">
         { this.getComponent() }
        </div>
      </div>
    );
  }
}

export default Messages;
