import React, { Component } from 'react';
import { Link } from "react-router-dom";

// import { ReactComponent as Logo } from '../../svg/BeMyLove.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGlassCheers, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

library.add({
  faCheck,
  faGlassCheers,
  faSignInAlt
});

class Results extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="results" style={this.props.ctrl == true ? { display: 'block' } : { display: 'none' } } >
        <p className="text-center">{ this.props.text }</p>
      </div>
  )};
}

export default Results;
