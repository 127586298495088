import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.css';
import './css/page.css';
import Home from './site/home/Home';
import Login from './login/Login';
import ControlLogin from './control-login/ControlLogin';
import Register from './register/Register';
import Married from './married/Married';
import Invited from './invited/Invited';
import ConfirmInvite from './confirm-invite/ConfirmInvite';
import ConfirmAccount from './confirm-account/ConfirmAccount';
import CondizioniLegali from './site/CondizioniLegali';
import Privacy from './site/Privacy';
import Cookies from './site/Cookies';
import ListTodoSite from './site/list-todo-site/ListTodoSite';
import BudgetSite from './site/budget-site/BudgetSite';
import EventsLocationSite from './site/events-location-site/EventsLocationSite';
import GiftsSite from './site/gifts-site/GiftsSite';
import GuestsSite from './site/guests-site/GuestsSite';
import ProgramsSite from './site/programs-site/ProgramsSite';
import MenuSite from './site/menu-site/MenuSite';
import WhoWeAreSite from './site/who-we-are-site/WhoWeAreSite';
import StartSite from './site/start-site/StartSite';
import ChatSite from './site/chat-site/ChatSite';
import ResetPassWord from './reset-password/ResetPassWord';
import GetPassWord from './get-password/GetPassWord';
import PlayCard from './site/play-card/PlayCard';
import UnSubscribe from './site/unsubscribe/UnSubscribe';
import MiniLove from './site/minilove/MiniLove';
import Survey1 from './site/survey-1/Survey1';
import AutoLogin from './auto-login/AutoLogin';
import BeSilver from './site/be-silver/BeSilver';
import RegisterFriend from './register-friend/RegisterFriend';
import Redirect from './site/redirect/Redirect';
import Blog from './site/blog/Blog';
import ArticleOne from './site/blog/articles/ArticleOne';
import ArticleTwo from './site/blog/articles/ArticleTwo';
import ArticleThree from './site/blog/articles/ArticleThree';
import ArticleFour from './site/blog/articles/ArticleFour';
import ArticleFive from './site/blog/articles/ArticleFive';
import ArticleSix from './site/blog/articles/ArticleSix';
import ArticleSeven from './site/blog/articles/ArticleSeven';
import ArticleEight from './site/blog/articles/ArticleEight';
import ArticleNine from './site/blog/articles/ArticleNine';
import ArticleTen from './site/blog/articles/ArticleTen';
import ArticleEleven from './site/blog/articles/ArticleEleven';
import ArticleTwelve from './site/blog/articles/ArticleTwelve';
import ArticleThirteen from './site/blog/articles/ArticleThirteen';
import ArticleFourteen from './site/blog/articles/ArticleFourteen';
import ArticleFiveteen from './site/blog/articles/ArticleFiveteen';
import ArticleSixteen from './site/blog/articles/ArticleSixteen';
import ArticleSeventeen from './site/blog/articles/ArticleSeventeen';

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import 'babel-polyfill';
import 'url-search-params-polyfill';
import 'whatwg-fetch';

const Page404 = ({ location }) => {(
  window.location.href = '/'
)};

const reload = () => window.location.reload();

ReactDOM.render(
  <Router>
    <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/controllo-login/:code" component={ControlLogin} />
          <Route path="/registrati" component={Register} />
          <Route path="/registra-amici/:code" component={RegisterFriend} />
          <Route path="/redirect/:redirect" component={Redirect} />
          <Route path="/sposi/:tabs" component={Married} />
          <Route path="/invitati/" component={Invited} />
          <Route path="/conferma-invito/:otp" component={ConfirmInvite} />
          <Route path="/conferma-account/:otp" component={ConfirmAccount} />
          <Route path="/condizioni-legali" component={CondizioniLegali} />
          <Route path="/policy-privacy" component={Privacy} />
          <Route path="/cookies" component={Cookies} />
          <Route path="/lista-impegni" component={ListTodoSite} />
          <Route path="/controllo-budget" component={BudgetSite} />
          <Route path="/i-luoghi-degli-eventi" component={EventsLocationSite} />
          <Route path="/lista-nozze" component={GiftsSite} />
          <Route path="/lista-invitati" component={GuestsSite} />
          <Route path="/programma-delle-nozze" component={ProgramsSite} />
          <Route path="/menu-di-nozze" component={MenuSite} />
          <Route path="/chi-siamo" component={WhoWeAreSite} />
          <Route path="/inizia" component={StartSite} />
          <Route path="/chat" component={ChatSite} />
          <Route path="/reset-password" component={ResetPassWord} />
          <Route path="/get-password/:token" component={GetPassWord} />
          <Route path="/come-andra-il-mio-matrimonio" component={PlayCard} />
          <Route path="/rimuovi-iscrizione/:email" component={UnSubscribe} />
          <Route path="/minilove" component={MiniLove} />
          <Route path="/aiutaci-a-migliorare" component={Survey1} />
          <Route path="/auto-login/:token/:page" component={AutoLogin} />
          <Route path="/be-silver/:id/:token" component={BeSilver} />
          <Route path="/cose-da-sapere-prima-di-sposarsi" component={Blog} />
          <Route path="/blog/scegliere-auto-per-il-matrimonio" component={ArticleOne} />
          <Route path="/blog/come-scegliere-il-bouquet-per-il-matrimonio" component={ArticleTwo} />
          <Route path="/blog/perche-la-sposa-lancia-il-bouquet" component={ArticleThree} />
          <Route path="/blog/scegliere-le-scarpe-da-sposa-giuste" component={ArticleFour} />
          <Route path="/blog/matrimoni-e-covid-19-tutto-quello-che-ce-da-sapere-sul-nuovo-dcpm-in-relazione-ai-matrimoni" component={ArticleFive} />
          <Route path="/blog/matrimonio-pagetti-e-damigelle-consigli-per-il-corteo-nunziale-perfetto" component={ArticleSix} />
          <Route path="/blog/matrimonio-low-cost-tutte-le-idee-consigli-suggerimenti-per-risparmiare" component={ArticleSeven} />
          <Route path="/blog/matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite" component={ArticleEight} />
          <Route path="/blog/matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite-parte-2" component={ArticleNine} />
          <Route path="/blog/cani-e-matrimonio-le-nostre-idee-e-suggerimenti" component={ArticleTen} />
          <Route path="/blog/bomboniere-e-matrimoni-2021-idee-originali" component={ArticleEleven} />
          <Route path="/blog/fedi-nunziali-tutti-i-consigli-per-scegliere-quelle-perfette-per-voi" component={ArticleTwelve} />
          <Route path="/blog/fotografo-per-il-matrimonio-come-scegliere-quello-giusto" component={ArticleThirteen} />
          <Route path="/blog/trucco-sposa-consigli-make-up-impeccabile-per-il-giorno-delle-nozze" component={ArticleFourteen} />
          <Route path="/blog/trucco-sposa-errori-da-evitare-assolutamente" component={ArticleFiveteen} />
          <Route path="/blog/proposta-di-matrimonio-come-lasciare-il-segno" component={ArticleSixteen} />
          <Route path="/blog/matrimonio-2021-e-covid-quali-misure-adottare-per-festeggiare-in-sicurezza" component={ArticleSeventeen} />
          <Route component={Page404} />
          <Route path="/robots.txt" onEnter={reload} />
        </Switch>
    </div>
  </Router>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
