import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import Step0 from '../step-0/Step0';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUser } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';

library.add({
  faUser
});


class ModalConfirm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      id: props.id,
      image: props.image
    };

    this.handleClose = this.handleClose.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.show !== this.state.show ) {
        this.setState({
          show: nextProps.show,
        });
        return true;
    }
    return false;
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSubmit( event ) {

    let input = {
      token: localStorage.getItem("bml_session"),
      id: this.state.id,
      image: this.state.image
    };

    axios.post(process.env.REACT_APP_API_URL + '/minilove-delete/', input)
        .then(response => {

          if(response.data.error.status === false) {
            // OK
              window.top.location.href = '/minilove';
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }

        });

  }

  render() {
    return (
      <div className="modal show-modal modal-pay" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
        <div className="modal-content">
          <span className="close-button" onClick={this.handleClose}>&times;</span>
          <br />
          <h3 className="text-center">Vuoi eliminare il Minilove?</h3>
            <p className="text-center">Ricorda che una volta eliminato sarà in maniera
            definitiva ma niente paura, potrai crearne quanti ne vuoi!</p><br />
            <div className="row">
              <div className="col-lg-12">
                <p className="text-center margin-bottom-0">Vuoi eliminarlo?</p>
                <div
                  onClick={(e) => this.handleSubmit()}
                  className="btn-submit center-single-btn margin-top-0"
                  style={{ backgroundColor: '#ef5aa7', marginTop: '20px', cursor: 'pointer' }}>
                    Sì, lo voglio!
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ModalConfirm;
