import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import Step0 from './step-0/Step0';
import Step1 from './step-1/Step1';
import Step2 from './step-2/Step2';
import Step3 from './step-3/Step3';
import Step4 from './step-4/Step4';

import MetaTags from 'react-meta-tags';

class MiniLove extends Component {
  constructor(props) {

    super(props);

    this.state = {
      // email: '',
      // success: false,
      // error: false
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
    if( localStorage.getItem('bml_session') ) {
      document.getElementsByClassName("row-action-footer")[0].style.display = "none";
    }
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>MiniLove - bemylove</title>
          <meta name="description" content="Costruite il minilove con i vostri volti e divertitevi a vestirli. Fatevi un selfie, scegliete i loro abiti, stampateli e incollateli! Rendete unico e divertente il vostro matrimonio." />
          <meta property="og:title" content="MiniLove - bemylove" />
          <meta property="og:url" content="https://bemylove.it/minilove" />
          <link rel="canonical" href="https://bemylove.it/minilove"/>
        </MetaTags>
        <Header ctrl="0" />
          <Route exact path={this.props.match.path} component={Step0} />
          <Route path={`${this.props.match.path}/step-1`} component={Step1} />
          <Route path={`${this.props.match.path}/step-2`} component={Step2} />
          <Route path={`${this.props.match.path}/step-3`} component={Step3} />
          <Route path={`${this.props.match.path}/step-4`} component={Step4} />
        <Footer />
      </div>
    );
  }
}

export default MiniLove;
