import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faClock, faTrash, faMapMarkerAlt, faCalendarCheck } from '@fortawesome/free-solid-svg-icons/index';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Results from '../results/Results';

library.add({
  faClock,
  faTrash,
  faMapMarkerAlt,
  faCalendarCheck
});

var program = {
  id: 0,
  what: '',
  whither: '',
  whatTime: ''
};

const DragHandleProgram = SortableHandle((singleProgram) =>
        <div className="col-lg-1 col-12 drag" style={ window.innerWidth < 1000 ? { display: 'none' } : { display: 'block' }}>
          <FontAwesomeIcon icon="grip-lines" className="margin-icons" />
        </div>
);


const SortableProgram = SortableElement(({programList, index, removeProgram, getTooltipMobile}) =>
      <div className="row" key={index}>
        <DragHandleProgram singleProgram={ programList } />
        <div className="col-lg-8 col-12">
          <div className="row" style={{ borderBottom: "0" }}>
            <div className="col-lg-4 col-12">
              <div className="d-flex justify-content-center"><FontAwesomeIcon icon="clock" className="margin-icons" /> { programList.whatTime }</div>
            </div>
            <div className="col-lg-6 col-12">
              <p><FontAwesomeIcon icon="calendar-check" className="margin-icons" /> { programList.what }</p>
              <p><FontAwesomeIcon icon="map-marker-alt" className="margin-icons" /> { programList.whither }</p>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-6 text-center tooltip">
          <a href={"https://www.google.it/maps/place/" + programList.whither } target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon="map-marker-alt" className="margin-top-50" /></a>
          { getTooltipMobile( 1 ) }
       </div>
        <div className="col-lg-1 col-6 text-center tooltip">
          <FontAwesomeIcon icon="trash" className="margin-top-50" onClick={()=> removeProgram(index)}/>
          { getTooltipMobile( 2 ) }
       </div>
      </div>
);

const SortableProgramList = SortableContainer(({allPrograms, removeProgram, getTooltipMobile}) => {
  return (
    <div className="content-elms">
      {allPrograms.map((value, index) => (
        <SortableProgram key={`item-${index}`} index={index} programList={value} removeProgram={removeProgram} getTooltipMobile={getTooltipMobile} />
      ))}
    </div>
  );
});

class ProgramLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whatTime: '',
      badTime: false,
      allPrograms: this.props.allPrograms,
      isRegister: this.props.isRegister,
      resultsText: "Clicca su \"Salva cambiamenti\" per confermare!",
      updateInfo: false,
      urlWhere: '/'
    };

    this.whatTimeChange = this.whatTimeChange.bind(this);
    this.whitherChange = this.whitherChange.bind(this);
    this.whatChange = this.whatChange.bind(this);
    this.addProgram = this.addProgram.bind(this);
    this.removeProgram = this.removeProgram.bind(this);
    this.getTooltipMobile = this.getTooltipMobile.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.allPrograms !== this.state.allPrograms ) {
        this.setState({
          allPrograms: nextProps.allPrograms
        });
        return true;
    }
    return false;
  }

  getTooltipMobile( ctrl ) {
    switch (ctrl) {
      case 1:
        if(window.innerWidth < 1000) {
          return  <span><br />Guarda dove si trova!</span>
        }
        else {
          return  <span className="tooltiptext" style={{ top: '-20px', left: '40px' }}>
                    Guarda dove si trova!
                  </span>

        }
        break;
      case 2:
        if(window.innerWidth < 1000) {
          return  <span><br />Elimina questo elemento</span>
        }
        else {
          return  <span className="tooltiptext" style={{ top: '-20px', left: '40px' }}>
                    Elimina questo elemento
                  </span>

        }
        break;

    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ allPrograms }) => ({
      allPrograms: arrayMove(allPrograms, oldIndex, newIndex),
    }));

    this.props.allPrograms.length = 0;

    this.state.allPrograms.forEach( programPush => {
      this.props.allPrograms.push(programPush);
    });

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);
  };

  whatTimeChange(event) {

    //this.state.badTime = false;
    this.setState({ badTime: false });

    let value = event.target.value;

    let lastChar = value.slice(-1);

    if(!isNaN(lastChar)) {
      let charCode = value.charCodeAt(0);

      if (charCode !== 47) {
        program.whatTime = value;
        this.setState({whatTime: value });

        if (value.length === 2) {
          program.whatTime = value + ':';
          this.setState({whatTime: value + ':' });
        }
        else if (value.length === 5) {
          let patt = new RegExp("^[0-2][0-9]:[0-5][0-9]$");
          let res = patt.test(value);
          this.setState({badTime: !patt.test(value) });
        }
      }
    }
    else {
      value = value.slice(0, -1);
      program.whatTime = value;
      this.setState({whatTime: value });
    }
  }

  whitherChange(event) {
    program.whither = event.target.value;
    this.setState({urlWhere: "https://www.google.it/maps/place/" + event.target.value });
  }

  whatChange(event) {
    program.what = event.target.value;
  }

  addProgram() {

    if( program.what !== '' && program.whither !== '' && program.whatTime !== '' && program.whatTime.length == 5 && this.state.badTime === false ) {

      let tmpPrograms = this.state.allPrograms;

      tmpPrograms.push(program);

      this.setPropsState( tmpPrograms );

      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);

      program = {
        id: 0,
        what: '',
        whither: '',
        whatTime: ''
      };

      this.setState({whatTime: '' });
      this.refs.ProgramTime.value = '';
      this.refs.ProgramWhither.value = '';
      this.refs.ProgramWhat.value = '';
    }
    else if ( program.whatTime.length < 5 ) {
      this.setState({badTime: true});
    }

  }

  removeProgram(index) {

    let elm = this.state.allPrograms;

    elm.splice( index , 1);

    this.setPropsState( elm );

    if( this.state.isRegister == 0 ) {
      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);
    }

  }

  setPropsState( programs ) {
    this.setState({allPrograms: programs});

    if( this.state.allPrograms.length !== this.props.allPrograms.length ) {
      this.props.allPrograms.length = 0;

      this.state.allPrograms.forEach( programPush => {
        this.props.allPrograms.push(programPush);
      });
    }
  }

  render() {
    let results;
    if ( this.state.isRegister == 0 ) {
        results = <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
    }
    return (
        <div>
          { /* PROGRAMMA */}
          <div className="row-content">
            <div className="row" style={this.state.isRegister == 1 ? { display: 'block' } : { display: 'none' } }>
              <div className="title-section title-section-third">
                <p>Programma <FontAwesomeIcon icon="clipboard-list" /></p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="form-group">
                  <label htmlFor="time">Ora</label>
                  <input type="text" aria-describedby="00:00" maxLength="5" value={ this.state.whatTime } ref="ProgramTime" pattern="^[0-2][0-3]:[0-5][0-9]$" onChange={ this.whatTimeChange } />
                  <small className="time-error" style={this.state.badTime ? { display: 'block' } : { display: 'none' } }>Controlla bene l'orario</small>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group">
                  <label htmlFor="what">Cosa?</label>
                  <input type="text"   aria-describedby="Cosa?" ref="ProgramWhither" onChange={ this.whatChange } />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="form-group">
                  <label htmlFor="where">Dove?</label>
                  <input type="text" aria-describedby="Dove?" ref="ProgramWhat" onChange={ this.whitherChange } />
                  <small>Inserisci l'indirizzo completo. Assicurati che su Maps di veda esattamente il luogo.</small>
                  <div className="button text-center margin-top-5" style={ this.state.urlWhere == '/' ? { display: 'none' } : { display: 'block' } } >
                    <a href={ this.state.urlWhere } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="center-single-btn button" onClick={this.addProgram}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
            </div>
            <SortableProgramList allPrograms={this.state.allPrograms} onSortEnd={this.onSortEnd} removeProgram={this.removeProgram} getTooltipMobile={this.getTooltipMobile} useDragHandle />
          </div>
          { results }
        </div>
  )};
}

export default ProgramLists;
