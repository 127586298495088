import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { Link } from "react-router-dom";

import ModalConfirm from '../modal/ModalConfirm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMale,
  faFemale } from '@fortawesome/free-solid-svg-icons';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import MetaTags from 'react-meta-tags';

class Step4 extends Component {
  constructor(props) {

    super(props);

    if( !props.location.state ) {
      window.location.href = '/minilove/';
      return;
    }

    let timestamp = new Date().getTime();

    this.state = {
      id: props.location.state.id,
      image: props.location.state.image,
      ogImage: "",
      timestamp: new Date().getTime(),
      showModal: false
    }

    this.handleDownload = this.handleDownload.bind(this);
    this.deleteMinilove = this.deleteMinilove.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
    document.getElementsByClassName("row-action-footer")[0].style.display = "none";

    let valueBase64 = {
      id: this.state.id,
      image: this.state.image,
      path: 'minilove',
      pathTwo: 'og_image',
      token: localStorage.getItem("bml_session")
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-base64-minilove/', valueBase64 )
        .then(response => {
          this.setState({
            ogImage: response.data.rls.sposo
          });
        });
  }

  handleDownload( event, type ) {
    let url = process.env.REACT_APP_MEDIA_URL + 'minilove/final-images/' + type + '-' + this.state.image + '.jpg?t=' + this.state.timestamp;
    window.open(url, 'Download');
  }

  deleteMinilove( event ) {
    this.setState({
      showModal: true
    });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <div className="Site Minilove_4">
          <div className="row bg-white">
            <h2 className="text-center">Complimenti!</h2>
            <h3>Il tuo minilove è pronto per essere stampato!</h3>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                Congratulazioni, il tuo minilove è stato generato. Adesso potrai
                scaricarlo, ritagliarlo e piegarlo lungo le linee guida! <br /><br />
                <h3>Non fermarti qui!</h3>
                Condividi con i tuoi amici il vostro minilove su Facebook o WhatsApp!
              </div>
            </div>
            <button
              type="button"
              className="btn-submit btn-minilove-delete"
              style={{ marginLeft: 'auto' }}
              onClick={ e => this.handleDownload(e, 'sposo') } ><FontAwesomeIcon icon="male" /> Scarica MiniSposo</button>

            <button
              type="button"
              className="btn-submit btn-minilove-save"
              style={{ marginRight: 'auto' }}
              onClick={ e => this.handleDownload(e, 'sposa') } ><FontAwesomeIcon icon="female" /> Scarica MiniSposa</button>
          </div>
          <br /><br />
          <h3>Condividi!</h3>
          <div className="imgOmg">
            <img src={ this.state.ogImage } />
          </div>
          <br /><br />
          <div claName="row">
            <div className="button-share">
              <FacebookShareButton
                 className="network__share-button"
                 url={ this.state.ogImage }
                 quote="Ecco i nostri Minilove creati su https://bemylove.it"
                >
                  <FacebookIcon
                    size='64'
                    round={true}
                  />
                </FacebookShareButton>
            </div>
            <div className="button-share" style={{ display: 'none' }}>
              <TwitterShareButton
                 className="network__share-button"
                 url={ this.state.ogImage }
                 quote="Ecco i nostri Minilove creati su https://bemylove.it"
                >
                  <TwitterIcon
                    size='64'
                    round={true}
                  />
                </TwitterShareButton>
            </div>
            <div className="button-share" style={{ display: 'none' }}>
              <TelegramShareButton
                 className="network__share-button"
                 url={ this.state.ogImage }
                 quote="Ecco i nostri Minilove creati su https://bemylove.it"
                >
                  <TelegramIcon
                    size='64'
                    round={true}
                  />
                </TelegramShareButton>
            </div>
            <div className="button-share">
              <WhatsappShareButton
                 className="network__share-button"
                 url={ this.state.ogImage }
                 quote="Ecco i nostri Minilove creati su https://bemylove.it"
                >
                  <WhatsappIcon
                    size='64'
                    round={true}
                  />
                </WhatsappShareButton>
            </div>
          </div>
          <br /><br />
          <br /><br />
            <button
              type="button"
              className="btn-submit btn-minilove-delete"
              onClick={ e => this.deleteMinilove(e) } ><FontAwesomeIcon icon="trash" /> Elimina e ricomincia</button>

        </div>
        <ModalConfirm show={ this.state.showModal } id={ this.state.id } image={ this.state.image } />
      </div>
    );
  }
}

export default Step4;
