import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';

library.add({
  faTimes,
  faHome
});

class RegisterError extends Component {
  constructor(props) {
    super(props);
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  render() {
    return (
      <div id="register_ko" className="container" style={this.props.registerErrorMessage !== '' ? { display: 'block' } : { display: 'none' } }>
        <h2 className="text-right"><strong>Registrazione fallita <FontAwesomeIcon icon="times" /></strong></h2>
        <div className="row row-content">
          <p className="text-center">Purtroppo la registrazione non è andata a buon fine <span dangerouslySetInnerHTML={{__html: this.props.registerErrorMessage }}></span></p>
          <div className="center-single-btn">
            <Link to="/">
              <div className="button">Accedi <FontAwesomeIcon icon="sign-in-alt" /></div>
            </Link>
          </div>
        </div>
      </div>
  )};
}

export default RegisterError;
