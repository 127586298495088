import React, { Component } from 'react';
import axios from 'axios';

class AccessMarried extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      type: props.type,
      married: {},
      user: props.user,
      email: '',
      level: ''
    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      type: this.state.type
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-married/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              married: response.data.rls,
              email: response.data.rls.email,
              level: response.data.rls.level
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });

  }

  handleChange() {

    this.props.history.push(
      '/sposi/chat',
      {
        id: this.state.id,
        level: this.state.level,
        page: 'chat',
        type: 'sposi',
        user: this.refs.setMarried.value,
        email: this.state.email
      }
    );
  }

  render() {
    return (
      <div className="row" >
        <div className="col-lg-4 offset-lg-4 col-sm-12">
          <div className="form-group">
            <label htmlFor="data">Seleziona la tua identità</label>
            <select ref="setMarried" onChange={ this.handleChange }>
              <option selected disabled>Scegli</option>
              <option value={ this.state.married.sposo }>{ this.state.married.sposo }</option>
              <option value={ this.state.married.sposa }>{ this.state.married.sposa }</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default AccessMarried;
