import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faCar } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';
import Results from '../../../partials/results/Results';

library.add({
  faCar
});

class Cars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allGuests: [],
      allCars: [],
      updateInfo: false,
      resultsText: "Gestione auto aggiornato!",
      ctrl: parseInt(props.ctrl), // 1 = Sposi; 0 = Invitati
      show: false,
      tmpCar: 0,
      noCars: "Attendere..."
    };

    this.addCars = this.addCars.bind(this);
    this.addPax = this.addPax.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-driver/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allGuests: response.data.rls,
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
    axios.post(process.env.REACT_APP_API_URL + '/get-cars/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allCars: response.data.rls,
              noCars: "Nessuna auto inserita!"
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  addCars( event ) {
    let input = {
      user_id: this.state.id,
      name: this.refs.nameCars.value,
      driver: this.refs.driverCars.value,
      token: localStorage.getItem("bml_session")
    };

    axios.post(process.env.REACT_APP_API_URL + '/save-car/', input)
        .then(response => {
          if(response.data.error.status == false) {
            // OK
            if( response.data.rls == "OK" ) {
              this.setState({
                updateInfo: true,
                resultsText: "Auto salvata con successo"
              });
              this.refs.nameCars.value = '';
              this.refs.driverCars.value = '';
            }

            this.componentDidMount();

            setTimeout( () => {
              this.setState({
                updateInfo: false
              });
            }, 3000);
            //this.handleClose();
          }
          else {
            // NO
            // this.setState({
            //   guestsSuccessSubmit: true,
            //   registerErrorMessage: response.data.error.rls
            // });
          }

        });
    event.preventDefault();

  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow( value ) {
    this.setState({ show: true, tmpCar: value });
  }

  removeCar( id ) {
    if( this.state.ctrl == 1 ) {

      let input = {
        id: id,
        user_id: this.state.id,
        token: localStorage.getItem("bml_session")
      };

      axios.post(process.env.REACT_APP_API_URL + '/remove-car/', input)
          .then(response => {
            if(response.data.error.status == false) {
              // OK
              this.setState({
                updateInfo: true,
                resultsText: "Auto eliminata con successo"
              });
              this.componentDidMount();
              setTimeout( () => {
              this.setState({
                updateInfo: false,
                resultsText: "Auto non eliminata"
              });
              }, 2000);
            }
            else {
              // NO
              this.setState({
                updateInfo: true
              });
              setTimeout( () => {
                localStorage.removeItem("bml_session");
                window.top.location.href = '/';
              }, 2000);
            }

          });
    }
  }

  addPax( event ) {
    let input = {
      cars_id: this.state.tmpCar,
      guest_id: this.refs.paxCars.value,
      user_id: this.state.id,
      ctrl: this.state.ctrl,
      token: localStorage.getItem("bml_session")
    };

    axios.post(process.env.REACT_APP_API_URL + '/save-pax/', input)
        .then(response => {
          if(response.data.error.status == false) {
            // OK
            if( response.data.rls == "OK" ) {
              this.setState({
                updateInfo: true,
                resultsText: "Passeggero inserito con successo",
                show: false
              });
            }
            else {
              this.setState({
                updateInfo: true,
                resultsText: "Passeggero non inserito",
              });
            }

            this.componentDidMount();

            setTimeout( () => {
              this.setState({
                updateInfo: false
              });
            }, 3000);
            //this.handleClose();
          }
          else {
            // NO
            // this.setState({
            //   guestsSuccessSubmit: true,
            //   registerErrorMessage: response.data.error.rls
            // });
          }

        });
    event.preventDefault();

  }

  render() {
    return (
      <div id="register"  className="Cars" >
        <h2 className="text-right">
          Gestione auto
        </h2>
        <div className="row row-content">
          <div className="col-lg-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="nameCars">Dai un nome all'auto</label>
              <input type="text" ref="nameCars" />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="driverCars">Chi la guiderà?</label>
              <select ref="driverCars">
                <option value="0" defaultValue disabled>Scegli</option>
                { this.state.allGuests && this.state.allGuests.length > 0
                  ? this.state.allGuests.map( (guestList, key) => {
                    return (
                      <option value={ guestList.id } key={key}>{ guestList.name }</option>
                     );
                 }) : ''}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="center-single-btn button" onClick={this.addCars}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
        </div>
        <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
        { /* CARS */}
        <div className={ this.state.ctrl == 0 && this.state.allCars.length > 0 ? 'content-elms' : 'row-content' }>
          <div className={ this.state.ctrl == 0 ? '' : 'row' }>
          <p className="text-center">{ this.state.allCars.length == 0 ? this.state.noCars : ''}</p>
            {this.state.allCars.map( (carList, key) => {
                return (
                  <div className="content-elms" key={key}>
                    <div className="row">
                      <div className="col-8">
                        <p><FontAwesomeIcon icon="car"/> <strong>{ carList.name_cars }</strong> sarà guidata da <strong>{ carList.name_driver }</strong></p>
                        <p style={carList.pax.length > 0 ? { display: 'block' } : { display: 'none' }}>
                        {carList.pax.length > 0 ?
                          carList.pax.map( (guest, keyPax) => {
                              return (
                                <small key={keyPax} style={keyPax > 0 ? { marginLeft: '20px' } : { marginLeft: '0' }}><FontAwesomeIcon icon="user-check"/> { guest.name_pax }</small>
                              )})
                           : ""}
                        </p>
                      </div>
                      <div className="col-2" style={ this.state.ctrl == 0 ? { display: 'none' } : { display: 'block' } }>
                        <div className="text-center tooltip">
                          <FontAwesomeIcon icon="plus" style={{ color: 'white' }} onClick={()=> this.handleShow( carList.id )}/>
                          <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-50px', left: '28px' } }>Aggiungi Passeggero</span>
                        </div>
                      </div>
                      <div className="col-2" style={ this.state.ctrl == 0 ? { display: 'none' } : { display: 'block' } }>
                        <div className="text-center tooltip">
                          <FontAwesomeIcon icon="trash" style={{ color: 'white' }} onClick={()=> this.removeCar( carList.id )}/>
                          <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-50px', left: '28px' } }>Elimina auto</span>
                        </div>
                      </div>
                    </div>
                   </div>
                );
            })}
          </div>
        </div>
        { /* FINE CARS */}
        <div className="modal show-modal" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
          <div className="modal-content">
            <span className="close-button" onClick={this.handleClose}>&times;</span>

            <form onSubmit={this.addPax} >
              <div className="row row-content">
                <div className="col-md-12 text-center">
                <div className="form-group">
                  <label htmlFor="paxCars" style={{ color: 'white' }}>Seleziona il paseggero</label>
                  <select ref="paxCars">
                    <option value="0" defaultValue disabled>Scegli</option>
                    { this.state.allGuests && this.state.allGuests.length > 0
                      ? this.state.allGuests.map( (guestList, key) => {
                        return (
                          <option value={ guestList.id } key={key}>{ guestList.name }</option>
                         );
                     }) : ''}
                  </select>
                </div>
                </div>
              </div>
               <div className="row">
                 <div className="col center-single-btn">
                   <button type="submit" className="center-single-btn button" style={{ border: '2px solid white', backgroundColor: '#d77eac'}}>Aggiungi!</button>
                 </div>
               </div>
            </form>
          </div>
        </div>
        { /* FINE MODALE */}
      </div>
    );
  }
}

export default Cars;
