import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';


import MetaTags from 'react-meta-tags';


class AutoLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.autoLogin = this.autoLogin.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( !this.props.match.params.token && !this.props.match.params.page ) {
        localStorage.removeItem("bml_session");
        this.props.history.push('/');
        return;
    }

    this.autoLogin();
  }

  autoLogin() {
    let input = {
      token: this.props.match.params.token
    };

    axios.post(process.env.REACT_APP_API_URL + '/auto-login/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            localStorage.setItem("bml_session", response.data.token);
            if( this.props.match.params.page == 'minilove' ) {
              this.props.history.push('/minilove');
            }
            else if( this.props.match.params.page == 'be-silver' ) {
              this.props.history.push('/be-silver/' + response.data.user_id + '/' + response.data.token);
            }
            else {
              this.props.history.push(
                '/' + response.data.access + '/' + this.props.match.params.page,
                {
                  id: response.data.user_id,
                  level: response.data.user_level,
                  page: this.props.match.params.page,
                  type: response.data.access
                }
              );
            }
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }

        });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>Login - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Login - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div id="login">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row">
            <h2 className="text-center">Attendi...</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoLogin;
