import React, { Component } from 'react';
import { Link, Switch, Router } from "react-router-dom";

import Footer from './partials/Footer';
import Header from './partials/Header';

import MetaTags from 'react-meta-tags';

class Cookies extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Cookies - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Cookies - bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/cookies" />
          <link rel="canonical" href="https://bemylove.it/cookies"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Cookies</h1>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-md-1 col-sm-12 bg-white">
              <div>
                <p>
                  bemylove.it configura i suoi cookie per fare in modo che
                  la navigazione dell'utente sia il più soddisfacente possibile.
                  È la maniera migliore per l'utente di affacciarsi al tipo di
                  contenuto che più gli interessa e che offre bemylove.it.
                </p>
                <h3>Cosa sono i Cookie?</h3>
                <p>
                  Un cookie è un file di servizio che si scarica sul computer,
                  Smartphone o Tablet dell'utente. Questo file si utilizza per
                  recuperare l'informazione relativa alla navigazione dell'utente,
                  in modo tale da non perdere la sessione di registro, migliorare
                  il tempo di caricamento del sito web, non mostrare informazione
                  che l'utente già conosce, etc.
                </p>
                <h3>I cookie di bemylove.it</h3>
                <p>
                  bemylove.it utilizza questo tipo di cookie per
                  offrire un miglior servizio ai suoi utenti:
                </p>
                <ul style={ { listStyleType: 'square' } }>
                  <li>
                    <p>
                      Cookie strettamente necessari: sono necessari per offrire i
                      servizi che l'usuario richiede di ricevere. Se questi cookie
                      vengono disattivati, l'utente non potrà ricevere in maniera
                      corretta i contenuti e i servizi di bemylove.it;
                    </p>
                  </li>
                  <li>
                    <p>
                      Cookie per l'analisi delle prestazioni: si utilizzano per
                      il monitoraggio e l'analisi statistica del comportamento degli utenti;
                    </p>
                  </li>
                  <li>
                    <p>
                      Cookie pubblicitari: necessari per gestire gli spazi
                      pubblicitari seguendo diversi criteri, come la frequenza in
                      cui gli annunciano vengono mostrati;
                    </p>
                  </li>
                  <li>
                    <p>
                      Cookie per l'analisi delle funzionalità: gestiscono gli
                      spazi pubblicitari seguendo il profilo specifico dell'utente.
                    </p>
                  </li>
                </ul>
                <p>
                  La disattivazione dei cookie per l'analisi delle prestazioni,
                  delle funzionalità e pubblicitari non influirà sul funzionamento
                  del sito web, però si ripercuoterà sull'esito degli annunci
                  mostrati che permettono migliorare i servizi di bemylove.it
                  e ottenere introiti che offrono la possibilità di offrire
                  gratuitamente molti servizi e contenuti.<br /><br />
                  Al momento i cookies utilizzati sono:
                </p>
                <ul style={ { listStyleType: 'square' } }>
                  <li>
                    <h4>Google Analytics</h4>
                    <ul style={ { listStyleType: 'square' } }>
                      <li><p>Responsabile: Google Analytics</p></li>
                      <li><p>Tipo: Analitici</p></li>
                      <li><p>Descrizione: Servizio di analisi per il rilevamento e l’ottenimento di informazioni sul traffico del sito web.</p></li>
                      <li><p>Responsabile: Google Analytics</p></li>
                      <li><p>Maggiori Informazioni:
                      <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">QUI</a></p></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  L'informazione che bemylove.it ottiene con l'uso di questi
                  cookie (riferita al pc dell'utente) potrà essere abbinata con i
                  dati personali dell'utente, nel caso in cui sia registrato su
                  bemylove.it.
                </p>
                <h3>Disattivazione di cookie</h3>
                <p>
                  L'utente potrà scegliere in qualsiasi momento quali cookie vuole
                  che funzionino sul sito web mediante la configurazione del browser:
                </p>

                <ul style={ { listStyleType: 'square' } }>
                  <li>
                    <p>
                      Chrome, da
                      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=it">
                        QUI
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Explorer, da
                      <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">
                        QUI
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Firefox, da
                      <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
                        QUI
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Safari, da
                      <a href="https://support.apple.com/kb/ph21411?locale=it_IT">
                        QUI
                      </a>
                    </p>
                  </li>
                </ul>
                <p>
                  bemylove.it non si responsabilizza del contenuto e della
                  veridicità delle politiche di privacy dei terzi inclusi in questa
                  politica di cookie. Per qualsiasi dubbio sulla politica di cookie,
                  è possibile mettersi in contatto con bemylove.it scrivendo
                  a gdpr[chiocciola]bemylove.it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Cookies;
