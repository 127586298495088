import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class StartSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Inizia - bemylove</title>
          <meta name="description" content="Quando io a Vanessa abbiamo deciso di sposarci, abbiamo subito iniziato ad usare bemylove per l’organizzazione del nostro matrimonio. Io e mia moglie amiamo..." />
          <meta property="og:title" content="Inizia - bemylove" />
          <meta property="og:url" content="https://bemylove.it/inizia" />
          <link rel="canonical" href="https://bemylove.it/inizia"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Inizia</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6oPDcZi6h4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                <h3>L’esperienza di Francesco (lo sposo):</h3><br />
                “Quando io a Vanessa abbiamo deciso di sposarci, abbiamo subito
                iniziato ad usare <strong>bemylove</strong> per l’organizzazione del nostro matrimonio.
                Io e mia moglie amiamo molto la tecnologia e quindi cosa meglio di
                questa piattaforma per l’organizzazione delle nostre nozze?<br /><br /><br />
                Registrarsi ed iniziare ad usarlo è stato davvero semplice!
                Entrando sul sito <strong>bemylove.it</strong> se vuoi registrarti,
                clicca sul tasto "Sì, lo voglio!" e completare tutti i campi richiesti.
                La data innanzitutto, i luoghi del matrimonio
                (avendo cura di essere più precisi possibile), il programma e la
                lista nozze. Tutte le informazioni utili che poi saranno
                disponibili anche sugli smartphone dei vostri invitati! Alla
                fine dovrete aggiungere il vostro Iban e la vostra mail e il
                numero di cellulare unitamente alla vostra BeMyStory, la sezione
                che vi permetterà di dare spazio alla vostra fantasia dove ad
                esempio potreste raccontare come vi siete conosciuti o dire agli
                invitati come vorreste che fosse il giorno più bello della vostra vita!<br /><br /><br />
                Dopo essersi registrati, il sistema genererà automaticamente i codici
                di accesso per gli sposi e per gli invitati e vi invierà una mail per
                convalidare la registrazione.<br /><br /><br />
                Basterà semplicemente cliccare sul link inviato via mail e potrete
                finalmente iniziare l’organizzazione del vostro matrimonio con
                <strong>bemylove</strong>! Dopo aver inserito tutte le informazioni richieste
                dalla piattaforma, potrete far accedere i vostri ospiti tramite
                il codice di accesso alle parti a loro dedicate, rendendoli
                partecipi al 100 % del vostro giorno più bello!”
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StartSite;
