import React, { Component } from 'react';
import axios from 'axios';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import ModalCard from './modal-card/ModalCard';
import ModalCardRls from './modal-card-rls/ModalCardRls';

import MetaTags from 'react-meta-tags';

class Playard extends Component {
  constructor(props) {

    super(props);

    this.state = {
      show: false,
      showRls: false,
      sentence: '',
      sentenceValue: 0,
      card: '',
      title: props.title && props.title == "off" ? "off" : "on",
      styleTitle: { backgroundImage: 'none', paddingBottom: '9px', marginBottom: '0', display: "block"}
    };

    this.goTo = this.goTo.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.add("bg-white");
    if( this.state.title == "off" ) {
      this.setState({
        styleTitle: {
          display: "none"
        }
      });
    }
  }

  goTo( type ) {
    if( !localStorage.getItem("bml_session") ) {
        this.setState({
          show: true
        });
        return true;
    }
    else {

      if( localStorage.getItem("bml_cards_" + type) ) {
        document.getElementsByClassName('card-' + type)[0].classList.add("is-flipped");
        let result = JSON.parse(localStorage.getItem("bml_cards_" + type));
        setTimeout(() => {
          this.setState({
            showRls: true,
            sentence: result.sentence,
            sentenceValue: result.sentenceValue,
            card: type
          });
          document.getElementsByClassName('card-' + type)[0].classList.remove("is-flipped");
        }, 500);
        return;
      }


      let value = {
        token: localStorage.getItem("bml_session"),
        card: type
      };

      axios.post(process.env.REACT_APP_API_URL + '/get-general-cards/', value )
          .then(response => {
            document.getElementsByClassName('card-' + type)[0].classList.add("is-flipped");
            setTimeout(() => {
              if(response.data.error.status === false) {
                // OK
                this.setState({
                  showRls: true,
                  sentence: response.data.rls.sentence,
                  sentenceValue: response.data.rls.value,
                  card: type
                });

                localStorage.setItem("bml_cards_" + type, JSON.stringify({
                  sentence: response.data.rls.sentence,
                  sentenceValue: response.data.rls.value
                }));

                document.getElementsByClassName('card-' + type)[0].classList.remove("is-flipped");
              }
              else {
                // NO
                localStorage.removeItem("bml_session");
                window.top.location.href = '/';
              }
            }, 500);

          });
    }

  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Come andrà il mio matrimonio? - bemylove</title>
          <meta name="description" content="Scoprilo con il gioco di carte di bemylove.it! Calcola la percentuale di Amore, Fortuna, Salute e Denaro che avrai nel tuo matrimonio! Cosa aspetti? Iscriviti e gioca con noi!" />
          <meta property="og:title" content="Scopri come andrà il tuo matrimonio - bemylove" />
          <meta property="og:url" content="https://bemylove.it/come-andra-il-mio-matrimonio" />
          <link rel="canonical" href="https://bemylove.it/come-andra-il-mio-matrimonio"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site PlayCard">
          <div className="cover" style={ this.state.styleTitle }>
            <h1 className="text-center">Come andrà il mio matrimonio?</h1>
            <p className="content-text text-center">
              Arrivate preparati al giorno più importante della vostra vita e
              scoprite come andrà giocando con le carte di <strong>bemylove!</strong>
            </p>
          </div>
          <div className="row">
            <div className="col-lg-2 col-sm-4 offset-lg-2 card card-amore" onClick={(e) => this.goTo('amore')}>
              <p className="text-center">Amore</p>
            </div>
            <div className="col-lg-2 col-sm-4 card card-fortuna" onClick={(e) => this.goTo('fortuna')}>
              <p className="text-center">Fortuna</p>
            </div>
            <div className="col-lg-2 col-sm-4 card card-denaro" onClick={(e) => this.goTo('denaro')}>
              <p className="text-center">Denaro</p>
            </div>
            <div className="col-lg-2 col-sm-4 card card-salute" onClick={(e) => this.goTo('salute')}>
              <p className="text-center">Salute</p>
            </div>
          </div>
        </div>
        <Footer />
        <ModalCard show={ this.state.show } />
        <ModalCardRls show={ this.state.showRls } sentence={ this.state.sentence } value={ this.state.sentenceValue } card={ this.state.card.toUpperCase().charAt(0) + this.state.card.slice(1) } />
      </div>
    );
  }
}

export default Playard;
