import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faChurch, faGlassCheers } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faChurch,
  faGlassCheers
});

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      general: {},
      updateSuccess: false,
      updateError: false
    };
  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-general/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              general: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  render() {
    return (
      <div id="register" className="General" >
        <h2 className="text-right">Generali</h2>

        <div className="row-content">
          <div className="row invited-color">
            <div className="col-12">
              <div className="text-center">
                <h4><i>{ this.state.general.sposo }<br />e<br />{ this.state.general.sposa }</i></h4> <br />
                <p>hanno il piacere di invitarvi al loro matrimonio che si terrà in data<br />
                  <span className="text-sign">
                    <Moment format="DD/MM/YYYY">
                      {this.state.general.data}
                    </Moment>
                  </span>
                </p><br />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-12 margin-top-5">
            <div className="card">
              <div className="card-body text-center">
                <p className="size-50"><FontAwesomeIcon icon="church" /></p>
                <p>Indirizzo delle nozze</p>
                <p>{ this.state.general.chiesa }</p>
                <hr />
                <span className="tooltip">
                  <a href={"https://www.google.it/maps/place/" + this.state.general.chiesa } target="_blank" rel="noopener noreferrer" className="bml-icon"><FontAwesomeIcon icon="map-marker-alt" /></a>
                  <span className="tooltiptext" style={ { top: '-60px', left: '12px' } }>Guarda dove si trova!</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 margin-top-5">
            <div class="card">
              <div className="card-body text-center">
                <p className="size-50"><FontAwesomeIcon icon="glass-cheers" /></p>
                <p>Indirizzo della cerimonia</p>
                <p>{ this.state.general.ricevimento }</p>
                <hr />
                <span className="tooltip">
                  <a href={"https://www.google.it/maps/place/" + this.state.general.ricevimento } target="_blank" rel="noopener noreferrer" className="bml-icon"><FontAwesomeIcon icon="map-marker-alt" /></a>
                  <span className="tooltiptext" style={ { top: '-60px', left: '12px' } }>Guarda dove si trova!</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6 col-12 margin-top-5">
            <div className="card">
              <div className="card-body text-center">
                <p className="size-50"><FontAwesomeIcon icon="male" /></p>
                <p>Indirizzo dello sposo</p>
                <p>{ this.state.general.casaSposo }</p>
                <hr />
                <span className="tooltip">
                  <a href={"https://www.google.it/maps/place/" + this.state.general.casaSposo } target="_blank" rel="noopener noreferrer" className="bml-icon"><FontAwesomeIcon icon="map-marker-alt" /></a>
                  <span className="tooltiptext" style={ { top: '-60px', left: '12px' } }>Guarda dove si trova!</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 margin-top-5">
            <div class="card">
              <div className="card-body text-center">
                <p className="size-50"><FontAwesomeIcon icon="female" /></p>
                <p>Indirizzo della sposa</p>
                <p>{ this.state.general.casaSposa }</p>
                <hr />
                <span className="tooltip">
                  <a href={"https://www.google.it/maps/place/" + this.state.general.casaSposa } target="_blank" rel="noopener noreferrer" className="bml-icon"><FontAwesomeIcon icon="map-marker-alt" /></a>
                  <span className="tooltiptext" style={ { top: '-60px', left: '12px' } }>Guarda dove si trova!</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <hr />
        <div className="row-content"style={ this.state.general.descrizione == '' ? { display: 'none' } : { display: 'block' } }>
          <div className="row invited-color">
            <div className="col-12">
              { this.state.general.descrizione }
            </div>
          </div>
        </div>
        <hr />

        <div className="row-content">
          <div className="row">
            <div className="col-12">
              <div className="title-section">
                <p>Info sposi <FontAwesomeIcon icon="ring" /><FontAwesomeIcon icon="ring" className="my-ring"/></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <p>Email</p>
                <p className="border-bottom-color">
                  <a href={ "mailto:"+this.state.general.email }>{ this.state.general.email }</a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <p>Telefono</p>
                <p className="border-bottom-color">
                  <a href={ "tel:"+this.state.general.telefono }>{ this.state.general.telefono }</a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default General;
