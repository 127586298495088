import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSignInAlt, faMale, faFemale } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

import MetaTags from 'react-meta-tags';

library.add(
  faSignInAlt,
  faMale,
  faFemale
)

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false,
      redirect: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({redirect: false});
    if( localStorage.getItem('bml_session') ) {
      this.setState({redirect: true});
      axios.post(process.env.REACT_APP_API_URL + '/login-session/', { token: localStorage.getItem('bml_session') } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              this.props.history.push(
                '/' + response.data.access + '/home',
                {
                  id: response.data.user_id,
                  level: response.data.user_level,
                  page: 'home',
                  type: response.data.access
                }
              );
            }
            else {
              // NO
              this.setState({error: true});
            }
          });
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    if( this.state.value.length === 8 ) {
      axios.post(process.env.REACT_APP_API_URL + '/login/', { login: this.state.value } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              localStorage.setItem("bml_session", response.data.token);
              this.props.history.push(
                '/' + response.data.access + '/home',
                {
                  id: response.data.user_id,
                  level: response.data.user_level,
                  page: 'home',
                  type: response.data.access
                }
              );
            }
            else {
              // NO
              this.setState({error: true});
            }
          });
    }
    else {
      this.setState({error: true});
    }
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>Login - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Login - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div id="login" className="">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="150" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row" style={this.state.redirect ? { display: 'block' } : { display: 'none' } }>
            <h2 className="text-center">Attendi... stiamo recuperando i tuoi dati...</h2>
          </div>
            <div className="row" style={this.state.redirect ? { display: 'none' } : { display: 'block' } }>
              <div className="col-md-6 offset-md-3">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group pos-ipad">
                    <label htmlFor="access-app">Inserisci il Codice</label>
                    <input
                      type="text"
                      id="access-app"
                      aria-describedby="Inserisci qui il Codice"
                      maxLength="8"
                      value={this.state.value}
                      onChange={this.handleChange} />
                    <p id="error-login" style={this.state.error ? { display: 'block' } : {} }>
                      Codice non corretto o account non validato.<br />
                      <small>
                        Prima di accedere, assicurati di aver confermato il tuo account tramite
                        l'email che ti è arrivata in fase di registrazione. Se non hai ricevuto
                        alcuna email, controlla nello SPAM o scrivi a <a href="mailto:info@bemylove.it">info@bemylove.it</a>
                      </small>
                    </p>
                    <small><Link to="/reset-password">Hai dimenticato la password?</Link></small>
                  </div>
                  <div className="row">
                    <div className="col-md-5 offset-md-1">
                      <button type="submit" className="button button-hover-submit" style={{ margin: 0, border: '1px solid white' }}>Accedi <FontAwesomeIcon icon="sign-in-alt" /></button>
                    </div>
                    <div className="col-md-5">
                      <Link to="/registrati">
                        <div className="button center-single-btn" style={{ backgroundColor : '#ef5aa7', border: '1px solid #ef5aa7' }}>
                          Vuoi sposarti? <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Login;
