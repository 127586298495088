import React, { Component } from 'react';
import axios from 'axios';
import ProgramLists from '../../../partials/programs/ProgramLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Results from '../../../partials/results/Results';
import VideoTutorial from '../../../partials/video-tutorial/VideoTutorial';


class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allPrograms: [],
      updateInfo: false,
      resultsText: "Programma aggiornato!"
    };

    this.updatePrograms = this.updatePrograms.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-programs/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allPrograms: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  updatePrograms( event ) {
    let input = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      programs: this.state.allPrograms
    };

    axios.post(process.env.REACT_APP_API_URL + '/update-programs/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              updateInfo: true
            });

            setTimeout( () => {
            this.setState({
              updateInfo: false
            });
            }, 2000);
          }
          else {
            // NO
            this.setState({
              updateInfo: true
            });
            setTimeout( () => {
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }, 2000);
          }

        });
    event.preventDefault();
  }

  render() {
    return (
      <div id="register"  className="Program" >
        <h2 className="text-right">
          Programma 
          <VideoTutorial url="https://youtu.be/15Emsd82seI" />
        </h2>
        <ProgramLists allPrograms={ this.state.allPrograms } isRegister="0" />
        <div className="row text-center">
          <div className="col center-single-btn">
            <button type="submit" className="button" onClick={ this.updatePrograms }>Salva Cambiamenti <FontAwesomeIcon icon="chevron-right" /></button>
          </div>
        </div>
        <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
      </div>
    );
  }
}

export default Program;
