import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faThList, faUtensils } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faTrash,
  faUtensils,
  faThList
});

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      antipasto: [],
      primi: [],
      secondi: [],
      contorni: [],
      dolci: [],
      bevande: [],
      allMenu: 0
    };

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-menu/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              antipasto: response.data.rls.filter(function(value) {
                                                    return value.type === 0;
                                                  }),
              primi: response.data.rls.filter(function(value) {
                                                    return value.type === 1;
                                                  }),
              secondi: response.data.rls.filter(function(value) {
                                                    return value.type === 2;
                                                  }),
              contorni: response.data.rls.filter(function(value) {
                                                    return value.type === 3;
                                                  }),
              dolci: response.data.rls.filter(function(value) {
                                                    return value.type === 4;
                                                  }),
              bevande: response.data.rls.filter(function(value) {
                                                    return value.type === 5;
                                                  }),
              allMenu: response.data.rls.length,
            });

          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  is0( value ) {
    return value.type === 0 ? true : false;
  }

  render() {
    return (
      <div id="register" className="List InvitedList InvitedMenu" >
        <h2 className="text-right"><strong>Menu di nozze</strong></h2>

          <div className="content-elms" style={ this.state.allMenu== 0 ? { display: 'block' } : { display: 'none' }}>
            <div className="row-contents">
              <div className="row">
                <div className="col-12">
                  <p className="text-center">Gli sposi non hanno ancora inserito il Menù di Nozze</p>
                </div>
              </div>
            </div>
          </div>

        <div className="content-elms">
          <div className="row text-center" style={this.state.antipasto.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Antipasti</p>
                <hr />
              </div>
            </div>
            {this.state.antipasto.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
          <div className="row text-center" style={this.state.primi.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Primi</p>
                <hr />
              </div>
            </div>
            {this.state.primi.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
          <div className="row text-center" style={this.state.secondi.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Secondi</p>
                <hr />
              </div>
            </div>
            {this.state.secondi.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
          <div className="row text-center" style={this.state.contorni.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Contorni</p>
                <hr />
              </div>
            </div>
            {this.state.contorni.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
          <div className="row text-center" style={this.state.dolci.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Dolci</p>
                <hr />
              </div>
            </div>
            {this.state.dolci.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
          <div className="row text-center" style={this.state.bevande.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <div className="col-12" style={ { paddingBottom: '0'} }>
              <div className="title-section">
                <p>Bevande</p>
                <hr />
              </div>
            </div>
            {this.state.bevande.map((value, index) => (
              <div className="col-12" key={index} style={ { fontStyle: 'italic'} }>
              <FontAwesomeIcon icon="utensils" className="margin-icons" />
              { value.what }
            </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
