import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleFourteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Trucco sposa: consigli per un make-up impeccabile per il giorno delle nozze!</title>
          <meta name="description" content="Subito dopo aver scelto l’abito per il grande giorno, è di fondamentale importanza per le spose dedicarsi alla scelta del trucco. La chiave per la perfezione sta sicuramente nel trovare il giusto mix che valorizzi i vostri tratti, senza stravolgere il vostro look." />
          <meta property="og:title" content="Trucco sposa: consigli per un make-up impeccabile per il giorno delle nozze!" />
          <meta property="og:url" content="https://bemylove.it/blog/trucco-sposa-consigli-make-up-impeccabile-per-il-giorno-delle-nozze" />
          <link rel="canonical" href="https://bemylove.it/blog/trucco-sposa-consigli-make-up-impeccabile-per-il-giorno-delle-nozze" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/fourteen/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Trucco sposa: consigli per un make-up impeccabile per il giorno delle nozze!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  31/01/2021
                </time>
              </div>
              <div className="content">
                Subito dopo aver scelto l’abito per il grande giorno, è di fondamentale
                importanza per le spose dedicarsi alla scelta del trucco. La chiave
                per la perfezione sta sicuramente nel trovare il giusto mix che
                valorizzi i vostri tratti, senza stravolgere il vostro look.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Hai già prenotato la tua truccatrice? Scarica l’App di Bemylove
                  e non dimenticare di aggiornare la lista delle cose da fare!
                </a>
                <hr />
                Non è certo un segreto che esiste un make-up sposa perfetto a
                seconda delle caratteristiche di ognuna. Ma per tutte la parola
                d’ordine è: sentirsi a proprio agio e non snaturare la propria
                immagine!
                <br /><br />
                <div>
                  <img src="../media/blog/fourteen/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Se amate i look nude e naturale, i Colori neutri e matte come
                beige e marrone sono perfetti. Questo tipo di look è adatto sia
                a spose con occhi chiari che con occhi scuri. Sarà bene usare
                una base leggera e modulabile poiché deve risultare impeccabile
                ed una passata di mascara che darà profondità allo sguardo.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <strong>Se sei una sposa bionda,</strong> puoi utilizzare per gli occhi tutte le
                tonalità calde come il bronzo e tutte le tonalità del marrone,
                intensificate sulla rima cigliare con il nero e sfumate poi sulla
                palpebra mobile fino ad arrivare sulla palpebra fissa con un tono
                decisamente più chiaro sotto l’arcata sopraccigliare. Per quanto
                riguarda l’ombretto meglio optare per il bianco avorio se il
                trucco è da giorno, e toni un po’ più iridescenti se il trucco
                è per una serata. Per questa tipologia di sposa, il colore del
                fondotinta dovrà essere beige dorato naturale, mentre per le
                labbra saranno perfetti tutti quei colori solari come pesca,
                albicocca, miele e bronzo.
                <br /><br />
                <img src="../media/blog/fourteen/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                <strong>Se sei una sposa dai capelli rossi,</strong> puoi optare per un make-up
                naturale e luminoso con un dettaglio che ne riprenda le sfumature,
                come un gloss dal sottotono caldo. Sono inoltre perfetti i colori
                ambrati e caldi in tutte le loro nuance. Il fondotinta perfetto
                è quello di color beige naturale scaldato poi sulle guance da
                fard molto dorati e caldi.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <strong>Se sei una sposa dai capelli castani o scuri,</strong> saranno perfetti
                tutti i toni della terra, e cioè i colori caldi e naturali,
                matita nera, ombretto semi perlato per la creazione di punti
                luce e una passata leggera di terra e blush per un contouring
                naturale ed elegante che valorizzi anche il viso tondo. Sulle
                labbra un rossetto nude a lunga tenuta dal sottotono caldo e,
                a conclusione, mascara e perché no, ciglia finte per valorizzare
                gli occhi.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/fourteen/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                È bene inoltre ricordare che ogni viso ha una forma: triangolare,
                quadrato, a rombo, tondo… Un trucco sapiente e furbo saprà valorizzare
                qualsiasi forma di viso, modificandone i tratti per renderlo quanto
                più ovale possibile.
                <br /><br />
                <div>
                  <img src="../media/blog/fourteen/four.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Ma come è possibile far durare un trucco perfetto per tutto il giorno?
                Per far sì che con il caldo afoso il vostro trucco si mantenga
                inalterato per molte ore e soprattutto non coli, qualsiasi prodotto
                voi vogliate usare per truccare gli occhi (ombretti, matite o
                anche fard per le guance) dovrà essere amalgamato prima con un
                po’ di correttore e poi applicare il prodotto scelto sul viso.
                Alla fine potrete fissare il tutto con polvere di riso e non vi
                sarà primer che tenga!
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/fourteen/five.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Ma quali sono gli errori più comuni da non commettere per il trucco
                sposa? Ve lo sveleremo nel prossimo articolo!
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleFourteen;
