import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Matrimoni e Covid 19: tutto quello che c’è da sapere sul nuovo DPCM in relazione ai matrimoni</title>
          <meta name="description" content="Le coppie che hanno organizzato il loro matrimonio nel 2020, purtroppo hanno visto i propri programmi essere sconvolti dall’arrivo del nuovo coronavirus. Il coronavirus infatti, ha colpito senza dubbio sia le celebrazioni che l’organizzazione delle nozze." />
          <meta property="og:title" content="Matrimoni e Covid 19: tutto quello che c’è da sapere sul nuovo DPCM in relazione ai matrimoni" />
          <meta property="og:url" content="https://bemylove.it/blog/matrimoni-e-covid-19-tutto-quello-che-ce-da-sapere-sul-nuovo-dcpm-in-relazione-ai-matrimoni" />
          <link rel="canonical" href="https://bemylove.it/blog/matrimoni-e-covid-19-tutto-quello-che-ce-da-sapere-sul-nuovo-dcpm-in-relazione-ai-matrimoni" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/five/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Matrimoni e Covid 19: tutto quello che c’è da sapere sul nuovo DPCM in relazione ai matrimoni</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  03/11/2020
                </time>
              </div>
              <div className="content">
                Le coppie che hanno organizzato il loro matrimonio nel 2020,
                purtroppo hanno visto i propri programmi essere sconvolti
                dall'arrivo del nuovo coronavirus. Il coronavirus infatti, ha
                colpito senza dubbio sia le celebrazioni che l’organizzazione
                delle nozze.
                {/* Massimo 160 caratteri. */}
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Dal marzo 2020, la popolazione del mondo intero ha dovuto fronteggiare
                la lotta a questo nuovo virus, cambiando radicalmente stili di vita,
                comportamenti sociali e molto altro. Da quel momento in poi anche
                le celebrazioni delle nozze e i festeggiamenti sono profondamente
                cambiati, in Italia come nel nesto del mondo. Inizialmente,
                infatti, è stata imposta la distanza sociale e l’uso delle
                mascherine per poi adottare misure sempre più stringenti.
                <br /><br />
                Il 3 Novembre 2020, il Presidente del Consiglio Giuseppe Conte e
                il Ministro della Salute Roberto Speranza hanno firmato un nuovo
                DPCM contenente delle nuove misure per il contenimento della
                diffusione del Covid 19 sull’intero territorio nazionale. Con
                questo nuovo decreto ministeriale, si ribadisce l’uso dei
                dispositivi di protezione delle vie respiratorie nei luoghi al
                chiuso, diversi dalle abitazioni private, o nei luoghi all’aperto
                dove non è possibile mantenere la distanza sociale.
                <br /><br />
                <img src="../media/blog/five/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                In merito alle celebrazioni liturgiche ed in particolare per i
                matrimoni, in chiesa bisogna per favorire il rispetto delle
                norme di distanziamento è necessario ridurre al minimo la
                presenza di concelebranti e ministri, che sono comunque tenuti
                al rispetto della distanza. I fedeli devono rispettare la
                distanza sociale. Per ragioni igienico-sanitarie, non è opportuno
                che nei luoghi destinati ai fedeli siano presenti sussidi per i
                canti o di altro tipo. Le eventuali offerte non devono essere
                raccolte durante la celebrazione, ma attraverso appositi
                contenitori, che possono essere collocati agli ingressi o in
                altro luogo ritenuto idoneo. Bisogna sempre indossare durante
                le celebrazioni liturgiche, i dispositivi di protezione personale.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/five/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <hr />
                <strong>*** Aggiornamento del 10/11/2020 ***</strong><br /><br />
                <strong>
                Ce lo state chiedendo in molti e quindi proviamo a fare chiarezza.
                </strong><br /><br />
                Ci si può sposare in Italia in tempi di Covid? La risposta è Nì.
                Questo perché nonostante ci siano delle norme a livello nazionale,
                il Governo consente alle regioni e ai comuni, di creare delle norme
                più restrittive. <br /><br />
                Quindi, dimenticatevi feste in stile "Il Castello delle Cerimonie" e,
                anche se non è vietato fare pranzi o cene in casa propria, il Governo
                ( e noi di bemylove ci uniamo ), sconsiglia di ricevere in casa propria
                persone non conviventi. <br /><br />
                La situazione giorno dopo giorno cambia, quindi ciò che ci sentiamo
                di consigliarvi è di informarvi presso le vostre istituzioni locali e
                sopratutto di far sempre riferimento al sito del Governo Italiano. Qui
                di seguito vi postiamo alcuni link utili.<br /><br />
                <ul className="list-blog">
                  <li>
                    <FontAwesomeIcon icon="heart" />
                    <a href="http://www.governo.it/it/articolo/domande-frequenti-sulle-misure-adottate-dal-governo/15638#zone" target="_blank">Domande frequenti (sito Governo Italiano)</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon="heart" />
                    <a href="http://www.governo.it/sites/new.governo.it/files/Dpcm_3novembre2020.docx" target="_blank">DPCM 3 Novembre 2020 (sito Governo Italiano)</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon="heart" />
                    <a href="http://www.governo.it/sites/new.governo.it/files/Dpcm_20201103_allegati_.pdf" target="_blank">DPCM 3 Novembre 2020 (sito Governo Italiano)</a>
                  </li>
                </ul>
                <hr />
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                <br /><br />
                È certo che questo virus ha sconvolto le nostre vite e le nostre
                abitudini di tutti i giorni, ma ognuno di noi in questo momento
                è chiamato per fare la sua parte nella lotta al Covid 19. Se
                ciò avverrà, presto torneremo alla nostra vita senza alcuna
                restrizione e liberi di festeggiare il nostro giorno più bello
                come lo abbiamo sempre sognato.
                <br /><br />
                </div>
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleFive;
