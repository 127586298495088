import React, { Component } from 'react';
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faGift } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faGift
});

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allGifts: [],
      updateSuccess: false,
      updateError: false
    };

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-gifts/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allGifts: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  render() {
    return (
      <div id="register" className="List InvitedList" >
      <h2 className="text-right">Lista nozze</h2>
      <div>

      <div className="content-elms" style={ this.state.allGifts.length == 0 ? { display: 'block' } : { display: 'none' }}>
        <div className="row-contents">
          <div className="row">
            <div className="col-12">
              <p className="text-center">Gli sposi non hanno ancora inserito la Lista Nozze</p>
            </div>
          </div>
        </div>
      </div>

        <div className="content-elms">
        { this.state.allGifts.map( (giftList, key) => {
              return (
                  <div key={key}  className={ giftList.done == 1 ? 'row strike' : 'row' }>
                    <div className="col-lg-8 col-12">
                      <div className="row" style={{ borderBottom: "0" }}>
                        <div className="col-lg-6 col-12">
                          <p><FontAwesomeIcon icon="gift" className="margin-icons" /> { giftList.what }</p>
                          <p><FontAwesomeIcon icon="map-marker-alt" className="margin-icons" /> { giftList.whither }</p>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="d-flex justify-content-center"><FontAwesomeIcon icon="tag" className="margin-icons" /> { parseFloat(giftList.price).toFixed(2).replace(".", ",") }€</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-6 text-center tooltip">
                      <a href={"https://www.google.it/maps/place/" + giftList.whither } target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon className="margin-top-50" icon="map-marker-alt" /></a>
                      <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '30px', left: '85px' } }>Guarda dove si trova!</span>
                    </div>
                    <div className="col-lg-2 col-6 text-center tooltip" >
                      <FontAwesomeIcon className="margin-top-50" icon={ giftList.done == 0 ? 'times' : 'check'} />
                      <span className="tooltiptext" style={  window.innerWidth < 1000 ? { top: '-76px' } : giftList.done == 1 ? { top: '20px' } : { top: '10px' } }>{ giftList.done == 0 ? 'Non hanno ancora ricevuto questo regalo' : 'Regalo già acquistato'}</span>
                    </div>
                  </div>
                );
            }) }
          </div>
        </div>
      </div>
    );
  }
}

export default List;
