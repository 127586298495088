import React, { Component } from 'react';
import axios from 'axios';

import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight } from '@fortawesome/free-solid-svg-icons';

class Step1 extends Component {
  constructor(props) {

    super(props);

    this.state = {
      success: false,
      error: false,
      imageSposo: "",
      imageSposa: "",
      nameImage: "",
      hideImage: true,
      waitSave: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getImage = this.getImage.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session') ) {
      document.getElementsByClassName("row-action-footer")[0].style.display = "none";
    }
  }

  setValue() {
    this.refs.email.value = this.state.email;
  }

  handleSubmit(event) {
    if( this.state.imageSposo == "" || this.state.imageSposa == "" ) {
      return;
    }
    this.setState({
      loadInfo: true,
      waitSave: true
    });
    let input = {
      token: localStorage.getItem("bml_session"),
      imageSposo: this.state.imageSposo,
      imageSposa: this.state.imageSposa,
      oldImage: false,
      bigImage: false,
    };

    axios.post(process.env.REACT_APP_API_URL + '/minilove-step-1/', input)
        .then(response => {

          if(response.data.error.status === false) {
            // OK
            this.props.history.push(
              '/minilove/step-2',
              {
                id: response.data.user_id,
                image: response.data.image
              }
            );
          }
          else {
            // NO
            //document.getElementsByTagName("form")[0].style.display = "none";
            this.setState({
              success: false,
              error: true,
              loadInfo: false,
              waitSave: false
            });
          }

        });
    event.preventDefault();
  }

  getImage( event, ctrl ) {
    //var img = document.querySelector('#preview');
    var img1 = document.querySelector('#preview-' + ctrl);
    this.setState({
      hideImage: true
    });

    var files = event.target.files;
    if(files[0].size > 5000000) {

      img1.src = "";
      this.setState({
        bigImage: true,
        oldImage: false
      });
      return;
    }
    else {
      this.setState({
        oldImage: true,
        bigImage: false
      });
    }
    if (files.length > 0) {
      var file = files[0];
      //$('#snackbar').removeClass('active');
      if(file.name.indexOf('.') == -1) {
        //this.errorSnackbarService.snackbarMessage(-1, 'Nel nome dell\'immagine manca l\'estensione es: ".jpg" o ".png"', 10000);
        return;
      }

      var reader = new FileReader();
      reader.onload = (function (aImg1) {
        return function (e) {
          aImg1.src = e.target.result;
          //aImg1.style.backgroundImage  = 'url('+e.target.result+')';
        };
      })(img1);

      reader.readAsDataURL(file);

      setTimeout( () => {
        if( ctrl == 1 ) {
          this.setState({
            imageSposo: reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""),
          });
        }
        else {
          this.setState({
            imageSposa: reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""),
          });
        }
        if( this.state.imageSposo == "" || this.state.imageSposa == "" ) {
          this.setState({
              hideImage: true
            });
        }
        else {
          this.setState({
              hideImage: false
            });
        }
      }, 1500);
    }

  }

  render() {
    return (
      <div>
        <div className="Site Minilove_1" style={{ paddingTop: '85px'}}>
          <div className="row">

          <form onSubmit={this.handleSubmit} style={{ width: '100%' }}>

            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h3>Inserite i vostri selfie</h3>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-group centered-input">
                  <input type="file" name="file" id="file" className="inputfile" onChange={ (e, ctrl) => this.getImage(e, 1) } />
                  <img src="../img/sposo.png" className="button-image" />
                </div>
                <p className="text-center">Immagine Sposo</p>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-group">
                  <img src="" id="preview-1" className="chat-img" width="250" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-group">
                <img src="" id="preview-2" className="chat-img" width="250" />

                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-group centered-input">
                  <input type="file" name="file" id="file" className="inputfile" onChange={ (e, ctrl) => this.getImage(e, 2) } />
                  <img src="../img/sposa.png" className="button-image" />
                </div>
                <p className="text-center">Immagine Sposa</p>
              </div>
            </div>

            {/* 3 */}<div className="col-sm-12 bg-white">

            {/* 2 */}<div className="row">

              {/* 1 */}<div className="col-lg-12 text-center" style={ this.state.hideImage ? { display: 'none'} : { display: 'block'} }>

                <div id="preview-1-"></div>
                <div id="preview-2-"></div>
                <img src="" id="preview-1" className="chat-img" width="250" />
                <img src="" id="preview-2" className="chat-img" width="250" />

                <div className="center-single-btn" style={ !this.state.oldImage ? { display: 'none'} : { display: 'block', paddingTop: '10px' } }>
                  <button
                    type="submit"
                    className="btn-submit"
                    style={ this.state.waitSave ? { display: 'none'} : { display: 'block', backgroundColor: '#ef5aa7' } }>
                      Prosegui <FontAwesomeIcon icon="chevron-right" />
                    </button>

                  <p className="waitSave text-center" style={ !this.state.waitSave ? { display: 'none'} : { display: 'block' } }>Salvataggio in corso...</p>
                </div>


                <br /><br />

                {/* 1 */}</div>
                <div className="waitSave text-center" style={ !this.state.bigImage ? { display: 'none'} : { display: 'block'} }>
                  Immagine troppo grande.
                </div>

              {/* 2 */}</div>
            {/* 3 */}</div>

          </form>

          </div>
        </div>
      </div>
    );
  }
}

export default Step1;
