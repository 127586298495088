import React, { Component } from 'react';
import axios from 'axios';

import Footer from '../partials/Footer';
import Header from '../partials/Header';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight } from '@fortawesome/free-solid-svg-icons';

import MetaTags from 'react-meta-tags';

class UnSubscribe extends Component {
  constructor(props) {

    super(props);

    this.state = {
      email: '',
      success: false,
      error: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
    this.setState({
      email: this.props.match.params.email
    });
    setTimeout( () => {
      this.setValue();
    }, 500);
  }

  setValue() {
    this.refs.email.value = this.state.email;
  }

  handleSubmit(event) {
    let input = {
      q1: this.refs.q1.value,
      q2: this.refs.q2.value,
      q3: this.refs.q3.value,
      q4: this.refs.q4.value,
      q5: this.refs.q5.value,
      q6: this.refs.q6.value,
      email: this.refs.email.value
    };

    axios.post(process.env.REACT_APP_API_URL + '/unsubscribe/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              success: true,
              error: false,
            });
            document.getElementsByTagName("form")[0].style.display = "none";
          }
          else {
            // NO
            document.getElementsByTagName("form")[0].style.display = "none";
            this.setState({
              success: false,
              error: true,
            });
          }

        });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site UnSubscribe">
          <div className="cover" style={{ backgroundImage: 'none', paddingBottom: '9px', marginBottom: '0'}}>
            <h1 className="text-center">Rimuovi iscrizione</h1>
          </div>
          <div className="row" style={{ backgroundColor: 'white' }}>
            <div className="col-lg-4 col-sm-12 offset-lg-4">
              <p style={ this.state.success == false && this.state.error == false ? { display: 'block', width: '100%' } : { display: 'none' } }>
              Ciao,<br />
              ci dispiace che <strong>bemylove</strong> non si uno strumento utile per il tuo matrimonio.<br /><br />
              Noi ci teniamo ad essere uno strumento di aiuto per i futuri sposi e
              vorremmo che <strong>bemylove.it</strong> diventasse un luogo sicuro, piacevole e accogliente
              per i futuri sposi.<br /><br /> Aiutaci a migliorare rispondendo a queste domande:
              </p>
            </div>
            <form onSubmit={this.handleSubmit} style={{ width: '100%'}}>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Come ci hai conosciuto?<br />
                    </label>
                    <textarea ref="q1" id="q1" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Perché hai scelto di cancellarti da <strong>bemylove.it</strong>?<br />
                    </label>
                    <textarea ref="q2" id="q2" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Cosa non ti piace di <strong>bemylove.it</strong>?<br />
                    </label>
                    <textarea ref="q3" id="q3" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Cosa ti piace di <strong>bemylove.it</strong>?<br />
                    </label>
                    <textarea ref="q4" id="q4" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Hai dei consigli da darci?<br />
                    </label>
                    <textarea ref="q5" id="q5" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Hai qualcosa da dirci?<br />
                    </label>
                    <textarea ref="q6" id="q6" rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 offset-lg-4">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      Questa è l'email che hai scelto di far rimuovere<br />
                    </label>
                    <input ref="email" type="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" disabled />
                  </div>
                </div>
              </div>
              <div className="col center-single-btn">
                <button type="submit" className="btn-submit">Rimuovi la mia iscrizione <FontAwesomeIcon icon="chevron-right" /></button>
              </div>
              <br /><br />
            </form>
            <div style={ this.state.success == true ? { display: 'block', width: '100%' } : { display: 'none' } }>
              <h2 className="text-center">Grazie!</h2>
              <p className="text-center text-rsl">
                La tua rimozione è avvenuta con successo!
              </p>
            </div>
            <div style={ this.state.error == true ? { display: 'block', width: '100%' } : { display: 'none' } }>
              <p className="text-center text-rsl">
                Si è verificato un errore durante la cancellazione.<br />
                Per favore scrivici richiedendo la cancellazione.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default UnSubscribe;
