import React, { Component } from 'react';
import { Link, Switch, Router } from "react-router-dom";

import Footer from './partials/Footer';
import Header from './partials/Header';

import MetaTags from 'react-meta-tags';

class CondizioniLegali extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Condizioni Legali - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Condizioni Legali - bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/condizioni-legali" />
          <link rel="canonical" href="https://bemylove.it/condizioni-legali"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Condizioni Legali</h1>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-md-1 col-sm-12 bg-white">
              <div>
                <ol>
                  <li>
                    <h3>Informazioni di contatto</h3>
                    <p>Email: info[chiocciola]bemylove.it</p>
                  </li>
                  <li>
                    <h3>Condizioni generali di utilizzo del portale bemylove.it e informativa agli utenti</h3>
                    <ol type="a">
                      <li>
                        <h4>Preambolo</h4>
                        <p>
                          Il sito web bemylove.it (di seguito potrebbe anche essere definito, il "Sito").
                          L'accesso e l'utilizzo del Sito attribuiscono automaticamente a colui che lo
                          effettua la condizione di Utente. Si considerano Utenti le persone fisiche
                          che utilizzano il Sito in qualità di consumatori in relazione ai servizi
                          offerti da bemylove.it.<br /><br />
                          L'accesso e l'utilizzo del Sito implicano l’accettazione piena e senza
                          riserve degli Utenti del contenuto delle presenti "Condizioni generali
                          di utilizzo", della "Politica sulla protezione dei dati personali", nonché,
                          se applicabili, delle "Condizioni particolari" eventualmente accessorie,
                          sostitutive o modificative in alcun modo delle presenti Condizioni generali
                          di utilizzo rispetto ai servizi e ai contenuti del Sito. L'Utente ha facoltà
                          di accedere, stampare, scaricare e archiviare in ogni momento le presenti
                          Condizioni generali di utilizzo. Le presenti Condizioni generali di utilizzo
                          sono permanentemente consultabili sul Sito accedendo al link "Condizioni Legali".<br /><br />
                          bemylove.it si riserva il diritto di modificare, senza preavviso, il
                          contenuto delle presenti Condizioni generali di utilizzo, si raccomanda
                          pertanto agli Utenti di leggere attentamente il testo delle stesse prima
                          dell’accesso e dell’utilizzo di qualsiasi servizio del Sito.<br /><br />
                          bemylove.it ha altresì la facoltà di modificare, senza preavviso,
                          la realizzazione e la presentazione grafica e/o la configurazione del
                          Sito, nonché alcuni o tutti i servizi o le funzionalità dello stesso,
                          con facoltà di sopprimerli e/o aggiungerne degli altri.<br /><br />
                          bemylove.it si riserva comunque il diritto, in qualsiasi momento
                          e senza preavviso, di negare l'accesso al Sito agli Utenti che violino
                          una qualsiasi delle presenti Condizioni generali di utilizzo, delle
                          Condizioni particolari eventualmente applicabili, o che, a discrezione
                          di bemylove.it, non utilizzino correttamente il Sito o ne abbiano
                          tratto vantaggio in modo unilaterale per un tempo determinato.
                        </p>
                      </li>
                      <li>
                        <h4>Oggetto</h4>
                        <p>
                          Le presenti Condizioni generali di utilizzo disciplinano l'accesso e
                          l'utilizzo del Sito, il cui scopo è quello di costituire un punto di
                          riferimento per le coppie di futuri Sposi in procinto di organizzare
                          le loro nozze.<br /><br />
                          A tal fine, bemylove.it mette a diSposizione degli Sposi una serie
                          di strumenti a titolo informativo per i partecipanti (nel dettaglio gli
                          Invitati al matrimonio) riguardo il giorno delle nozze. Tramite
                          bemylove.it gli Invitati conosceranno la lista nozze, il
                          programma, daranno la conferma alla loro partecipazione,
                          consultare il menù di nozze e potranno messaggiare con gli Sposi
                          per avere più informazioni.<br /><br />
                          bemylove.it si impegna a facilitare agli Invitati l’accesso a
                          dati e/o informazioni forniti dagli Sposi esclusivamente a condizione
                          che tali dati e/o informazioni non violino le normative vigenti o le
                          presenti Condizioni generali di utilizzo.<br /><br />
                          bemylove.it si riserva il diritto di rimuovere dal Sito,
                          senza preavviso, ogni genere di contenuti ritenuti contrari
                          alle normative vigenti o che violino o siano suscettibili di
                          violare eventuali diritti di terzi, nonché quei contenuti che
                          possano ritenersi inappropriati, inadeguati o che non soddisfino
                          i requisiti minimi di qualità desiderati.<br /><br />
                          L'Utente prende atto dell’impossibilità tecnica di garantire
                          una disponibilità al 100% del Sito. Ciononostante, bemylove.it
                          si impegna a rendere disponibile il Sito nella forma più
                          continuativa possibile. Per particolari ragioni di manutenzione,
                          sicurezza o capacità, nonché con motivo di eventi estranei al
                          controllo di bemylove.it (ad esempio, malfunzionamento di
                          reti pubbliche di comunicazioni, interruzioni di corrente, ecc.),
                          è possibile che si verifichino brevi anomalie o la sospensione
                          temporanea dei servizi del Sito.<br /><br />
                          bemylove.it si riserva il diritto di eliminare unilateralmente
                          e senza preavviso l’account degli Utenti che siano stati oggetto di
                          ripetuti reclami di cui bemylove.it sia venuta a conoscenza.
                        </p>
                      </li>
                      <li>
                        <h4>Obblighi degli Utenti del Sito</h4>
                        <p>
                          L'Utente si impegna a fare un uso diligente del Sito e dei servizi
                          accessibili sullo stesso, in piena osservanza della legge, dei buoni
                          costumi e delle presenti Condizioni generali di utilizzo, delle
                          Condizioni particolari eventualmente applicabili, nonché a trattare
                          con il dovuto rispetto gli altri Utenti. Ai sensi del paragrafo
                          quarto della clausola 2.a di cui sopra, bemylove.it si riserva
                          il diritto di ritirare qualsiasi contenuto che, a sua discrezione,
                          non soddisfi i requisiti minimi di qualità.<br /><br />
                          Il Sito bemylove.it è composto da una parte promozionale della
                          piattaforma dove chiunque può accedere e un'altra parte dove
                          l’accesso è consentito solo dopo previa registrazione da parte
                          degli Sposi. Dopo la registrazione infatti, agli Sposi vengono
                          forniti 2 codici, uno è per gli Sposi e uno per permettere agli
                          Invitati di accedere alla loro parte dedicata.<br /><br />
                          L'uso dei codici è personale dal punto di vista degli Sposi.
                          Il codice dedicato agli Invitati è trasferibile agli Invitati
                          del proprio matrimonio. L’Utente (cioè gli Sposi) è, quindi,
                          l'unico responsabile dell'utilizzo della proprio codice e di
                          quello degli Invitati, esonerando da qualsiasi responsabilità
                          bemylove.it. Qualora l'Utente sospetti o venga a conoscenza
                          dell'uso di uno dei 2 codici da soggetti non autorizzati, è tenuto
                          a riferire tale evento a bemylove.it con la maggior brevità possibile.<br /><br />
                          Tutte le informazioni fornite dall'Utente, con qualsiasi meccanismo
                          abilitato ad ottenere la prestazione dei servizi offerti da bemylove.it,
                          devono essere veritiere ed esatte. Agli effetti delle presenti Condizioni Legali,
                          l'Utente garantisce l'autenticità di tutti i dati comunicati in seguito
                          alla compilazione dei moduli o delle risposte a richieste effettuate
                          da bemylove.it e che risultino necessarie per la sottoscrizione
                          ai Servizi.<br /><br />
                          È altresì responsabilità dell'Utente mantenere tutte le informazioni
                          fornite a bemylove.it permanentemente aggiornate, in modo da
                          riflettere in ogni momento la situazione reale dell'Utente. In ogni
                          caso, l'Utente è l’unico responsabile in caso di dichiarazioni false
                          o inesatte rese, nonché di informazioni false o inesatte fornite, per
                          eventuali danni e perdite cagionati a bemylove.it o a terzi per tale motivo.<br /><br />
                          L'Utente è tenuto a rispettare le normative vigenti e i
                          diritti di terzi durante l’utilizzo dei contenuti e dei servizi
                          del Sito. È inoltre vietata la riproduzione, distribuzione,
                          trasmissione, adattamento o modifica, con qualsiasi mezzo e in qualsiasi
                          forma, dei contenuti del Sito (testi, disegni, grafica, informazioni,
                          database, file audio e/o video, loghi, ecc.) e altri elementi del
                          presente Sito, salvo previa autorizzazione dei legittimi proprietari
                          o se consentito dalla legge.<br /><br />
                          In particolare, all'Utente è fatto divieto di: utilizzare contenuti
                          offensivi o diffamatori, indipendentemente dal fatto che tali contenuti
                          riguardino altri Utenti o altre persone fisiche o giuridiche; utilizzare
                          contenuti pornografici o suscettibili di violare le normative vigenti a
                          tutela dei minori o pubblicizzare, offrire o distribuire prodotti
                          pornografici o suscettibili di violare dette leggi a tutela dei minori;
                          molestare o danneggiare altri Utenti o terzi (in particolare, per mezzo di spam);
                          utilizzare contenuti legalmente protetti (ad esempio, dalle leggi sulla
                          tutela di proprietà intellettuale, marchi, brevetti, modelli di utilità
                          o modelli estetici) senza averne il diritto, o pubblicizzare, offrire
                          o distribuire beni o servizi legalmente protetti; nonché commettere
                          o istigare atti contrari alla libera concorrenza, inclusi quelli
                          diretti alla captazione massiva di clienti (come sistemi a
                          effetto valanga, catene di sant'Antonio, sistemi piramidali
                          o altri). È fatto altresì divieto agli Utenti, in qualità di
                          consumatori, di promuovere beni e servizi a proprio esclusivo
                          vantaggio o a quello di persone a loro vincolate.<br /><br />
                          È fatto divieto all'Utente di realizzare, a mero titolo esemplificativo
                          e non limitativo, tra le altre, le seguenti attività:<br /><br />
                          <ul>
                            <li><p>Utilizzare strumenti, software o script associati all'utilizzo del Sito;</p></li>
                            <li><p>Bloccare, sovrascrivere, modificare o copiare, salvo nella misura necessaria per il corretto utilizzo dei servizi del Sito. Ad esempio, la copia o la riproduzione mediante tecnologie di ricerca di tipo "Robot/Crawler" non è necessaria ai fini del corretto utilizzo del Sito, motivo per cui tale pratica è espressamente vietata;</p></li>
                            <li><p>Diffondere e divulgare al pubblico senza previa autorizzazione contenuti del Sito o di altri Utenti;</p></li>
                            <li><p>Qualsiasi azione atta a pregiudicare la funzionalità dell'infrastruttura di bemylove.it, specialmente al fine di sovraccaricarla;</p></li>
                            <li><p>
                              Utilizzare qualsiasi materiale o informazione contenuti nel presente
                              Sito per finalità illecite e/o espressamente vietate dalle presenti
                              Condizioni generali di utilizzo, dalle Condizioni particolari eventualmente
                              applicabili, e/o che risultino contrari ai diritti e agli interessi di
                              bemylove.it, dei suoi membri e/o di terzi. L'Utente è tenuto a rispondere
                              nei confronti di eventuali parti lese per violazione o inadempimento di tali
                              obblighi, qualora danneggi, renda inutilizzabile, sovraccarichi, deteriori
                              o impedisca in qualsiasi modo (incluso mediante inserimento o diffusione di
                              virus informatici) il normale utilizzo dei materiali e delle informazioni
                              contenuti sul Sito, dei sistemi informatici o dei documenti, file e ogni
                              genere di contenuti archiviati su qualsiasi dispositivo informatico (hacking)
                              di bemylove.it, dei suoi membri o di altri Utenti;
                            </p></li>
                            <li><p>Pubblicare commenti o contenuti estranei all'oggetto e alle finalità del Sito;</p></li>
                            <li><p>Inserire dati di contatto in campi differenti da quelli appositamente destinati nei moduli. bemylove.it si riserva il diritto di modificare qualsiasi contenuto che contravvenga a quest’obbligo.</p></li>
                          </ul>
                          L'Utente prende atto e accetta che l'utilizzo del Sito e dei servizi
                          prestati su questo avviene, in ogni caso, unicamente ed esclusivamente
                          sotto la sua responsabilità.<br /><br />
                          L'Utente è tenuto a rispondere per danni e perdite di qualsiasi
                          natura eventualmente sofferti da bemylove.it o da terzi derivanti
                          dall'inadempimento di qualsiasi degli obblighi incombenti sull’Utente
                          in virtù delle presenti Condizioni Legali o delle normative vigenti
                          all’utilizzo del Sito e dei servizi offertivi.<br /><br />
                          bemylove.it non è obbligata a intervenire in caso di controversie
                          insorgenti tra i propri Utenti, o tra i propri Utenti e terzi,
                          suscettibili d'interferire nella corretta prestazione dei servizi,
                          riservandosi il diritto di eliminare i loro account sul Sito o di
                          risolvere qualsiasi contratto in essere, se del caso, fino alla completa
                          composizione di ogni controversia o vertenza.
                        </p>
                      </li>
                      <li>
                        <h4>Obblighi relativi alla pubblicazione di contenuti sul Sito</h4>
                        <p>
                          Tramite la pubblicazione di informazioni, testi, audio, video e/o
                          immagini sul Sito (in appresso, collettivamente, i "Contenuti"),
                          l'Utente dichiara di essere legittimo titolare dei diritti di proprietà
                          intellettuale e industriale su detti Contenuti per la loro riproduzione,
                          la distribuzione e la divulgazione al pubblico con qualsiasi mezzo
                          e su qualsiasi supporto, in tutto il mondo e per l’intera vigenza
                          della tutela legale degli stessi.<br /><br />
                          A tal fine, l'Utente dichiara di possedere il legittimo diritto
                          alla pubblicazione dei Contenuti sul Sito e di manlevare e tenere
                          indenne bemylove.it da qualsiasi reclamo o pretesa eventualmente
                          ricevuti a tale titolo. Gli Utenti sono gli unici responsabili
                          dell'ottenimento di ogni genere di autorizzazioni, permessi o
                          licenze eventualmente necessari per la creazione e pubblicazione
                          di tutti i Contenuti, incluse le autorizzazioni e/o i permessi
                          obbligatori in materia di diritti di immagine.<br /><br />
                          Al momento della pubblicazione dei Contenuti sul Sito,
                          l’Utente autorizza espressamente bemylove.it a utilizzare
                          e diffondere detti Contenuti su qualunque sito web amministrato
                          da bemylove.it o dei social network su cui sia presente.<br /><br />
                          Tutti i Contenuti pubblicati dagli Utenti devono intendersi come
                          formanti parte dell’opera di ingegno costituita dal Sito come
                          oggetto di tutela della disciplina dei diritti di proprietà
                          intellettuale, il cui unico legittimo titolare è bemylove.it.
                          A tal fine, l’Utente che realizzi qualsiasi apporto di Contenuti,
                          dichiara espressamente di cedere liberamente e gratuitamente a
                          favore di bemylove.it i Contenuti apportati e ogni diritto
                          di proprietà intellettuale sugli stessi e, in particolare, i
                          diritti di riproduzione, trasformazione e divulgazione al pubblico
                          in tutto il mondo e per l’intera vigenza della tutela legale
                          degli stessi.<br /><br />
                          bemylove.it si riserva il diritto di impedire la pubblicazione
                          di Contenuti suscettibili, a suo criterio, di pregiudicare
                          la qualità del servizio. È fatto divieto di pubblicare i contenuti:<br /><br />
                        </p>
                        <ul>
                          <li><p>Che risultino presumibilmente illeciti ai sensi delle normative nazionali, comunitarie o internazionali o suscettibili di commettere atti presumibilmente illeciti o comunque contrari ai principi di buona fede;</p></li>
                          <li><p>Che non soddisfino i parametri minimi di qualità stabiliti da bemylove.it per la loro pubblicazione sul Sito;</p></li>
                          <li><p>Suscettibili di violare i diritti fondamentali delle persone, di ferire la sensibilità degli Utenti, di contravvenire a norme di cortesia sul Web o comunque molesti e suscettibili di generare o indurre opinioni negative negli Utenti o terzi;</p></li>
                          <li><p>Che contravvengano ai principi di legalità, onorabilità, responsabilità, tutela della dignità umana, tutela dei minori, tutela dell'ordine pubblico, tutela della privacy, tutela del consumatore e dei diritti di proprietà intellettuale e industriale.</p></li>
                        </ul>
                        <p>
                          Salvo e impregiudicato quanto sopra, bemylove.it ricorda ai propri
                          Utenti che l’analisi dei succitati Contenuti deve condursi da un punto
                          di vista qualitativo e non legale, non essendo bemylove.it legittimata
                          né compente al perseguimento di reati penali o alla denuncia di illeciti civili.<br /><br />
                          bemylove.it si riserva il diritto di ritirare dal Sito i Contenuti
                          considerati non appropriati per le caratteristiche e le finalità del Sito
                          stesso e per gli Utenti. bemylove.it si riserva altresì il diritto di
                          modificare e/o adeguare i Contenuti nella misura del necessario, senza che
                          tali modifiche pregiudichino l'integrità dei Contenuti stessi, al fine di
                          adeguarli ai requisiti formali e di qualità del Sito o dei supporti sui
                          quali detti contenuti siano pubblicabili, ai sensi delle presenti
                          Condizioni Legali.<br /><br />
                          Nonostante bemylove.it adotti misure nell’intento di garantire
                          che i Contenuti non violino i divieti di cui sopra e soddisfino i
                          parametri di qualità di bemylove.it nonché le norme di cui alle
                          presenti Condizioni Legali, bemylove.it non è comunque tenuta a
                          controllare i Contenuti pubblicati, non essendo di conseguenza obbligata
                          ad assumersi alcuna responsabilità a tale titolo.<br /><br />
                          Qualora un Utente venga a conoscenza di Contenuti inappropriati
                          sul Sito, è pregato di contattare bemylove.it rivolgendosi
                          al nostro Servizio clienti all’indirizzo e-mail: info[chiocciola]bemylove.it<br /><br />
                          Per quanto riguarda i Contenuti potenzialmente pregiudizievoli
                          nei confronti di minori, bemylove.it manifesta la propria
                          volontà di favorire il corretto utilizzo di Internet.
                          bemylove.it manifesta, altresì, la propria volontà di
                          evitare la pratica e la diffusione di messaggi o immagini
                          di natura illecita o suscettibili di attentare o istigare ad
                          attentare alla dignità umana, alla sicurezza o ai valori di
                          tutela dell'infanzia e dell'adolescenza.<br /><br />
                          Il Sito riconosce, rispetta e tutela i diritti dei minori
                          nell'ambito della tutela dell'adolescenza e dell'infanzia
                          sia a livello nazionale che internazionale.<br /><br />
                          Ai sensi delle normative vigenti, si considera intromissione
                          illegittima nel diritto all’onore, alla tutela della vita
                          privata e familiare e all'immagine stessa del minore,
                          qualsiasi utilizzo della sua immagine o del suo nome sui
                          mezzi di comunicazione suscettibile di attentare alla sua
                          onorabilità o reputazione, o comunque contrario ai suoi interessi,
                          incluso con il consenso del minore o dei suoi rappresentanti
                          legali o tutori.<br /><br />
                          A tal fine, bemylove.it si riserva il diritto di
                          eliminare l’account di qualsiasi Utente che pubblichi
                          sul Sito qualsiasi Contenuto suscettibile di vulnerare
                          i diritti di cui al paragrafo anteriore, segnalandone
                          la relativa condotta alle pubbliche autorità competenti.
                        </p>
                      </li>
                      <li>
                        <h4>Responsabilità e diffusione di contenuti, dati e/o informazioni degli Utenti</h4>
                        <p>
                          bemylove.it ha facoltà di utilizzare e diffondere i
                          Contenuti pubblicati dagli Utenti sul Sito e, in particolare,
                          di procedere alla diffusione degli stessi su qualsiasi sito
                          web amministrato da bemylove.it o sui social network su
                          cui sia presente, al momento della pubblicazione di Contenuti
                          sul Sito, ragion per cui gli Utenti autorizzano espressamente
                          bemylove.it a utilizzare e diffondere tali Contenuti.<br /><br />
                          In virtù di quanto sopra, bemylove.it si riserva il
                          diritto di modificare o adeguare i Contenuti che così
                          lo richiedano al fine di rispettare la grafica del Sito
                          o di altri supporti di comunicazione impiegati sulla
                          piattaforma di bemylove.it, a condizione che tale
                          modifica o adeguamento rispetti l'integrità del Contenuto
                          pubblicato. I suddetti diritti o licenze d'uso si
                          intendono concessi per tutto il mondo e per l'intera
                          vigenza della tutela legale dei diritti dell'Utente.<br /><br />
                          bemylove.it non è tenuta a controllare l'utilizzo
                          dei servizi prestati effettuato dagli Utenti e, di
                          conseguenza, non è tenuta a garantire che l’utilizzo
                          da parte degli Utenti di detti servizi avvenga in conformità
                          alle presenti Condizioni generali di utilizzo, né che
                          facciano un uso diligente e/o prudente degli stessi,
                          malgrado il loro impegno in tal senso in virtù dell’utilizzo
                          del servizio e dell’accettazione delle presenti
                          Condizioni generali di utilizzo.<br /><br />
                          bemylove.it non è parimenti tenuta a verificare l'identità
                          degli Utenti, né la veridicità, la validità, la completezza
                          e/o l’autenticità dei dati personali forniti dagli Utenti,
                          assumendosi questi ultimi l’impegno di fornire a
                          bemylove.it dati veritieri, aggiornati e rispondenti
                          alle loro reali circostanze, ai sensi delle presenti
                          Condizioni generali di utilizzo di bemylove.it.<br /><br />
                          Salvo e impregiudicato quanto sopra, bemylove.it si
                          riserva il diritto di limitare, totalmente o parzialmente,
                          l'accesso ai servizi a determinati Utenti, nonché di
                          cancellare, sospendere, bloccare, modificare o eliminare
                          determinate tipologie di Contenuti, nonché di eliminare
                          l'account degli Utenti che facciano un uso indebito del
                          Sito o qualora venga a conoscenza della natura illecita
                          o lesiva di atti o di informazioni archiviate nei
                          confronti di eventuali beni o diritti di terzi, mediante
                          l'utilizzo di strumenti tecnologici atti a tale scopo.
                          A tal fine, bemylove.it ha facoltà di instaurare i
                          filtri necessari per evitare che, utilizzando il servizio,
                          si possano pubblicare su Internet contenuti illeciti o
                          nocivi o che il servizio sia utilizzato per finalità
                          contrarie a quelle previste.<br /><br />
                          Gli Utenti possono inoltrare proposte o suggerimenti
                          diretti al miglioramento delle varie informazioni,
                          attività e/o servizi contenuti e/o abilitati sul Sito,
                          rivolgendosi al Servizio clienti di bemylove.it.
                          Qualsiasi innovazione sviluppata in base ad eventuali
                          suggerimenti degli Utenti si intende di esclusiva
                          proprietà di bemylove.it, con rinuncia espressa
                          dell'Utente a far valere ogni diritto sulla stessa.<br /><br />
                          bemylove.it si riserva il diritto di registrare
                          qualsiasi tipo di comunicazione intervenuta tra gli
                          Utenti esclusivamente per finalità di analisi interna
                          e formative, salve e impregiudicate le disposizioni
                          contenute nella sua Politica sulla protezione dei dati
                          personali.
                        </p>
                      </li>
                      <li>
                        <h4>Esclusione di garanzie e responsabilità</h4>
                        <p>
                          Fatti salvi i casi espressamente stabiliti dalle
                          Condizioni generali di utilizzo e restanti condizioni
                          contenute nelle presenti Condizioni Legali, bemylove.it
                          non si assume alcuna responsabilità per danni e perdite di
                          qualsiasi natura eventualmente imputabili a inesattezza,
                          incompletezza o vetustà, nonché ad errori od omissioni
                          attinenti alle informazioni o ai servizi offerti sul
                          Sito o altri Contenuti ai quali sia possibile accedere
                          sul Sito, né si assume alcun obbligo o impegno di
                          verificare o controllare tali Contenuti e informazioni.<br /><br />
                          In aggiunta, pur impiegando tutti i mezzi tecnici a
                          propria disposizione, bemylove.it non è tenuta a
                          garantire la disponibilità, la continuità né l’infallibilità
                          del funzionamento del Sito e, di conseguenza, nei limiti
                          consentiti dalla legislazione vigente, non si assume alcuna
                          responsabilità per danni e perdite di qualsiasi natura
                          eventualmente imputabili alla mancata disponibilità o alla
                          discontinuità del funzionamento del Sito e dei servizi in
                          esso abilitati, nonché a errori di accesso a siti web
                          distinti o da cui tali servizi vengono prestati.<br /><br />
                          bemylove.it non si assume alcuna responsabilità per
                          i Contenuti, i dati e/o le informazioni forniti dagli
                          Utenti, neppure per i contenuti di siti web esterni verso
                          i quali esistano dei link di collegamento. In particolare,
                          bemylove.it non è tenuta a garantire che tali contenuti
                          siano veritieri, che siano funzionali rispetto a una
                          determinata finalità o possano servire a detta finalità.<br /><br />
                          Allo stesso modo, bemylove.it non si assume alcuna
                          responsabilità in merito alle opinioni pubblicate dagli
                          Utenti sul Sito, sui forum, sulle Chat o su altri
                          strumenti di partecipazione o di condivisione di
                          opinioni. Salvo e impregiudicato quanto sopra,
                          bemylove.it si impegna a utilizzare i mezzi a
                          propria disposizione al fine di moderare, nella misura
                          del possibile, le opinioni espresse dagli Utenti sul
                          Sito affinché non violino eventuali diritti di terzi.<br /><br />
                          Infine, bemylove.it non si assume alcuna responsabilità
                          in relazione all’esito di eventuali transazioni effettuate
                          tra Utenti.
                        </p>
                      </li>
                      <li>
                        <h4>Link a siti web di terzi</h4>
                        <p>
                          bemylove.it ha facoltà di inserire nei propri Contenuti
                          dei link a siti web appartenenti e/o amministrati da terzi
                          al fine di facilitare l'accesso alle informazioni
                          disponibili via Internet. Tali link possono essere
                          vincolati o meno ai summenzionati siti web.<br /><br />
                          bemylove.it non si assume alcuna responsabilità
                          derivante dall’inserimento di link tra i Contenuti
                          del Sito e i contenuti alloggiati all’esterno dello
                          stesso, o da qualsiasi altro riferimento a contenuti
                          esterni al presente Sito. Tali link o riferimenti
                          rispondono a finalità meramente informative e non
                          implicano in nessun caso l’esistenza di supporto,
                          approvazione, rapporto commerciale o di altro genere
                          tra bemylove.it e le persone fisiche o giuridiche
                          autrici e/o responsabili di tali contenuti o titolari
                          dei siti web su cui sono alloggiati.
                        </p>
                      </li>
                      <li>
                        <h4>Legislazione applicabile e foro competente</h4>
                        <p>
                          Le presenti Condizioni generali di utilizzo del Sito,
                          così come le restanti condizioni, sono disciplinate dal
                          diritto italiano.<br /><br />
                          Per eventuali controversie da risolversi o azioni
                          legali da esercitarsi derivanti dalla prestazione dei
                          servizi e dai Contenuti del Sito, nonché in merito
                          all'interpretazione, all’applicazione, all’adempimento
                          o inadempimento delle disposizioni contenute nelle
                          presenti Condizioni Legali, bemylove.it e l'Utente,
                          con rinuncia espressa a qualsiasi altro foro
                          eventualmente competente, convengono di sottomettersi
                          alla giurisdizione dei giudici e tribunali della città
                          di Milano.
                        </p>
                      </li>
                      <li>
                        <h4>Contatto</h4>
                        <p>
                          È possibile contattare bemylove.it all’indirizzo
                          e-mail o all’indirizzo postale pubblicati sopra.
                        </p>
                      </li>
                    </ol>
                    <li>
                      <h3>Servizi offerti</h3>
                      <p>
                        I servizi offerti di bemylove.it si suddividono in
                        2 modalità. Esse si differenziano in servizi
                        “standard” e in servizi “beSilver”.
                      </p>
                      <ol type="a">
                        <li>
                          <h4>Standard</h4>
                          <p>
                            Con il servizio standard tutti gli Utenti (Sposi e Invitati) avranno a disposizione le aree del Sito:
                          </p>
                          <ul>
                            <li><p>Generale;</p></li>
                            <li><p>Lista Nozze;</p></li>
                            <li><p>Programma;</p></li>
                            <li><p>Lista Invitati.</p></li>
                          </ul>
                          <p>Il servizio standard è gratuito.</p>
                        </li>
                        <li>
                          <h4>beSilver</h4>
                          <p>
                            Con il servizio beSilver tutti gli Utenti (Sposi e
                            Invitati) avranno a disposizione le aree del Sito:
                          </p>
                          <ul>
                            <li><p>Menù del Ricevimento;</p></li>
                            <li><p>Controllo del Budget;</p></li>
                            <li><p>Lista degli Impegni;</p></li>
                            <li><p>Chat.</p></li>
                          </ul>
                          <p>
                            Il servizio beSilver ha un costo di 4,90€. Chi avrà
                            il servizio beSilver, ha accesso anche ai servizi standard.
                          </p>
                        </li>
                        <li>
                          <h4>Diritto di recesso</h4>
                          <p>
                            L’Utente non ha alcun diritto al ripensamento e quindi,
                            all’annullamento del servizio beSilver dopo il suo
                            acquisto pertanto, il recesso e il rimborso non è consentito.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </li>
                  <li>
                    <h3>Condizioni particolari della chat</h3>
                    <p>
                      L'utilizzo della Chat implica la piena accettazione da parte
                      degli Utenti delle Condizioni particolari della Chat e delle
                      restanti Condizioni generali di utilizzo del Sito contenute
                      nelle presenti Condizioni Legali.
                    </p>
                    <ol type="a">
                      <li>
                        <h4>Condizioni particolari della Chat: presa d’atto e accettazione</h4>
                        <p>
                          Le presenti Condizioni particolari della chat regolano
                          la prestazione della chat sul Sito da parte di bemylove.it,
                          nonché l'utilizzo di detto servizio da parte degli Utenti.<br /><br />
                          bemylove.it mette a disposizione dei propri Utenti una
                          Chat affinché le coppie di futuri Sposi in procinto di
                          organizzare il loro matrimonio possano scambiare opinioni,
                          commenti o chiarire le informazioni riguardo il proprio
                          matrimonio, con gli Invitati.<br /><br />
                          L'utilizzo di questo servizio da parte degli Sposi è
                          condizionato alla previa registrazione della propria
                          email personale, nonché alla previa accettazione delle
                          Condizioni generali di utilizzo del Sito, nella versione
                          pubblicata da bemylove.it al momento in cui l’Utente
                          accede al servizio.<br /><br />
                          L'utilizzo di questo servizio da parte degli Invitati è
                          condizionato alla previa registrazione della propria
                          email personale nel momento in cui da conferma alla
                          partecipazione al matrimonio, nonché alla previa
                          accettazione delle Condizioni generali di utilizzo del
                          Sito, nella versione pubblicata da bemylove.it al
                          momento in cui l’Utente registra l’email.<br /><br />
                          L'utilizzo della chat è parimenti soggetto a tutti gli
                          avvisi e alle informative legali, norme e istruzioni di
                          utilizzo che bemylove.it è tenuta a portare a
                          conoscenza dell'Utente.<br /><br />
                          L'utilizzo della chat è limitato agli Utenti del Sito
                          che siano attivi e che lo utilizzino per la pianificazione
                          e la gestione di eventi privati, salvo e impregiudicato
                          quanto sopra, la finalità principale del servizio è
                          quella di assistere gli Sposi nell'organizzazione di
                          eventi nuziali, non dovendo in nessun caso fungere
                          da piattaforma o supporto promozionale per i
                          professionisti del settore. A tal fine, bemylove.it
                          si riserva il diritto di limitare o impedire l'accesso
                          alla Chat agli Utenti che ne facciano un uso indebito.<br /><br />
                          bemylove.it si riserva il diritto di modificare
                          in qualsiasi momento le presenti Condizioni particolari
                          della chat. Tali Condizioni particolari entrano in vigore
                          a partire dal momento della loro messa a disposizione
                          degli Utenti sul Sito. bemylove.it raccomanda quindi
                          agli Utenti di leggere le presenti Condizioni
                          Particolari ogni volta che utilizzano il Servizio di
                          Chat in caso di eventuali modifiche.<br /><br />
                          Qualora l'Utente non accetti le presenti Condizioni
                          particolari e/o, se del caso, le future condizioni e
                          comunicazioni eventualmente inviategli da bemylove.it,
                          è obbligato ad astenersi dall'accesso e/o dall’utilizzo
                          della chat.<br /><br />
                          Partecipando alla Chat, l’Utente accetta espressamente
                          che tutti i Contenuti pubblicati siano resi visibili
                          al resto dei membri di detta Chat.<br /><br />
                          Tutti i Contenuti forniti dall'Utente a bemylove.it,
                          con motivo della sua partecipazione al Servizio di
                          Chat, devono intendersi come incorporati al Sito e di
                          conseguenza oggetto di tutela della disciplina dei
                          diritti di proprietà intellettuali o affini. A tal fine,
                          l'Utente cede gratuitamente a bemylove.it l'uso di
                          tutti i Contenuti forniti con motivo della sua
                          partecipazione alla Chat, Contenuti che si intendono
                          integrati nel Sito ai sensi dell’anteriore clausola 2.d.
                        </p>
                      </li>
                      <li>
                        <h4>Oggetto e descrizione della chat</h4>
                        <p>
                          L’oggetto delle presenti Condizioni particolari è la
                          prestazione della chat da parte del Sito. Gli Utenti,
                          gratuitamente, previa registrazione sul Sito, possono
                          scambiare, visualizzare e rispondere a messaggi e, in
                          generale, conversare con altri Utenti della Chat per
                          esprimere e/o richiedere opinioni e scambiare impressioni
                          sulle informazioni, sulle attività e/o sui servizi
                          contenuti nel Sito o resi accessibili tramite lo stesso,
                          nonché su tematiche similari.
                        </p>
                      </li>
                      <li>
                        <h4>Accesso al Servizio di Chat</h4>
                        <p>
                          L'accesso al Servizio di Chat deve effettuarsi
                          inserendo la propria email. L’Utente è tenuto
                          ad assumere le conseguenze derivanti da eventuali
                          violazioni della riservatezza e dall’utilizzo
                          improprio del codice di accesso.<br /><br />
                          Il libero accesso al Servizio di Chat è consentito
                          solamente agli Utenti maggiori di 18 anni. Non è
                          consentito l’accesso ai minori di 18 anni.
                        </p>
                      </li>
                      <li>
                        <h4>Obblighi degli Utenti</h4>
                        <p>
                          L'Utente si impegna a utilizzare il Servizio di Chat
                          in conformità alle normative vigenti, alle presenti
                          Condizioni particolari della chat, alle Condizioni
                          generali di utilizzo del Sito, nonché alla morale, ai
                          buoni costumi generalmente accettati e all’ordine pubblico.<br /><br />
                          L'Utente si impegna quindi ad astenersi dall'utilizzare
                          il servizio per fini o scopi illeciti, contrari alle
                          presenti Condizioni particolari della chat e alle
                          Condizioni generali di utilizzo del Sito, lesivi di
                          diritti o di interessi di terzi o che comunque siano
                          suscettibili di danneggiare, inutilizzare, sovraccaricare
                          o deteriorare il servizio o di impedirne il normale
                          utilizzo o fruizione da parte di altri Utenti.
                          In particolare, a mero titolo esemplificativo e non
                          limitativo, è espressamente vietata la trasmissione,
                          la diffusione o la messa a disposizione di terzi,
                          mediante l’utilizzo del servizio, di informazioni,
                          messaggi, grafiche, file audio e/o video, fotografie,
                          registrazioni, software e, in generale, ogni classe di
                          materiali, dati o contenuti suscettibili di:
                        </p>
                        <ol type="i">
                          <li>
                            <p>
                              Molestare, perseguitare, minacciare o abusare in
                              qualsiasi modo di altri membri della Chat o di
                              terzi estranei alla stessa;
                            </p>
                          </li>
                          <li>
                            <p>
                              Includere contenuti osceni o censurabili o comunque
                              suscettibili di urtare la sensibilità di terzi che
                              possano eventualmente accedere agli stessi;
                            </p>
                          </li>
                          <li>
                            <p>
                              Diffondere contenuti tali da ledere e vulnerare i
                              diritti di altri Utenti o di terzi, come materiali
                              diffamatori o il cui contenuto costituisca una
                              violazione di eventuali diritti di proprietà
                              intellettuale o industriale;
                            </p>
                          </li>
                          <li>
                            <p>
                              Istigare terzi a violare le presenti Condizioni
                              particolari, le Condizioni generali d'uso del
                              Sito e le restanti condizioni contenute nelle
                              presenti Condizioni Legali;
                            </p>
                          </li>
                          <li>
                            <p>
                              Essere pubblicati con l'intenzione di farsi passare
                              per membri del personale bemylove.it o altri
                              Utenti utilizzando ID utente simili o con qualsiasi
                              altro metodo o dispositivo;
                            </p>
                          </li>
                          <li>
                            <p>
                              Essere utilizzati a fini commerciali, pubblicitari o
                              altrimenti illeciti, nonché per la trasmissione di
                              mail spazzatura, spam, catene di Sant’Antonio o invio
                              massivo di e-mail non richieste;
                            </p>
                          </li>
                          <li>
                            <p>
                              Violare la normativa sulla segretezza delle comunicazioni;
                            </p>
                          </li>
                          <li>
                            <p>
                              È espressamente vietata la vendita, la promozione e
                              la distribuzione di servizi o prodotti, anche gratuita,
                              attraverso la piattaforma della Chat di bemylove.it.
                              È parimenti vietata la promozione di attività
                              professionali direttamente riconducibili a un Utente,
                              svolte da detto Utente o da familiari.
                            </p>
                          </li>
                        </ol>
                        <p>
                          bemylove.it si riserva il diritto di ritirare dal
                          Sito i Contenuti considerati non appropriati per le
                          caratteristiche e le finalità del Sito stesso e per
                          gli Utenti. bemylove.it ricorda ai propri Utenti
                          che l’analisi dei succitati Contenuti deve condursi da
                          un punto di vista qualitativo e non legale, non essendo
                          bemylove.it legittimata né competente al perseguimento
                          di reati penali o alla denuncia di illeciti civili.<br /><br />
                          bemylove.it si riserva inoltre il diritto di interrompere
                          e/o sospendere la prestazione della chat a qualsiasi Utente
                          per inadempimento delle presenti Condizioni particolari,
                          nonché di eliminare e/o bloccare qualsiasi messaggio che
                          contenga caratteristiche simili a quelle enumerate nei
                          punti da i ad viii, anche qualora non espressamente
                          menzionate negli stessi, senza obbligo di preavviso o
                          successiva notifica, con facoltà di impedire ulteriormente
                          l'accesso all'Utente, a discrezione di bemylove.it,
                          qualora non adempia le finalità della Chat.
                        </p>
                      </li>
                      <li>
                        <h4>Contenuti pubblicati dagli utenti della Chat</h4>
                        <p>
                          I messaggi e i Contenuti apportati dagli Utenti nella
                          Chat contengono esclusivamente opinioni ed esperienze
                          personali e soggettive, di conseguenza ne sono unicamente
                          ed esclusivamente responsabili gli Utenti che li
                          pubblicano sul Sito.<br /><br />
                          Inserendo delle immagini nella Chat di bemylove.it,
                          gli Utenti concedono agli altri membri della Chat
                          l’autorizzazione per usare le immagini pubblicate.
                          L’uso di tali immagini è soggetto all’osservanza dei
                          seguenti criteri:
                        </p>
                        <ul>
                          <li><p>Non devono contenere immagini di persone fisiche identificate o identificabili;</p></li>
                          <li><p>Devono essere utilizzate esclusivamente nell’ambito della Chat di bemylove.it con fini unicamente ispiratori o per pareri.</p></li>
                        </ul>
                        <p>
                          Fermo restando ciò di cui sopra, bemylove.it si riserva
                          il diritto di eliminare tutte le immagini che non rispettino
                          i criteri d’uso della Chat.
                        </p>
                      </li>
                      <li>
                        <h4>Esenzione di responsabilità</h4>
                        <p>
                          bemylove.it non si assume alcuna responsabilità per
                          i messaggi trasmessi dagli Utenti, i quali a loro volta
                          esonerano espressamente bemylove.it da qualsiasi
                          responsabilità per danni e perdite di qualsiasi natura
                          eventualmente derivanti sia dal Contenuto dei messaggi
                          che dalla divulgazione a terzi delle condizioni, delle
                          caratteristiche e delle circostanze dell'utilizzo della
                          chat effettuato dagli Utenti, o comunque derivanti
                          dall'accesso e, se del caso, dall'intercettazione,
                          dall’eliminazione, dalla modifica o dalla manipolazione
                          da parte di terzi, autorizzati o meno, dei messaggi
                          diffusi dagli Utenti o messi a disposizione di terzi
                          attraverso il Servizio di Chat, pur impegnandosi a
                          mettere a disposizione i mezzi atti a garantire che la
                          partecipazione degli Utenti alla Chat non pregiudichi
                          né violi eventuali diritti di terzi.
                        </p>
                      </li>
                      <li>
                        <h4>Dati personali</h4>
                        <p>
                          bemylove.it informa i propri Utenti che il trattamento
                          dei dati personali viene condotto in conformità alla
                          Politica sulla protezione dei dati personali del Sito
                          disponibile per la consultazione accedendo al seguente
                          link:
                          <Link to="/policy-privacy">
                            https://bemylove.it/privacy
                          </Link><br /><br />
                          Gli Utenti sono tenuti a garantire la veridicità,
                          l’esattezza, la validità e l’autenticità dei Dati
                          Personali forniti, impegnandosi a mantenerli debitamente
                          aggiornati.
                        </p>
                      </li>
                      <li>
                        <h4>Divieto di cessione di licenza d’uso</h4>
                        <p>
                          bemylove.it autorizza l'Utente all'uso dei diritti
                          di proprietà intellettuale e industriale associati al
                          software per permettere l'esecuzione delle prestazioni
                          che conformano il Servizio di Chat, unicamente per il
                          loro utilizzo in conformità alle Condizioni Legali.<br /><br />
                          Salvo e impregiudicato quanto sopra, bemylove.it non
                          concede alcuna licenza d'uso né autorizzazione di alcun
                          tipo sui diritti di proprietà intellettuale e industriale
                          né su altre proprietà o diritti associati al Servizio di
                          Chat, al Sito stesso o ai Contenuti di cui all’Informativa
                          sui diritti di proprietà intellettuale e industriale del
                          Sito che l’Utente può consultare nelle presenti
                          Condizioni Legali.
                        </p>
                      </li>
                      <li>
                        <h4>Legislazione applicabile</h4>
                        <p>
                          Le presenti Condizioni particolari della chat sono
                          integralmente disciplinate dal diritto italiano.
                        </p>
                      </li>
                      <li>
                        <h4>Reclami</h4>
                        <p>
                          bemylove.it mette a disposizione degli Utenti del
                          Sito appositi moduli per reclami, richiedibili
                          all’indirizzo e-mail info[chiocciola]bemylove.it.
                          Una volta compilati i moduli, l’Utente deve inviarne
                          una copia all’indirizzo e-mail di cui sopra.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>Diritti di proprietà intellettuale e industriale</h3>
                    <p>
                      Copyright © 2019 Bemylove - Tutti i diritti riservati<br /><br />
                      Tutti i diritti di sfruttamento economico si intendono riservati.<br /><br />
                      Questo Sito è regolato dal diritto italiano ed è tutelato
                      dalla disciplina nazionale e internazionale dei diritti
                      sulla proprietà intellettuale e industriale.<br /><br />
                      I testi, disegni, immagini, database, loghi, struttura,
                      marchi e altri elementi del presente Sito sono tutelati
                      dalle leggi e dai trattati internazionali sulla proprietà
                      intellettuale e industriale.<br /><br />
                      È severamente vietata qualsiasi riproduzione, trasmissione,
                      adattamento, traduzione, modifica, divulgazione al pubblico
                      o altro utilizzo di tutto o parte dei Contenuti del presente
                      Sito, in qualsiasi forma o con qualsiasi mezzo, elettronico,
                      meccanico o di altro tipo, salvo previa autorizzazione
                      scritta di bemylove.it o di terzi eventualmente titolari
                      dei diritti in questione. Qualsiasi violazione dei suddetti
                      diritti può comportare l’avvio dei pertinenti procedimenti
                      stragiudiziali o giudiziali in sede civile o penale.<br /><br />
                      bemylove.it non concede alcuna licenza d’uso né
                      autorizzazione di alcun tipo sui diritti di proprietà
                      intellettuale e industriale né su altre proprietà o diritti
                      associati al Sito, né sui servizi o sui Contenuti dello stesso.<br /><br />
                      L'Utente dichiara espressamente che nessuno dei Contenuti
                      forniti a bemylove.it vulnera in alcun modo i diritti
                      di proprietà industriale o intellettuale o di altra natura
                      di titolarità di terzi, e di manlevare e tenere indenne
                      bemylove.it da eventuali reclami o pretese a tale titolo
                      da parte di terzi, assumendosi l’Utente la piena
                      responsabilità di detti Contenuti.<br /><br />
                      La legittimità della titolarità dei diritti di proprietà
                      intellettuale e industriale corrispondenti ai Contenuti
                      forniti dagli Utenti è di esclusiva responsabilità di questi
                      ultimi, dovendo espressamente impegnarsi a manlevare e tenere
                      indenne bemylove.it da ogni possibile reclamo e pretesa
                      da parte di terzi.<br /><br />
                      Ai fini di tutelare eventuali diritti di proprietà
                      intellettuale e industriale, nel caso in cui un Utente
                      o un terzo estraneo ritenga si sia prodotta una violazione
                      dei propri diritti derivante dalla pubblicazione di
                      determinati Contenuti sul Sito, l’interessato è tenuto
                      a notificare tale evento per iscritto all'indirizzo e-mail
                      info[chiocciola]bemylove.it, indicando:
                    </p>
                    <ul style={ { listStyleType: 'square' } }>
                      <li><p>I dati identificativi dell'interessato, titolare dei diritti presuntamente violati; gli estremi dei poteri di rappresentanza in base ai quali agisce in caso di reclami presentati per conto di terzi distinti dall’interessato;</p></li>
                      <li><p>I contenuti tutelati dai diritti di proprietà intellettuale e relativa ubicazione sul Sito;</p></li>
                      <li><p>La prova dell'esistenza, della titolarità e della vigenza dei diritti di proprietà intellettuale in questione;</p></li>
                      <li><p>Una dichiarazione di assunzione di piena responsabilità dell'interessato sulla veridicità dei dati e delle informazioni fornite nella notifica di cui al presente punto.</p></li>
                    </ul>
                  </li>
                  <li>
                    <h3>Cancellazione delle nozze</h3>
                    <p>
                      In caso di cancellazione delle nozze, gli Sposi sono tenuti
                      notificare tale circostanza a bemylove.it. L’account
                      verrà eliminato il maniera definitiva.
                    </p>
                  </li>
                  <li>
                    <h3>Durata</h3>
                    <p>
                      bemylove.it si impegna a prestare il Servizio Lista
                      Nozze fino a sei (6) mesi dopo la celebrazione del matrimonio
                      degli Sposi, data in cui procederà alla sua disattivazione.
                    </p>
                  </li>
                  <li>
                    <h3>Protezione dei dati personali</h3>
                    <p>
                      Per la corretta prestazione dei servizi, bemylove.it si
                      impegna a trattate i dati personali degli Utenti.<br /><br />
                      A tale scopo, gli Utenti, siano essi Sposi o Invitati, sono
                      tenuti a compilare un modulo e a fornire i dati personali
                      richiesti da bemylove.it e indicati come indispensabili
                      per la corretta prestazione dei servizi.
                    </p>
                  </li>
                  <li>
                    <h3>Politica di non discriminazione</h3>
                    <p>
                      L’intenzione di bemylove.it è che tutte le coppie di
                      futuri Sposi si sentano accettati e inclusi in tutti i
                      propri portali, applicazioni e strumenti (di seguito,
                      congiuntamente, le “Proprietà”).<br /><br />
                      Per questo motivo, bemylove.it proibisce ogni condotta
                      discriminatoria nei confronti di coppie o dipendenti di
                      bemylove.it sulla base della loro razza, colore, religione,
                      sesso, nazionalità, origine, incapacità, stato civile,
                      situazione familiare, stato di gravidanza, orientamento
                      sessuale, identità di genere, espressione di genere,
                      cittadinanza, età, o altra caratteristica protetta dalle
                      normative vigenti. A mero titolo informativo e non limitativo,
                      si considera condotta discriminatoria il rifiuto di prestare
                      o di accettare servizi o qualsiasi altra condotta indebita e
                      basata sulle caratteristiche di cui sopra.<br /><br />
                      Come stabilito nelle presenti Condizioni Legali, il divieto
                      in questione si estende anche alla pubblicazione di contenuti
                      discriminatori, come opinioni o pubblicazioni su forum o su
                      qualunque delle nostre Proprietà.<br /><br />
                      bemylove.it si riserva il diritto di adottare tutte le
                      misura ritenute necessarie, a sua discrezione, per rafforzare
                      l’osservanza di detta Politica, con facoltà incluso di
                      eliminare gli account sulle Proprietà degli utenti e/o i
                      professionisti che non vi adempiano.<br /><br />
                      Qualora si venga conoscenza di alcun tipo di condotta
                      discriminatoria da parte di un Utente, si prega di notificarlo
                      all’indirizzo e-mail info[chiocciola]bemylove.it,
                      menzionando nell’oggetto “Politica di non discriminazione”
                      onde permetterci di prendere gli opportuni provvedimenti.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CondizioniLegali;
