import React, { Component } from 'react';

class NoAccess extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="row" >
        <p className="text-center">Nulla da mostrare.</p>
      </div>
    );
  }
}

export default NoAccess;
