import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faMale, faFemale, faDownload } from '@fortawesome/free-solid-svg-icons';

library.add(
  faMapMarkedAlt,
  faMale,
  faFemale,
  faDownload
)

class Cta extends Component {
  constructor(props) {

    super(props);

    this.state = {
      showCta: false,
      hideSingleCta: false,
      lang: navigator.language || navigator.userLanguage
    };

  }

  componentDidMount() {

    if( localStorage.getItem('bml_session') ) {
      this.setState({
        hideSingleCta: true
      });
    }

    window.addEventListener("scroll", (event) => {

      if((window.innerHeight + window.scrollY) >= document.body.offsetHeight -300 && this.props.ctrl == 0) {
        this.setState({
          showCta: false
        });
      }
      else if( this.props.ctrl == 0 && window.scrollY > 400 ){
        this.setState({
          showCta: true
        });
      }
      else {
        this.setState({
          showCta: false
        });
      }

    });
  }

  render() {
    return (
      <div>
        <div style={this.state.showCta ? { display: 'block' } : { display: 'none' } }>
          <div className="row nav-cta">
            <div className="col-lg-4 col-sm-12" style={ this.state.hideSingleCta ? { display: 'none' } : { display: 'block' } }>
              <p className="text-center margin-bottom-0">Vuoi sposarti?</p>
              <Link to="/registrati">
                <div className="button center-single-btn margin-top-0" style={{backgroundColor : '#ef5aa7'}}>
                  Sì, lo voglio! <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" />
                </div>
              </Link>
            </div>
            <div className={ this.state.hideSingleCta ? 'col-md-6 btn-login' : 'col-lg-4 col-md-12 btn-login' } style={{ marginTop: 0 }}>
              <a href={ "https://play.google.com/store/apps/details?id=app.bemylove&gl=" + this.state.lang } target="_blank">
                <div className="button text-center button-cta" style={{ margin: '0 auto', marginTop: '20px' }}>Scarica l'APP per Android! <FontAwesomeIcon icon="download" /></div>
              </a>
            </div>
            <div className={ this.state.hideSingleCta ? 'col-md-6 btn-login' : 'col-lg-4 col-md-12 btn-login' } style={{ marginTop: 0 }}>
              <a href={ "https://apps.apple.com/" + this.state.lang + "/app/bemylove/id1552841878" } target="_blank">
                <div className="button text-center button-cta" style={{ margin: '0 auto', marginTop: '20px' }}>Scarica l'APP per iOS! <FontAwesomeIcon icon="download" /></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cta;
