import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleTen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Cani al Matrimonio: le nostre idee e suggerimenti</title>
          <meta name="description" content="Si sa, il cane è il migliore amico dell’uomo. Ma è anche il migliore amico della coppia, che segue gli sposi con entusiasmo, simpatia e assoluta fedeltà. È quindi considerato un vero e proprio componente della famiglia e ricopre un ruolo d’onore al matrimonio." />
          <meta property="og:title" content="Cani al Matrimonio: le nostre idee e suggerimenti per far sì che il vostro amico a 4 zampe sia partecipe al vostro giorno più bello!" />
          <meta property="og:url" content="https://bemylove.it/blog/cani-e-matrimonio-le-nostre-idee-e-suggerimenti" />
          <link rel="canonical" href="https://bemylove.it/blog/cani-e-matrimonio-le-nostre-idee-e-suggerimenti" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/ten/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Cani al Matrimonio: le nostre idee e suggerimenti</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  06/12/2020
                </time>
              </div>
              <div className="content">
                Si sa, il cane è il migliore amico dell’uomo. Ma è anche il
                migliore amico della coppia, che segue gli sposi con entusiasmo,
                simpatia e assoluta fedeltà. È quindi considerato un vero e
                proprio componente della famiglia e ricopre un ruolo d’onore al
                matrimonio.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://play.google.com/store/apps/details?id=app.bemylove&gl=IT" target="_blank">
                  Hai già contattato la dogsitter? Scarica l’App di Bemylove e
                  tieni sempre sotto controllo l’organizzazione del tuo giorno più bello!
                </a>
                <hr />
                Vediamo insieme alcuni consigli per condividere con lui questo
                giorno speciale. Prima di tutto, è essenziale chiedere al parroco
                e alla location se è possibile portare il vostro cane. Infatti
                sarebbe opportuno che ci sia la possibilità di uno spazio per
                il nostro amico a 4 zampe dove possa riposare durante la
                cerimonia ed un luogo in cui possa rilassarsi durante il banchetto.
                Quindi sarebbe opportuno considerare una location con ampi spazi
                aperti in cui possa correre ed essere a suo agio e magari
                addentare il suo giocattolo preferito.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/ten/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                Il vostro cane inoltre potrebbe farvi da paggetto facendogli
                portare le fedi all’altare.
                In questo caso potreste inoltre vestirlo di tutto punto per l’evento.
                Potrete sbizzarrirvi con cravattini/collarini con strass o con
                dei cestini dove sistemare le fedi o potreste pensare a smoking
                in miniatura realizzati appositamente per loro. Se avete una
                cagnolina esistono anche delle graziosissime gonnelline con le
                quali saranno bellissime. Ci sentiamo di darvi un solo un
                avvertimento: non esagerate troppo con gli addobbi per il vostro
                amico e magari a metà matrimonio toglietegli il vestitino e
                fatelo stare libero!
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/ten/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Non dimenticate inoltre che questo giorno potrebbe essere stressante
                anche per lui. L’aiuto di un dogsitter è essenziale poiché lui
                potrà prendersi cura di lui durante la cerimonia, durante il
                banchetto e anche durante il reportage fotografico in cui
                giocherà un ruolo fondamentale. Ricordate infatti di scattare
                qualche foto con il vostro amico a 4 zampe, il quale renderà
                questi momenti ancora più indimenticabili.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <div className="">
                  <img src="../media/blog/ten/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleTen;
