import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

library.add(
  faHeart
)

class ModalPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      show: props.show,
      price: 'Attendi...',
      idButton: ''
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {

    if( this.state.id != null ) {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      type: 1
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-pays/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            if( response.data.price.price_1 != '') {
              let price = response.data.price.price_1;
              this.setState({
                price: price != '4,90' ? '<strike>4,90€</strike> ' + price + '€' : price + '€'
              });

              let idButton = '';

              switch (price) {
                case '4,90':
                  this.setState({
                    idButton: 'RDGN5VEKVUW6W'
                  });
                break;
                  case '2,90':
                    this.setState({
                      idButton: '77QLLB5DF9C6U'
                    });
                    break;
                case '0,20':
                  this.setState({
                    idButton: 'L6WWAQ4BUK93U'
                  });
                  break;
                default:
                  this.setState({
                    idButton: 'RDGN5VEKVUW6W'
                  });
                  break;
              }
              return;
            }
            else {
              this.setState({
                show: false
              });
            }
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
    }

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.show !== this.state.show ) {
        this.setState({
          show: nextProps.show,
        });
        return true;
    }
    return false;
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSubmit( event ) {
    event.preventDefault();

  }

  render() {
    return (
      <div className="modal show-modal modal-pay" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
        <div className="modal-content">
          <span className="close-button" onClick={this.handleClose}>&times;</span>
            <br />
            <h3 className="text-center">beSilver</h3>
              <p>Sfrutta al massimo le potenzialità di <span>bemylove</span> passando alla versione <span>beSilver</span>!
              Infatti, con <span>beSilver</span>, sarà possibile poter gestire: </p><br /><br />
              <ul>
                <li><FontAwesomeIcon icon="heart" /> <Link to="/menu-di-nozze" target="_blank">Menù del Ricevimento</Link></li>
                <li><FontAwesomeIcon icon="heart" /> <Link to="/controllo-budget" target="_blank">Controllo del Budget</Link></li>
                <li><FontAwesomeIcon icon="heart" /> <Link to="/lista-impegni" target="_blank">Lista degli Impegni</Link></li>
                <li><FontAwesomeIcon icon="heart" /> <Link to="/chat" target="_blank">Chat</Link></li>
              </ul>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" target="_blank" >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value={ this.state.idButton } />
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <button type="submit" className="btn-submit" style={ { width: '100%', backgroundColor : '#ef5aa7' } }>
                      Passa a beSilver a soli <span dangerouslySetInnerHTML={{__html: this.state.price }} />
                  </button>
                </div>
                <div className="row">
                  <small style={ { paddingTop: '15px' } }>N.B.: Se hai effettuato il pagamento, la registrazione avverrà entro 24 ore.</small>
                </div>
              </div>
              <img alt="" border="0" src="https://www.paypalobjects.com/it_IT/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </div>
      </div>
    );
  }
}

export default ModalPay;
