import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleEight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Matrimoni nel 2021: ecco gli stili e le tendenze più seguite!</title>
          <meta name="description" content="Il tuo matrimonio si celebrerà nel 2021? Se la risposta e sì, vale la pena vedere quali saranno le tendenze e gli stili che la faranno da padrone in questo anno, in cui sicuramente il desiderio degli sposi sarà quello di tornare a sognare dopo un anno così difficile." />
          <meta property="og:title" content="Matrimoni nel 2021: ecco gli stili e le tendenze più seguite!" />
          <meta property="og:url" content="https://bemylove.it/blog/matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite" />
          <link rel="canonical" href="https://bemylove.it/blog/matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/eight/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Matrimoni nel 2021: ecco gli stili e le tendenze più seguite!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  01/12/2020
                </time>
              </div>
              <div className="content">
                Il tuo matrimonio si celebrerà nel 2021? Se la risposta e sì,
                vale la pena vedere quali saranno le tendenze e gli stili che
                la faranno da padrone in questo anno, in cui sicuramente il
                desiderio degli sposi sarà quello di tornare a sognare dopo un
                anno così difficile.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://play.google.com/store/apps/details?id=app.bemylove&gl=IT" target="_blank">
                  Hai già scelto lo stile del tuo matrimonio? Scarica l’App di
                  Bemylove e tieni sempre sotto controllo l’organizzazione del
                  tuo giorno più bello!
                </a>
                <hr />
                Così come nella moda, anche le tendenze di matrimonio si evolvono
                di continuo in quanto influenzate dai cambiamenti della nostra
                società e del tempo in cui viviamo. In particolare, dopo l’arrivo
                del coronavirus, il mondo del wedding è cambiato radicalmente,
                cambiando quindi il mondo di pensare le cerimonie e i festeggiamenti.
                Verranno privilegiate le location in cui è possibile mantenere
                il distanziamento e dove sarà possibile diluire i festeggiamenti
                anche in più giorni, creando così dei Wedding Week-end.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/eight/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Particolare attenzione ha nel 2021 il tema delle nozze etiche e
                eco-friendly in cui ogni dettaglio delle nozze è pensato in
                perfetto stile ecologico. Si pensa ad esempio a partecipazioni
                di nozze in carta riciclata, ad un banchetto biologico a km zero,
                o a delle bomboniere realizzate con materiali ecologici e
                viaggio di nozze da organizzare in maniera sostenibile.
                Insomma, ogni momento del matrimonio può essere a impazzo zero
                verso il nostro pianeta. Se state pensando a questo tema, il
                colore da utilizzare per il vostro matrimonio è sicuramente il
                verde!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Se invece fai del romanticismo la tua bandiera, i temi perfetti
                per il tuo matrimonio saranno lo Shabby chic, il Provenzale o
                il Boho chic. Infatti questi temi e tendenze, rappresentano quelli
                più gettonati degli ultimi anni e lo saranno anche nel 2021.
                Questi temi rispecchiano in pieno la tendenza verso l’attenzione
                alla natura e con essi è possibile includere fiori di campo
                appena raccolti, cestini e materiali naturali come tronchi,
                frutti di bosco e bacche, mentre per gli amanti della Provenza
                e dello stile Shabby Chic meglio usare lanterne anticate, libri,
                mazzolini di lavanda, decori in ferro battuto.
                <br /><br />
                <img src="../media/blog/eight/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Di certo, ogni matrimonio è unico e l’importante che voi lo
                personalizziate seguendo il vostro gusto ed il vostro stile.
                Molti altri stili e tendenze la faranno da padrone nel 2021,
                come ad esempio lo stile glamour, lo stile minimal e
                l’intramontabile matrimonio a tema mare, ma per saperne di
                più dovrete attendere il nostro prossimo articolo!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <img src="../media/blog/eight/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleEight;
