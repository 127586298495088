import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

class ArticleFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Le scarpe della sposa: alcuni semplici consigli da seguire per non sbagliare!</title>
          <meta name="description" content="Anche nella scelta delle scarpe bisogna seguire alcune regole per scegliere quelle adatte. E’ importante comprarle con largo anticipo per poterle provare con l'abito." />
          <meta property="og:title" content="Le scarpe della sposa: alcuni semplici consigli da seguire per non sbagliare" />
          <meta property="og:url" content="https://bemylove.it/blog/scegliere-le-scarpe-da-sposa-giuste" />
          <link rel="canonical" href="https://bemylove.it/blog/scegliere-le-scarpe-da-sposa-giuste" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/four/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Le scarpe della sposa: alcuni semplici consigli da seguire per non sbagliare!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  12/10/2020
                </time>
              </div>
              <div className="content">
                Anche nella scelta delle scarpe bisogna seguire alcune regole per
                scegliere quelle adatte. E’ importante comprarle con largo
                anticipo per poterle provare con l'abito.
              </div>
              {/* Massimo 160 caratteri. */}
              <hr />
              <div className="content">
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Avete già scelto le vostre scarpe da sposa? Con la lista degli
                  impegni di Bemylove controllate tutta l’organizzazione del
                  vostro giorno più bello!
                </a>
                <hr />
                E’ bene scegliere delle scarpe da sposa che si adattino alla tua
                personalità e che ti facciano sentire te stessa (e soprattutto comoda!)
                nel tuo giorno speciale! Dovrai tenere conto anche l’epoca dell’anno
                in cui ti sposerai. Se ti sposerai in autunno o in inverno, è
                preferibile scegliere delle scarpe in velluto. Se invece il tuo
                matrimonio si celebrerà in primavera o in estate, è meglio optare
                per dei sandali.
                <br /><br />
                Il colore della scarpe è sicuramente il primo punto di cui bisogna
                tenere conto. Essendo un accessorio di spicco, esse devono essere
                perfettamente abbinate all’abito della sposa e nella stessa precisa
                sfumatura di bianco. Potrai sbizzarrirti nella scelta del colore
                se sceglierai di indossare un abito colorato.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/four/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Successivamente dovrai tenere in considerazione la scelta dei
                materiali, che dovranno essere di qualità e ancor più dovrai
                tener d’occhio l’altezza del tacco. Ci deve essere proporzione
                tra l’altezza della sposa e del suo futuro marito, quindi se il
                futuro marito è di bassa statura sarà bene non scegliere dei
                tacchi vertiginosi. Inoltre, è bene prendere in considerazione
                la comodità della scarpa, una componente molto soggettiva che
                varia da donna a donna. Nella scelta delle scarpe da sposa di
                certo potrai sbizzarrirti. Che siamo sneakers, stivaletti,
                sandali o décolleté, l'importante è che si adattino allo stile
                del tuo matrimonio e alla tua personalità!
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/four/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleFour;
