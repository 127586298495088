import React, { Component } from 'react';
import axios from 'axios';
import TodoLists from './TodoLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Results from '../../../partials/results/Results';
import VideoTutorial from '../../../partials/video-tutorial/VideoTutorial';


class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allElms: [],
      updateInfo: false,
      resultsText: "Lista impegni aggiornata!"
    };

    this.updateElms = this.updateElms.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-todo/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allElms: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  updateElms( event ) {
    let input = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      elms: this.state.allElms
    };

    axios.post(process.env.REACT_APP_API_URL + '/update-todo/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              updateInfo: true
            });
            setTimeout( () => {
            this.setState({
              updateInfo: false
            });
            }, 2000);
          }
          else {
            // NO
            this.setState({
              updateInfo: true
            });
            setTimeout( () => {
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }, 2000);
          }

        });
    event.preventDefault();
  }

  render() {
    return (
      <div id="register" className="List" >
        <h2 className="text-right">
          Lista impegni 
          <VideoTutorial url="https://youtu.be/YzDQNoJSPh4" />
        </h2>
        <TodoLists allElms={ this.state.allElms } user_id={ this.state.id } />
        <div className="row text-center">
          <div className="col center-single-btn">
            <button type="submit" className="button" onClick={ this.updateElms }>Salva Cambiamenti <FontAwesomeIcon icon="chevron-right" /></button>
          </div>
          <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
        </div>
      </div>
    );
  }
}

export default Todo;
