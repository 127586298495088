import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleSixteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Proposta di matrimonio: Come lasciare il segno?</title>
          <meta name="description" content="Mi vuoi sposare?: tre semplici parole, simbolo dell’inizio di una nuova vita. La proposta di matrimonio è uno di quegli eventi che scioglierebbero il cuore anche alle personalità più fredde." />
          <meta property="og:title" content="Proposta di matrimonio: Come lasciare il segno?" />
          <meta property="og:url" content="https://bemylove.it/blog/proposta-di-matrimonio-come-lasciare-il-segno" />
          <link rel="canonical" href="https://bemylove.it/blog/proposta-di-matrimonio-come-lasciare-il-segno" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/sixteen/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Proposta di matrimonio: Come lasciare il segno?</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  28/02/2021
                </time>
              </div>
              <div className="content">
                Mi vuoi sposare?: tre semplici parole, simbolo dell’inizio di
                una nuova vita. La proposta di matrimonio è uno di quegli eventi
                che scioglierebbero il cuore anche alle personalità più fredde.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://play.google.com/store/apps/details?id=app.bemylove" target="_blank">
                  Hai già installato l’App di Bemylove? Non perdere tempo!
                  Scarica l’App di Bemylove e inizia a organizzare il tuo matrimonio!
                </a>
                <hr />
                Innanzitutto il bon ton vuole che il fidanzato chieda prima di
                tutto la mano della sua amata al padre. Questo gesto viene
                interpretato come prova di grande educazione e rispetto nei
                confronti dei familiari della futura sposa. Inoltre il galateo
                prevede che sia l’uomo a offrire in dono alla fidanzata l’anello
                come pegno del proprio amore, e ciò deve avvenire al momento
                della proposta di matrimonio: questo gioiello assumerà così il
                significato simbolico della promessa e dell’impegno preso.
                <br /><br />
                <div>
                  <img src="../media/blog/sixteen/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Ma come realizzarne una proposta di matrimonio che lascerà davvero il segno?
                <br /><br />
                Ecco a voi alcune proposte da cui potrete prendere ispirazione:
                <br /><br />
                <span style={{ fontSize: '24px', marginRight: '20px' }}>
                  <strong>1</strong>
                </span>
                Una proposta intima ma estremamente romantica: Potete far trovare
                il magico cofanetto nel vostro nido d’amore. Realizzate un cuore
                sul pavimento con delle piccole candele e dei petali di rosa.
                Ponete al centro la scatoletta con l’anello e aggiungete qualche
                punto luce in giro per casa. Questo gesto, in quelle mura,
                simboleggia impegno, progettualità e tanta concretezza
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <span style={{ fontSize: '24px', marginRight: '20px' }}>
                  <strong>2</strong>
                </span>
                Durante un viaggio speciale: Se amate viaggiare, quale migliore
                occasione di fare la proposta proprio durante uno dei vostri viaggi?
                A Parigi in cima alla Tour Eiffel, a New York sull’Empire State
                Building o ai piedi del Taj Mahal. Potete anche scegliere un
                giorno speciale ed organizzare una cena romantica, alla fine
                della quale, potete fare la fatidica domanda.
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/sixteen/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <span style={{ fontSize: '24px', marginRight: '20px' }}>
                  <strong>3</strong>
                </span>
                Proposta avventurosa o in Volo: Se desiderate stupire la vostra
                dolce metà, potete organizzare un giro in mongolfiera e durante
                il volo, inginocchiarvi e fare la vostra proposta. Se amate
                l’avventura potete anche organizzare una gita avventurosa in
                cima ad una montagna o durante un’escursione subacquea con
                l’aiuto di qualche amico.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                <span style={{ fontSize: '24px', marginRight: '20px' }}>
                  <strong>4</strong>
                </span>
                Proposta di matrimonio al cinema: Se volete stupire la vostra Lei,
                dovete optare per questa proposta davvero speciale.
                Potete realizzare un video dei momenti più belli della vostra
                storia d’amore: il giorno in cui vi siete conosciuti, i vostri
                viaggi, le avventure vissute insieme e chi più ne ha più ne
                metta. Dovrete poi mettervi d’accordo con il proprietario del
                cinema e chiedergli di inserire il vostro video prima dell’inizio
                del film. alla fine basterà portare la vostra amata a vedere un
                film e darle l’anello al momento giusto!
                <br /><br />
                <div>
                  <img src="../media/blog/sixteen/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                <span style={{ fontSize: '24px', marginRight: '20px' }}>
                  <strong>5</strong>
                </span>
                Tradizionale e romantica: Se amate essere tradizionali, infine,
                potete fare la più classica delle proposte. Riunire i vostri
                familiari e chiederle di passare tutta la vita insieme al culmine
                del pranzo. Una proposta classica ma che lascerà sicuramente il segno.
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleSixteen;
