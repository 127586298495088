import React, { Component } from 'react';
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faTimes, faCheck, faReceipt } from '@fortawesome/free-solid-svg-icons/index';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Results from '../../../partials/results/Results';

library.add({
  faTrash,
  faTimes,
  faCheck,
  faReceipt
});

var elm = {
  id: 0,
  what: '',
  price: 0,
  done: 0
};

const DragHandle = SortableHandle(({singleElm, index}) => {
  return (
    <div className="col-lg-1 col-12 drag" style={ window.innerWidth < 1000 ? { display: 'none' } : { display: 'block' }}>
      <FontAwesomeIcon icon="grip-lines" className="margin-icons" />
    </div>
    )}
  );


const SortableElm = SortableElement(({elmList, index, removeElm, signElm}) => {
    return (
      <div key={index}  className={ elmList.done == 1 ? 'row strike' : 'row' }>
        <DragHandle singleElm={ elmList } />
        <div className="col-lg-9 col-9">
          <div className="row" style={{ borderBottom: "0" }}>
            <div className="col-lg-6 col-12">
              <FontAwesomeIcon icon="clipboard" className="margin-icons"/> { elmList.what }
            </div>
            <div className="col-lg-4 col-12">
              <div className="d-flex justify-content-center"><FontAwesomeIcon icon="receipt" className="margin-icons" /> { parseFloat(elmList.price).toFixed(2).replace(".", ",") }€</div>
            </div>
          </div>
        </div>
        <div className={localStorage.getItem('bml_session') == null ? 'col-lg-1 col-xs-6 text-center' : 'col-lg-1 col-6 text-center tooltip' } style={localStorage.getItem('bml_session') === null ? { display: 'none' } : { display: 'block' } }>
          <FontAwesomeIcon className="margin-top-50" icon={ elmList.done == 0 ? 'times' : 'check'} onClick={()=> signElm(elmList.id, elmList.done)} />
          <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-40px' } }>{ elmList.done == 0 ? 'Clicca qui se è stata effettuata questa spesa' : 'Clicca qui se non è stata effettuata la spesa'}</span>
        </div>
        <div className="col-lg-1 col-6 text-center tooltip">
          <FontAwesomeIcon icon="trash" className="margin-top-50" onClick={()=> removeElm(index)} />
          <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-20px', left: '40px' } }>Elimina questo elemento</span>
        </div>
      </div>
    )}
  );

const SortableElmList = SortableContainer(({allElms, removeElm, signElm}) => {
  return (
    <div className="content-elms">
      {allElms.map((value, index) => {
        return (
        <SortableElm key={`item-${index}`} index={index} elmList={value} removeElm={removeElm} signElm={signElm} />
      )})}
    </div>
  );
});

class BudgetLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: this.props.user_id,
      budget: this.props.budget,
      allElms: this.props.allElms,
      toSpend: this.props.toSpend,
      spend: this.props.spend,
      resultsText: "Clicca su \"Salva cambiamenti\" per confermare!",
      updateInfo: false
    };

    this.whatElmChange = this.whatElmChange.bind(this);
    this.priceElmChange = this.priceElmChange.bind(this);
    this.setBudget = this.setBudget.bind(this);

    this.addElm = this.addElm.bind(this);
    this.removeElm = this.removeElm.bind(this);
    this.signElm = this.signElm.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.allElms !== this.state.allElms ) {
        this.refs.budget.value = nextProps.budget + '€';
        this.setState({
          allElms: nextProps.allElms,
          toSpend: nextProps.toSpend,
          spend: nextProps.spend,
          budget: nextProps.budget,
        });
        return true;
    }
    return false;
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ allElms }) => ({
      allElms: arrayMove(allElms, oldIndex, newIndex),
    }));

    this.props.allElms.length = 0;

    this.state.allElms.forEach( elmPush => {
      this.props.allElms.push(elmPush);
    });

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);
  };

  whatElmChange(event) {
    elm.what = event.target.value;
  }

  priceElmChange(event) {
    this.setState({badPrice: false });
    let value = event.target.value;
    if( !isNaN(value) ) {
      elm.price = value.replace(",", ".");
    }
    else {
      this.setState({badPrice: true });
    }

  }

  addElm() {
    if( elm.what !== '' && elm.price !== '' ) {

      let tmpElms = this.state.allElms;

      tmpElms.push(elm);

      this.setPropsState( tmpElms, 0 );

      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);

      elm = {
        id: 0,
        what: '',
        price: 0,
        done: 0
      };
      this.refs.elmWhat.value = '';
      this.refs.elmPrice.value = '';
    }
  }

  removeElm(index) {

    let elmRemove = this.state.allElms;

    elmRemove.splice( index , 1);

    this.setPropsState( elmRemove, 0 );

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);

  }

  signElm(id, done) {
    let value = {
      id: this.state.user_id,
      elmId: id,
      token: localStorage.getItem("bml_session"),
      done: done == 1 ? 0 : 1
    }

    axios.post(process.env.REACT_APP_API_URL + '/set-budget-done/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setPropsState(response.data, 1);
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
      });

  }

  setPropsState( elms, ctrl ) {
    if( ctrl == 1 ) {
      this.setState({
        allElms: elms.rls,
        toSpend: elms.toSpend,
        spend: elms.spend,
      });

    }
    else {
      let toSpend = 0;
      let spend = 0;
      elms.forEach( elm => {
        if( elm.done == 0 ) {
          toSpend += parseFloat(elm.price, 2);
        }
        else {
          spend += parseFloat(elm.price, 2);
        }
      });
      this.setState({
        allElms: elms,
        toSpend: toSpend,
        spend: spend,
      });

    }

    if( this.state.allElms.length !== this.props.allElms.length ) {
      this.props.allElms.length = 0;

      this.state.allElms.forEach( elmPush => {
        this.props.allElms.push(elmPush);
      });
    }
  }

  setBudget(event) {
    let input = {
      user_id: this.state.user_id,
      token: localStorage.getItem("bml_session"),
      budget: this.refs.budget.value
    };

    axios.post(process.env.REACT_APP_API_URL + '/set-budget/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              budget: this.refs.budget.value
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }

        });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        { /* BUDGET */}
        <div className="content-budget-father">
          <div className="row budget">
            <div className="col-sm-12 budget-content text-center">
              <form onSubmit={this.setBudget}>
                <div className="row-content col-lg-4 offset-lg-4 col-sm-12" style={{ marginBottom: '0', paddingBottom: '0' }}>
                  <div className="form-group">
                    <label htmlFor="budget">Budget</label>
                    <input ref="budget" type="text" className="text-center" id="budget" required />
                  </div>
                </div>
                <div className="col center-single-btn">
                  <button type="submit" className="button">Aggiorna <FontAwesomeIcon icon="chevron-right" /></button>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12 budget-content text-center budget-border-left">
              <h4>Budget rimanente</h4>
              <p>{ (parseFloat(this.state.budget) - parseFloat(this.state.spend)).toFixed(2).replace(".", ",") }</p>
            </div>
            <div className="col-lg-4 col-sm-12 budget-content text-center">
              <h4>Speso</h4>
              <p>{ this.state.spend.toFixed(2).replace(".", ",") }€</p>
            </div>
            <div className="col-lg-4 col-sm-12 budget-content text-center budget-border-right">
              <h4>Da spendere</h4>
              <p>{ this.state.toSpend.toFixed(2).replace(".", ",") }€</p>
            </div>
          </div>
        </div>
        <div className="row-content">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="whatGift">Cosa?</label>
                <input type="text" aria-describedby="Cosa?" ref="elmWhat" onChange={ this.whatElmChange } />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="priceGift">Prezzo</label>
                <input type="text" aria-describedby="0,00" maxLength="5" ref="elmPrice" onChange={ this.priceElmChange } />
                <small className="price-error" style={this.state.badPrice ? { display: 'block' } : { display: 'none' } }>Solo Numeri.</small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="center-single-btn button" onClick={this.addElm}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
          </div>
          <SortableElmList allElms={this.state.allElms} onSortEnd={this.onSortEnd} removeElm={this.removeElm} signElm={this.signElm} useDragHandle  />
        </div>
        <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
      </div>
  )};
}

export default BudgetLists;
