import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - I paggetti e le damigelle: i nostri consigli per il corteo nunziale perfetto!</title>
          <meta name="description" content="I paggetti e le damigelle possono rendere il vostro matrimonio davvero speciale. Infatti è possibile dare un tocco in più al vostro matrimonio se ci saranno i paggetti e le damigelle ad aprire il vostro corteo nunziale." />
          <meta property="og:title" content="I paggetti e le damigelle: i nostri consigli per il corteo nunziale perfetto!" />
          <meta property="og:url" content="https://bemylove.it/blog/matrimonio-pagetti-e-damigelle-consigli-per-il-corteo-nunziale-perfetto" />
          <link rel="canonical" href="https://bemylove.it/blog/matrimonio-pagetti-e-damigelle-consigli-per-il-corteo-nunziale-perfetto" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/six/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>I paggetti e le damigelle: i nostri consigli per il corteo nunziale perfetto!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  15/11/2020
                </time>
              </div>
              <div className="content">
                I paggetti e le damigelle possono rendere il vostro matrimonio
                davvero speciale. Infatti è possibile dare un tocco in più al
                vostro matrimonio se ci saranno i paggetti e le damigelle ad
                aprire il vostro corteo nunziale.
              </div>
              {/* Massimo 160 caratteri. */}
              <hr />
              <div className="content">
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Avete già scelto i fiori che adorneranno vostro bouquet? Con
                  la lista degli impegni di Bemylove controllate tutta
                  l’organizzazione del vostro giorno più bello!
                </a>
                <hr />
                Se amate i bambini e ne apprezzate l'allegria e la dolcezza,
                potete renderli protagonisti della vostra marcia nunziale.
                Spesso, per ricoprire questo ruolo vengono scelti i nipoti
                degli sposi, i cuginetti o i figli degli amici degli sposi.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                Il loro numero può variare, da 2 fino a un massimo di 8, secondo
                le regole del galateo, e devono essere sempre di numero pari.
                Essi devono avere un’età compresa tra i quattro e i dieci anni,
                poiché non devono essere troppo grandi, ma nemmeno troppo piccoli
                da non poter comprendere i compiti che gli state affidando. <br /><br />
                Ma quali sono i compiti principali dei paggetti e delle damigelle?
                <br /><br />
                <div className="article-image">
                  <img src="../media/blog/six/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Alle damigelle affidato il compito di aprire il corteo nunziale
                reggendo un piccolo bouquet in tinta con quello della sposa e un
                cestino colmo di petali di fiori che lasciano cadere al passaggio
                della sposa. In seguito potranno prendere posto vicino ai
                testimoni. Al fianco delle damigelle ci saranno i paggetti.
                Ad un paggetto potrà essere affidato il compito di reggere il
                cuscino con le fedi e portarvelo al momento giusto. <br /><br />
                Una volta conclusasi la festa, i paggetti e le damigelle saranno
                dei validi aiutanti per distribuire le bomboniere!
                <br /><br />
                <img src="../media/blog/six/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Per quanto riguarda il loro abbigliamento, secondo le regole del
                Bon Ton, è la sposa a doversi occupare dei loro vestiti. <br />
                Di solito vestono in bianco o in tinte pastello, certo è che
                naturalmente il loro abbigliamento deve essere in sintonia con
                lo stile della sposa e della cerimonia!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleSix;
