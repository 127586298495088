import React, { Component } from 'react';

import { Link } from "react-router-dom";
import Cta from '../partials/Cta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUser, faClock, faPaperPlane, faImage } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';
// import { ReactComponent as Logo } from '../../svg/BeMyLove.svg';

library.add({
  faClock,
  faUser,
  faPaperPlane,
  faImage
});

class Footer extends Component {
  constructor(props) {

    super(props);

    this.state = {
      hideSingleCta: false,
      lang: navigator.language || navigator.userLanguage
    };

  }

  componentDidMount() {
    if( localStorage.getItem('bml_session') ) {
      this.setState({
        hideSingleCta: true
      });
    }
  }

  render() {
    return (
      <div className="">

        <div id="section4">
        <div className="row row-action-footer">
          <div className={ this.state.hideSingleCta ? 'col-lg-4 col-md-12 btn-login' : 'col-lg-2 col-md-12 btn-login' }>
            <Link to="/login">
              <div className="button text-center" style={ this.state.hideSingleCta ? { backgroundColor : '#ef5aa7', marginTop : '20px' } : { marginTop : '20px' } }>Accedi <FontAwesomeIcon icon="sign-in-alt" /></div>
            </Link>
          </div>
          <div className="col-lg-2 col-sm-12" style={ this.state.hideSingleCta ? { display: 'none' } : { display: 'block' } }>
            <p className="text-center margin-bottom-0" style={{paddingLeft : '32px'}}>Vuoi sposarti?</p>
            <Link to="/registrati">
              <div className="button margin-top-0 text-center" style={{backgroundColor : '#ef5aa7', marginTop : '20px'}}>
                  Sì, lo voglio! <FontAwesomeIcon icon="male" /><FontAwesomeIcon icon="female" />
              </div>
            </Link>
          </div>
          <div className={ this.state.hideSingleCta ? 'col-lg-4 col-md-12 btn-login' : 'col-lg-4 col-md-12 btn-login' }>
            <a href={ "https://play.google.com/store/apps/details?id=app.bemylove&gl=" + this.state.lang } target="_blank">
              <div className="button text-center" style={{marginTop : '20px'}}>Scarica l'APP per Android! <FontAwesomeIcon icon="download" /></div>
            </a>
          </div>
          <div className={ this.state.hideSingleCta ? 'col-lg-4 col-md-12 btn-login' : 'col-lg-4 col-md-12 btn-login' }>
            <a href={ "https://apps.apple.com/" + this.state.lang + "/app/bemylove/id1552841878" } target="_blank">
              <div className="button text-center" style={{marginTop : '20px'}}>Scarica l'APP per iOS! <FontAwesomeIcon icon="download" /></div>
            </a>
          </div>
        </div>
          <div className="white text">
            <h2 className="footer">bemylove</h2>
            <div className="row">
              <div className="col-lg-2 offset-md-3 col-sm-12 text-center">
                <Link to="/condizioni-legali" target="_blank">
                  Condizioni Legali
                </Link>
              </div>
              <div className="col-lg-2 col-sm-12 text-center">
                <Link to="/policy-privacy" target="_blank">
                  Policy Privacy
                </Link>
              </div>
              <div className="col-lg-2 col-sm-12 text-center">
                <Link to="/cookies" target="_blank">
                  Cookies
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
