import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';


import MetaTags from 'react-meta-tags';


class RegisterFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( this.props.match.params.code ) {
      localStorage.setItem("bml_friend", this.props.match.params.code);
      this.props.history.push('/registrati');
    }
    else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
        <title>bemylove - Il tuo wedding planner digitale!</title>
        <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
        <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
        <meta property="og:url" content="https://bemylove.it/registrati" />
        <link rel="canonical" href="https://bemylove.it/registrati"/>
        </MetaTags>
        <div id="login">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row">
            <h2 className="text-center">Attendi...</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterFriend;
