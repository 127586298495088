import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faUserCheck, faUserTimes, faClipboard } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';
import Results from '../../partials/results/Results';
import VideoTutorial from '../../partials/video-tutorial/VideoTutorial';
import { Link } from "react-router-dom";

library.add({
  faTrash,
  faUserCheck,
  faUserTimes,
  faClipboard
});

var guest = {
  name: '',
  info: ''
};

class Guest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      ctrl: props.ctrl, // 1 = Sposi; 0 = Invitati
      allGuest: [],
      allGuestAdd: [],
      show: false,
      email: '',
      otp: '',
      updateInfo: false,
      resultsText: "Lista invitati aggiornata!",
      myprops: props.myprops
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.removePax = this.removePax.bind(this);
    this.addPax = this.addPax.bind(this);
    this.setName = this.setName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goTo = this.goTo.bind(this);

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-guest/', value )
        .then(response => {
          if(response.data.error.status == false) {
            // OK
            this.setState({
              allGuest: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  setName(event) {
    guest.name = event.target.value;
  }

  setInfo(event) {
    guest.info = event.target.value;
  }

  removePax( id ) {
    if( this.state.ctrl == 1 ) {

      let input = {
        id: id,
        user_id: this.state.id,
        token: localStorage.getItem("bml_session")
      };

      axios.post(process.env.REACT_APP_API_URL + '/remove-guest/', input)
          .then(response => {
            if(response.data.error.status == false) {
              // OK
              this.setState({
                updateInfo: true
              });
              this.componentDidMount();
              setTimeout( () => {
              this.setState({
                updateInfo: false
              });
              }, 2000);
            }
            else {
              // NO
              this.setState({
                updateInfo: true
              });
              setTimeout( () => {
                localStorage.removeItem("bml_session");
                window.top.location.href = '/';
              }, 2000);
            }

          });
    }
  }

  addPax() {
    if( guest.name !== '') {

      let tmpGuests = this.state.allGuestAdd;

      tmpGuests.push(guest);

      this.setPropsState( tmpGuests );

      guest = {
        name: '',
        info: ''
      };

      this.refs.name.value = '';
      this.refs.info.value = '';
    }
  }

  setPropsState( guests ) {
    this.setState({allGuestAdd: guests});
  }

  handleSubmit( event ) {
    let input = {
      guests: this.state.allGuestAdd,
      email: this.refs.email.value,
      user_id: this.state.id,
      ctrl: this.state.ctrl,
      token: localStorage.getItem("bml_session")
    };

    this.setState({email: this.refs.email.value});

    axios.post(process.env.REACT_APP_API_URL + '/added-guests/', input)
        .then(response => {
          if(response.data.error.status == false) {
            // OK
            if( response.data.rls.otp == 0 ) {
              this.setState({
                updateInfo: true,
                resultsText: response.data.rls.rls
              });
            }
            else {
              this.setState({
                guestsSuccessSubmit: true,
                otp: response.data.rls.otp
              });

              if( response.data.rls.otp != 1 ) {
                this.sendEmail();
              }
              this.componentDidMount();
            }

            setTimeout( () => {
              this.setState({
                updateInfo: false
              });
            }, 3000);
            //this.handleClose();
          }
          else {
            // NO
            // this.setState({
            //   guestsSuccessSubmit: true,
            //   registerErrorMessage: response.data.error.rls
            // });
          }

        });
    event.preventDefault();

  }

  sendEmail() {

    let input = {
      user_id: this.state.id,
      email: this.state.email,
      otp: this.state.otp,
      url: window.location.host
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-add-guests/', input)
      .then(response => {
        this.setState({
          updateInfo: true,
          resultsText: "Ti è stata inviata un email! Se non la vedi controlla nello SPAM!"
        });

        let inputAdmin = {
          email: this.state.email,
          ctrl: 'invitati'
        };

        axios.post(process.env.REACT_APP_API_URL + '/send-email-admin/', inputAdmin);

        setTimeout( () => {
          this.setState({
            updateInfo: false
          });
        }, 3000);
      });

  }

  getInfo( info ) {
    if( info != '' ) {
      return <FontAwesomeIcon icon="clipboard"/>;
    }
  }

  goTo( value ) {

    this.state.myprops.history.push(
      '/sposi/' + value,
      {
        id: this.state.id,
        level: this.state.myprops.location.state.level,
        page: value,
        type: this.state.myprops.location.state.type
      }
    );

  }

  render() {
    return (
      <div id="register" className={ this.state.ctrl == 0 ? 'Guest InvitedList' : 'Guest' }>
      <div className="container">
        <h2 className="text-right">
          Invitati
          <VideoTutorial url="https://youtu.be/zfg0UHiHBNE" style={ this.state.ctrl == 0 ? { display: 'none' } : { display: 'block' } } />
        </h2>
        <div className="col center-single-btn" style={ this.state.ctrl == 0 || this.state.allGuest.length == 0 ? { display: 'none' } : { display: 'block' } }>
          <div  onClick={(e) => this.goTo('gestione-auto')} className="button button-car" style={{ border: '1px solid white' }}>Gestione Auto</div>
        </div>
        { /* INVITATI */}
        <div className={ this.state.ctrl == 0 && this.state.allGuest.length > 0 ? 'content-elms' : 'row-content' }>
          <div className={ this.state.ctrl == 0 ? '' : 'row' }>
            <p className="text-center">{ this.state.allGuest.length == 0 ? 'Nessun invitato ha dato conferma!' : ''}</p>
            {this.state.allGuest.map( (guestList, key) => {
                return (
                  <div className="content-elms" key={key}>
                    <div className="row">
                      <div className="col-10">
                        <p><FontAwesomeIcon icon="user-check"/> { guestList.name }</p>
                        <p>{ this.getInfo( guestList.info ) } { guestList.info == '' ? '' : guestList.info }</p>
                      </div>
                      <div className="col-2" style={ this.state.ctrl == 0 ? { display: 'none' } : { display: 'block' } }>
                        <div className="text-center tooltip">
                            <FontAwesomeIcon icon="trash" style={{ color: 'white' }} onClick={()=> this.removePax( guestList.id )}/>
                            <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-50px', left: '28px' } }>Elimina questo elemento</span>
                        </div>
                      </div>
                    </div>
                   </div>
                );
            })}
          </div>
          <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
          <br /><br />
            <div>
              <div className="center-single-btn button" onClick={this.handleShow}>Aggiungi Invitati <FontAwesomeIcon icon="plus" /></div>
            </div>
          <div>
          </div>
        </div>
      </div>

      <div className="show-add-guest" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
          <div className="" style={ this.state.allGuestAdd.length > 0 ? { } : { } }>
              <span className="close-button" onClick={this.handleClose}>&times;</span>

              <div className="modal-message" style={ this.state.guestsSuccessSubmit ? { display: 'block' } : { display: 'none' } }>
                <h5 className="text-center" style={{ color: 'white' }}>Inserimento degli invitati effettuato con successo!</h5>
                <p className="text-center" style={ this.state.ctrl == 1 ? { display: 'none' } : { display: 'block', color: 'white' } }>Adesso clicca nel link che hai ricevuto per email per completare l'inserimento!</p>
                <p className="text-center" style={{ color: 'white' }}>Ricorda: Se l'email risulta già presente, gli invitati non potranno essere aggiunti. Utilizza un altro indirizzo email.</p>
              </div>

              <form onSubmit={this.handleSubmit} style={ this.state.guestsSuccessSubmit ? { display: 'none' } : { display: 'block' } }>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <div className="form-group">
                      <label htmlFor="name">Nome e Cognome invitato</label>
                      <input
                        onChange={ this.setName }
                        ref="name"
                        type="text"
                        autoComplete="off"
                        aria-describedby="Nome e Cognome invitato" />
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="form-group">
                      <label htmlFor="name">Info da annotare?</label>
                      <input
                        onChange={ this.setInfo }
                        ref="info"
                        type="text"
                        aria-describedby="Info da annodare?" />
                        <small><i>(Es. allergie o altro)</i></small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="center-single-btn button" style={{ border: '2px solid white', backgroundColor: '#d77eac'}} onClick={this.addPax}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
                </div>
                <hr />
                <h4 style={ this.state.allGuestAdd.length > 0 ? { display: 'block' } : { display: 'none' } } className="titleModalGuestList">
                  Invitati aggiunti
                </h4>
                 {this.state.allGuestAdd.map( (guestList, key) => {
                     return (
                       <div className="content-elms" key={key} style={{ color: 'white' }}>
                          <div className="col-12">
                            <FontAwesomeIcon icon="user-times"/>  { guestList.name } <i>{ guestList.info != '' ? ' - ' + guestList.info : '' }</i>
                          </div>
                        </div>
                     );
                 })}
                 <div style={ this.state.allGuestAdd.length > 0 ? { display: 'block' } : { display: 'none' } }>
                 <hr />
                 <div className="form-group">
                   <label htmlFor="email">Email</label>
                   <input
                     ref="email"
                     type="text"
                     id="email"
                     aria-describedby="Email" required="required" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                 </div>
                   <small className="text-center color-small-guest" style={ this.state.ctrl == 0 ? { display: 'block' } : { display: 'none' } }><i>
                     Cosa succede al click del tasto "Conferma"? Ti invieremo un email per conferma e
                     avviseremo gli sposi della { this.state.allGuestAdd.length == 1 ? 'tua' : 'vostra' }  presenza al loro matrimonio!
                   </i></small>
                   <small className="text-center color-small-guest" style={ this.state.ctrl == 1 ? { display: 'block' } : { display: 'none' } }><i>
                     Inserisci l'email personale di uno degli invitati inseriti per permettergli di utilizzare la Chat!
                   </i></small>
                 </div>

                 <div className="row" style={ this.state.allGuestAdd.length > 0 ? { display: 'block' } : { display: 'none' } }>
                   <div className="col">
                     <small className="text-center color-small-guest">
                       Con l'invio del seguente modulo, dichiaro di aver preso visione della
                       <Link to="/policy-privacy" className="legal-link-guest" target="_blank">
                         Policy Privacy
                       </Link> e delle
                       <Link to="/condizioni-legali" className="legal-link-guest" target="_blank">
                         Condizioni Legali
                       </Link>.
                       <br /><br />
                     </small>
                   </div>
                   <div className="col center-single-btn">
                     <button type="submit" className="button" style={{ border: '2px solid white', backgroundColor: '#d77eac'}}>Conferma!</button>
                   </div>
                 </div>
              </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Guest;
