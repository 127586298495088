import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../partials/Footer';
import Header from '../partials/Header';

import MetaTags from 'react-meta-tags';


class Redirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: this.props.match.params.redirect
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";

    if( this.state.redirect == 'bombonierediriso' ) {
      window.location.href = 'https://www.bombonierediriso.it/?from=bemylove';
    }
    else {
      window.location.href = 'https://bemylove.it/';
    }

  }

  handleSubmit( event ) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <Header ctrl="0" />
        <div id="login">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <br />
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row">
            <h2 className="text-center">Attendi...</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Redirect;
