import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { Link } from "react-router-dom";

import ModalConfirm from '../modal/ModalConfirm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faheart } from '@fortawesome/free-solid-svg-icons';

// var draggingStarted = 0;
// var lastMousePositionX = 0;
// var lastMousePositionY = 0;
// var divOffsetX = 0;
// var divOffsetY = 0;
// var containerWidth = 0;
// var containerHeight = 0;
// var imageWidth = 0;
// var imageHeight = 0;
// var widthOriginal = 0;
// var imageLoaded = 0;
// var imageTop = 0;
// var imageLeft = 0;
//
// var rotate_1 = 0;
// var scale_1 = 1;
// var rotate_2 = 0;
// var scale_2 = 1;

var image = [
  {
    draggingStarted: 0,
    lastMousePositionX: 0,
    lastMousePositionY: 0,
    divOffsetX: 0,
    divOffsetY: 0,
    containerWidth: 0,
    containerHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
    widthOriginal: 0,
    imageLoaded: 0,
    imageTop: 0,
    imageLeft: 0,
    rotate: 0,
    scale: 1,
    imageSelector: null
  },
  {
    draggingStarted: 0,
    lastMousePositionX: 0,
    lastMousePositionY: 0,
    divOffsetX: 0,
    divOffsetY: 0,
    containerWidth: 0,
    containerHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
    widthOriginal: 0,
    imageLoaded: 0,
    imageTop: 0,
    imageLeft: 0,
    rotate: 0,
    scale: 1,
    imageSelector: null
  }
];

class Step2 extends Component {
  constructor(props) {

    super(props);

    if( !props.location.state ) {
      window.location.href = '/minilove/';
      return;
    }

    this.state = {
      id: props.location.state.id,
      image: props.location.state.image,
      error: false,
      directory: "original",
      imageLeft: 0,
      imageTop: 0,
      hideSubmit: false,
      showWait: false,
      timestamp: new Date().getTime(),
      showModal: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.ImageLoaded = this.ImageLoaded.bind(this);
    this.setScale = this.setScale.bind(this);
    this.setRotate = this.setRotate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteMinilove = this.deleteMinilove.bind(this);

    this.loaded = this.loaded.bind(this);

    setTimeout( () => {
      image.forEach((img, i) => {
        img.imageSelector = document.querySelector('#drag-image-' + i);
        if (img.imageSelector.complete) {
          this.loaded(i);
        } else {
          img.imageSelector.addEventListener('load', this.loaded(i))
          img.imageSelector.addEventListener('error', function() {
              alert('error');
          });
        }
      });

      }, 1500);

  }

  loaded( ctrl ) {
    this.ImageLoaded( ctrl );
  }

  ImageLoaded( i ) {
    var img = image[i].imageSelector;
    image[i].imageWidth = img.width;
    image[i].widthOriginal = img.width;
    image[i].imageHeight = img.height;
    image[i].imageLoaded = 1;
  }

  getWidth(el, type) {
    if (type === 'inner')  // .innerWidth()
      return el.clientWidth;
    else if (type === 'outer')  // .outerWidth()
      return el.offsetWidth;
    let s = window.getComputedStyle(el, null);
    if (type === 'width')  // .width()
      return el.clientWidth - parseInt(s.getPropertyValue('padding-left')) - parseInt(s.getPropertyValue('padding-right'));
    else if (type === 'full')  // .outerWidth(includeMargins = true)
      return el.offsetWidth + parseInt(s.getPropertyValue('margin-left')) + parseInt(s.getPropertyValue('margin-right'));
    return null;
  }

  getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
    document.getElementsByClassName("row-action-footer")[0].style.display = "none";

    let valueBase64 = {
      id: this.state.id,
      image: this.state.image,
      path: 'minilove',
      pathTwo: 'original',
      token: localStorage.getItem("bml_session")
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-base64-minilove/', valueBase64 )
        .then(response => {
          var img1 = document.querySelector('#drag-image-0');
          img1.src = response.data.rls.sposo;
          var img2 = document.querySelector('#drag-image-1');
          img2.src = response.data.rls.sposa;

          image.forEach((img, i) => {
            img.containerWidth = this.getWidth( '#image-container-' + i, 'outer');
            img.containerHeight = this.getWidth( '#image-container-' + i, 'inner');
            img.divOffsetX = this.getOffset( document.getElementById('image-container-' + i) ).left;
            img.divOffsetY = this.getOffset( document.getElementById('image-container-' + i) ).top;
            setTimeout( () => {
              var imgSel = document.querySelector('#drag-image-' + i);
              imgSel.addEventListener('load', this.loaded(i));
              imgSel.addEventListener('error', function(event) {
                  alert('error', event.message);
              });
            }, 3000);
          });

        });

  }

  mouseDown(event, i) {

      if ( image[i].imageLoaded == 1 ) {
          image[i].draggingStarted = 1;

          /* Save mouse position */
          if (event.type == "touchstart") {
            // image[i].lastMousePositionX = event.originalEvent.touches[0].pageX - image[i].divOffsetX;
            // image[i].lastMousePositionY = event.originalEvent.touches[0].pageY - image[i].divOffsetY;

          }
          else if(event.type == "mousedown") {
            image[i].lastMousePositionX = event.pageX - image[i].divOffsetX;
            image[i].lastMousePositionY = event.pageY - image[i].divOffsetY;
          }
          else {
            image[i].lastMousePositionX = event.pageX - image[i].divOffsetX;
            image[i].lastMousePositionY = event.pageY - image[i].divOffsetY;
          }

      }
  }

  mouseUp(event, i) {
    image[i].draggingStarted = 0;
  }

  mouseMove(event, i) {
    if (image[i].draggingStarted == 1) {

        if (event.type == "touchmove") {
            var current_mouse_position = {
                x: event.originalEvent.touches[0].pageX - image[i].divOffsetX,
                y: event.originalEvent.touches[0].pageY - image[i].divOffsetY
            };
        }
        else if(event.type == "mousemove") {
            var current_mouse_position = {
                x: event.pageX - image[i].divOffsetX,
                y: event.pageY - image[i].divOffsetY
            };
        }
        else {
            var current_mouse_position = {
              x: event.pageX - image[i].divOffsetX,
              y: event.pageY - image[i].divOffsetY
            };
        }


        var change_x = current_mouse_position.x - image[i].lastMousePositionX;
        var change_y = current_mouse_position.y - image[i].lastMousePositionY;

        image[i].lastMousePositionX = current_mouse_position.x;
        image[i].lastMousePositionY = current_mouse_position.y;

        let top = image[i].imageSelector.style.top;
        let left = image[i].imageSelector.style.left;

        var img_top = parseInt(top.replace('px', ''), 10);
        var img_left = parseInt(left.replace('px', ''), 10);

        image[i].imageTop = img_top + change_y;
        image[i].imageLeft = img_left + change_x;

        this.setState({
          imageTop: img_top + change_y,
          imageLeft: img_left + change_x,
        });

        this.update( i );

    }
  }

  update( i ) {
    if(this.state.directory == 'original') {
      image[i].imageSelector.style.top = image[i].imageTop;
      image[i].imageSelector.style.left = image[i].imageLeft;
      image[i].imageSelector.style.transform = 'translateX(-50%) translateY(-50%) rotate(' + image[i].rotate + 'deg) scale(' + image[i].scale + ')';
    }
  }

  setScale( i ) {
    var scale = 0;
    switch ( i ) {
      case 0:
        scale = document.getElementById("scale_0").value;
        this.refs.scale_0.value = scale;
        break;
      case 1:
        scale = document.getElementById("scale_1").value;
        this.refs.scale_1.value = scale;
        break;
      default:
    }
    image[i].scale = scale;
    this.update( i );
  }

  setRotate( i ) {
    var rotate = 0;
    switch ( i ) {
      case 0:
        rotate = document.getElementById("rotate_0").value;
        this.refs.rotate_0.value = rotate;
        break;
      case 1:
        rotate = document.getElementById("rotate_1").value;
        this.refs.rotate_1.value = rotate;
        break;
      default:
    }
    image[i].rotate = rotate;
    this.update( i );
  }

  handleSubmit(event) {
    let input = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      rotate_0: -(parseInt(this.refs.rotate_0.value)),
      scale_0: parseFloat(this.refs.scale_0.value),
      top_0: parseInt(this.refs.top_0.value),
      left_0: parseInt(this.refs.left_0.value),
      width_0: parseInt(this.refs.width_0.value),
      height_0: parseInt(this.refs.height_0.value),
      widthOriginal_0: parseInt(this.refs.widthOriginal_0.value),
      name_0: this.refs.name_0.value,
      directory_0: this.refs.directory_0.value,
      offsetWidth_0: parseInt(this.refs.offsetWidth_0.value),
      offsetHeight_0: parseInt(this.refs.offsetHeight_0.value),
      rotate_1: -(parseInt(this.refs.rotate_1.value)),
      scale_1: parseFloat(this.refs.scale_1.value),
      top_1: parseInt(this.refs.top_1.value),
      left_1: parseInt(this.refs.left_1.value),
      width_1: parseInt(this.refs.width_1.value),
      height_1: parseInt(this.refs.height_1.value),
      widthOriginal_1: parseInt(this.refs.widthOriginal_1.value),
      name_1: this.refs.name_1.value,
      directory_1: this.refs.directory_1.value,
      offsetWidth_1: parseInt(this.refs.offsetWidth_1.value),
      offsetHeight_1: parseInt(this.refs.offsetHeight_1.value),
      code: this.state.image
    };

    this.setState({
      hideSubmit: true,
      showWait: true
    });

    axios.post(process.env.REACT_APP_API_URL + '/images/custom', input)
        .then(response => {

          if(response.data.error.status === false) {
            // OK
            this.props.history.push(
              '/minilove/step-3',
              {
                id: this.state.id,
                image: this.state.image
              }
            );
          }
          else {
            // NO
            this.setState({
              error: true,
            });
          }

        });
    event.preventDefault();
  }

  deleteMinilove( event ) {
    this.setState({
      showModal: true
    });
    event.preventDefault();
  }

  render() {
    const mystyle_0 = {
      top: image[0].imageTop,
      left: image[0].imageLeft,
      transform: 'translateX(-50%) translateY(-50%) rotate(' + image[0].rotate + 'deg) scale(' + image[0].scale + ')'
    };
    const mystyle_1 = {
      top: image[1].imageTop,
      left: image[1].imageLeft,
      transform: 'translateX(-50%) translateY(-50%) rotate(' + image[1].rotate + 'deg) scale(' + image[1].scale + ')'
    };
    return (
      <div>
        <div className="Site Minilove_2">
          <div className="row bg-white">
            <h3>Posiziona il viso al centro</h3>

            <div className="row">

              <div className="col-sm-12 text-center">
                <small style={{ color: 'black' }}>Trascina l'immagine per posizionarla nelle linee guida. Durante il trascinamento, ricorda di mantenere il mouse dentro il riquadro</small><br /><br />
              </div>

              <div className="col">

                <div className="row">
                  <div className="col-sm-6 text-center">
                    <small style={{ color: 'black' }}>Cambia le dimensioni</small>
                  </div>
                  <div className="col-sm-6 text-center">
                    <small style={{ color: 'black' }}>Ruota</small>
                  </div>
                  <div className="col-sm-6">
                    <div className="slidecontainer">
                    <div className="row">
                      <div className="col-sm-6 heart-scale-1">
                        <FontAwesomeIcon icon="heart" />
                      </div>
                      <div className="col-sm-6 heart-scale-2">
                        <FontAwesomeIcon icon="heart" />
                      </div>
                    </div>
                      <input
                        id="scale_0"
                        className="scale"
                        type="range"
                        min="0.1"
                        max="2"
                        step="0.1"
                        defaultValue={ image[0].scale }
                        onChange={ e => this.setScale(0) } />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="slidecontainer">
                    <div className="row">
                      <div className="col-sm-6 heart-rotate-1">
                        <FontAwesomeIcon icon="heart" />
                      </div>
                      <div className="col-sm-6 heart-rotate-2">
                        <FontAwesomeIcon icon="heart" />
                      </div>
                    </div>
                      <input
                        id="rotate_0"
                        className="rotate"
                        type="range"
                        min="-90"
                        max="90"
                        step="5"
                        defaultValue={ image[0].rotate }
                        onChange={ e => this.setRotate(0) } />
                    </div>
                  </div>
                </div>

                <div onMouseDown={(e) => { this.mouseDown( e, 0) } } onTouchStart={(e) => { this.mouseDown( e, 0) } }
                onMouseUp={(e) => { this.mouseUp(e, 0) } } onTouchEnd={(e) => { this.mouseUp(e, 0) } } onMouseMove={(e) => { this.mouseMove(e, 0) } } onTouchMove={(e) => { this.mouseMove(e, 0) } }
                className="box-foto" id="image-container-0" className={ this.state.directory == 'original' ? '' : 'freeze' }>
                    <div className="sagoma"></div>
                        <img onLoadedData={ this.ImageLoaded } id="drag-image-0" src="" style={mystyle_0} />

                    <input type="hidden" ref="rotate_0" className="rotate_1" value={ image[0].rotate } />
                    <input type="hidden" ref="scale_0" className="scale_1" value={ image[0].scale } />
                    <input type="hidden" ref="top_0" className="top" value={ image[0].imageTop } />
                    <input type="hidden" ref="left_0" className="left" value={ image[0].imageLeft } />
                    <input type="hidden" ref="width_0" className="width" value="300" />
                    <input type="hidden" ref="height_0" className="height" value="300" />
                    <input type="hidden" ref="widthOriginal_0" className="widthOriginal" value={ image[0].widthOriginal } />
                    <input type="hidden" ref="name_0" className="name" value={ 'sposo-' + this.state.image + '.jpg'} />
                    <input type="hidden" ref="directory_0" className="directory" value="original" />
                    <input type="hidden" ref="offsetWidth_0" className="offsetWidth" value={ image[0].divOffsetX } />
                    <input type="hidden" ref="offsetHeight_0" className="offsetHeight" value={ image[0].divOffsetY } />

                </div>

              </div>


              <div className="col">
              <div className="row">
                <div className="col-sm-6 text-center">
                  <small style={{ color: 'black' }}>Cambia le dimensioni</small>
                </div>
                <div className="col-sm-6 text-center">
                  <small style={{ color: 'black' }}>Ruota</small>
                </div>
                <div className="col-sm-6">
                  <div className="slidecontainer">
                  <div className="row">
                    <div className="col-sm-6 heart-scale-1">
                      <FontAwesomeIcon icon="heart" />
                    </div>
                    <div className="col-sm-6 heart-scale-2">
                      <FontAwesomeIcon icon="heart" />
                    </div>
                  </div>
                    <input
                      id="scale_1"
                      className="scale"
                      type="range"
                      min="0.1"
                      max="2"
                      step="0.1"
                      defaultValue={ image[1].scale }
                      onChange={ e => this.setScale(1) } />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="slidecontainer">
                  <div className="row">
                    <div className="col-sm-6 heart-rotate-1">
                      <FontAwesomeIcon icon="heart" />
                    </div>
                    <div className="col-sm-6 heart-rotate-2">
                      <FontAwesomeIcon icon="heart" />
                    </div>
                  </div>
                    <input
                      id="rotate_1"
                      className="rotate"
                      type="range"
                      min="-90"
                      max="90"
                      step="5"
                      defaultValue={ image[1].rotate }
                      onChange={ e => this.setRotate(1) } />
                  </div>
                </div>
              </div>
                <div onMouseDown={(e) => { this.mouseDown( e, 1) } } onTouchStart={(e) => { this.mouseDown( e, 1) } }
                onMouseUp={(e) => { this.mouseUp(e, 1) } } onTouchEnd={(e) => { this.mouseUp(e, 1) } } onMouseMove={(e) => { this.mouseMove(e, 1) } } onTouchMove={(e) => { this.mouseMove(e, 1) } }
                className="box-foto" id="image-container-1" className={ this.state.directory == 'original' ? '' : 'freeze' }>
                    <div className="sagoma"></div>
                        <img onLoadedData={ this.ImageLoaded } id="drag-image-1" src="" style={mystyle_1} />

                    <input type="hidden" ref="rotate_1" className="rotate_1" value={ image[1].rotate } />
                    <input type="hidden" ref="scale_1" className="scale_1" value={ image[1].scale } />
                    <input type="hidden" ref="top_1" className="top" value={ image[1].imageTop } />
                    <input type="hidden" ref="left_1" className="left" value={ image[1].imageLeft } />
                    <input type="hidden" ref="width_1" className="width" value="300" />
                    <input type="hidden" ref="height_1" className="height" value="300" />
                    <input type="hidden" ref="widthOriginal_1" className="widthOriginal" value={ image[1].widthOriginal } />
                    <input type="hidden" ref="name_1" className="name" value={ 'sposa-' + this.state.image + '.jpg'} />
                    <input type="hidden" ref="directory_1" className="directory" value="original" />
                    <input type="hidden" ref="offsetWidth_1" className="offsetWidth" value={ image[1].divOffsetX } />
                    <input type="hidden" ref="offsetHeight_1" className="offsetHeight" value={ image[1].divOffsetY } />

                </div>
              </div>
            </div>

            <button
              type="button"
              className="btn-submit btn-minilove-delete"
              style={ this.state.hideSubmit == true ? { display: 'none' } : { display: 'block' }}
              onClick={ e => this.deleteMinilove(e) } ><FontAwesomeIcon icon="trash" /> Elimina e ricomincia</button>

            <button
              type="button"
              className="btn-submit btn-minilove-save"
              onClick={ e => this.handleSubmit(e) }
              style={ this.state.hideSubmit == true ? { display: 'none' } : { display: 'block', backgroundColor: '#ef5aa7' }}>
                Salva <FontAwesomeIcon icon="chevron-right" />
                </button>
            <p className="message-cut" style={ this.state.showWait == false ? { display: 'none' } : { display: 'block' }}>Un team di esperti chiamati da tutto il mondo sta ritagliando le vostre foto...</p>
            <p className="message-cut" style={ this.state.error == false ? { display: 'none' } : { display: 'block' }}>Oh no c'è stata un po' di confusione... Aggiorna la pagina e riprova!</p>

          </div>
        </div>
        <ModalConfirm show={ this.state.showModal } id={ this.state.id } image={ this.state.image } />
      </div>
    );
  }
}

export default Step2;
