import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../partials/Footer';
import Header from '../partials/Header';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

library.add(
  faHeart
)


class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [
        {
          id: 'seventeen',
          url: 'matrimonio-2021-e-covid-quali-misure-adottare-per-festeggiare-in-sicurezza',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Matrimoni 2021 e Covid: Quali misure adottare per festeggiare in sicurezza?",
          abstract: "Dopo un lungo periodo di incertezza, finalmente arrivano le linee guida che regolano le cerimonie e i ricevimenti. Le troviamo in un documento che riguarda in generale le “Linee guida per la ripresa delle attività economiche e sociali”."
        },
        {
          id: 'sixteen',
          url: 'proposta-di-matrimonio-come-lasciare-il-segno',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Proposta di matrimonio: Come lasciare il segno?",
          abstract: "Mi vuoi sposare?: tre semplici parole, simbolo dell’inizio di una nuova vita. La proposta di matrimonio è uno di quegli eventi che scioglierebbero il cuore anche alle personalità più fredde."
        },
        {
          id: 'fiveteen',
          url: 'trucco-sposa-errori-da-evitare-assolutamente',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Trucco sposa: errori da evitare assolutamente!",
          abstract: "Come abbiamo visto, l’attenzione da dedicare al trucco della sposa è importante quanto la scelta del suo abito. Ma quali sono gli errori più comuni che dobbiamo assolutamente evitare? Vediamoli insieme!"
        },
        {
          id: 'zero',
          url: '',
          cover: '',
          title: "",
          abstract: ""
        },
        {
          id: 'fourteen',
          url: 'trucco-sposa-consigli-make-up-impeccabile-per-il-giorno-delle-nozze',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Consigli per un make-up impeccabile per il giorno delle nozze!",
          abstract: "Subito dopo aver scelto l’abito per il grande giorno, è di fondamentale importanza per le spose dedicarsi alla scelta del trucco. La chiave per la perfezione sta sicuramente nel trovare il giusto mix che valorizzi i vostri tratti, senza stravolgere il vostro look."
        },
        {
          id: 'thirteen',
          url: 'fotografo-per-il-matrimonio-come-scegliere-quello-giusto',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Cerchi il fotografo per il matrimonio? Ecco come scegliere quello giusto!",
          abstract: "Il fotografo è prima di tutto un artista. Egli deve saper cogliere tutti i momenti essenziali del vostro matrimonio e fonderli insieme con il suo stile, realizzando dei ricordi magici sotto forma di foto e video che vi emozioneranno ogni volta che li rivedrete."
        },
        {
          id: 'twelve',
          url: 'fedi-nunziali-tutti-i-consigli-per-scegliere-quelle-perfette-per-voi',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Fedi nunziali: tutti i consigli per scegliere quelle perfette per voi!",
          abstract: "L’anello simboleggia da sempre l’importanza di un amore senza fine. La scelta delle fedi nuziali è un momento importantissimo nell’organizzazione del matrimonio, in quanto si tratta di un oggetto con un’importanza simbolica elevata, che vi accompagnerà per tutta la vita."
        },
        {
          id: 'eleven',
          url: 'bomboniere-e-matrimoni-2021-idee-originali',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Bomboniere Matrimoni 2021: idee originali per il vostro giorno più bello!",
          abstract: "Cosa non può mancare ad un matrimonio? Gli sposi, gli inviati... ma di certo non possono mancare le bomboniere. Trovare la bomboniera perfetta per il proprio matrimonio non è sempre facilissimo, ne esistono un’infinità di tipologie, vediamone alcune insieme!"
        },
        {
          id: 'ten',
          url: 'cani-e-matrimonio-le-nostre-idee-e-suggerimenti',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Cani al Matrimonio: le nostre idee e suggerimenti",
          abstract: "Si sa, il cane è il migliore amico dell’uomo. Ma è anche il migliore amico della coppia, che segue gli sposi con entusiasmo, simpatia e assoluta fedeltà. È quindi considerato un vero e proprio componente della famiglia e ricopre un ruolo d’onore al matrimonio."
        },
        {
          id: 'nine',
          url: 'matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite-parte-2',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Matrimoni nel 2021: ecco gli stili e le tendenze più seguite! - Parte 2",
          abstract: "Nel precedente articolo vi abbiamo raccontato di alcuni tra gli stili e le tendenze bridal più seguiti per il 2021. Abbiamo già detto che sicuramente il motivo conduttore di questo anno, sarà il desiderio delle coppie di tornare a sognare dopo un anno così difficile."
        },
        {
          id: 'zero',
          url: '',
          cover: '',
          title: "",
          abstract: ""
        },
        {
          id: 'eight',
          url: 'matrimonio-nel-2021-ecco-gli-stili-e-le-tendenze-piu-seguite',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Matrimoni nel 2021: ecco gli stili e le tendenze più seguite!",
          abstract: "Il tuo matrimonio si celebrerà nel 2021? Se la risposta e sì, vale la pena vedere quali saranno le tendenze e gli stili che la faranno da padrone in questo anno, in cui sicuramente il desiderio degli sposi sarà quello di tornare a sognare dopo un anno così difficile."
        },
        {
          id: 'seven',
          url: 'matrimonio-low-cost-tutte-le-idee-consigli-suggerimenti-per-risparmiare',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Matrimonio Low Cost: idee, consigli e suggerimenti senza rinunce",
          abstract: "Si sa, il matrimonio è il giorno più bello della vita di spose e sposi, ma spesso, questo rischia di trasformarsi anche nel giorno più costoso. Seguendo alcuni consigli ed accorgimenti è possibile evitare tutto ciò, senza rinunciare davvero a nulla!"
        },
        {
          id: 'six',
          url: 'matrimonio-pagetti-e-damigelle-consigli-per-il-corteo-nunziale-perfetto',
          cover: 'bemylove-one-anteprima.jpg',
          title: "I paggetti e le damigelle: consigli per il corteo nunziale perfetto!",
          abstract: "I paggetti e le damigelle possono rendere il vostro matrimonio davvero speciale. Infatti è possibile dare un tocco in più al vostro matrimonio se ci saranno i paggetti e le damigelle ad aprire il vostro corteo nunziale."
        },
        {
          id: 'five',
          url: 'matrimoni-e-covid-19-tutto-quello-che-ce-da-sapere-sul-nuovo-dcpm-in-relazione-ai-matrimoni',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Matrimoni e Covid 19: tutto quello che c’è da sapere sul nuovo DPCM in relazione ai matrimoni",
          abstract: "Le coppie che hanno organizzato il loro matrimonio nel 2020, purtroppo hanno visto i propri programmi essere sconvolti dall’arrivo del nuovo coronavirus. Il coronavirus infatti, ha colpito senza dubbio sia le celebrazioni che l’organizzazione delle nozze."
        },
        {
          id: 'four',
          url: 'scegliere-le-scarpe-da-sposa-giuste',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Le scarpe della sposa: alcuni semplici consigli da seguire per non sbagliare!",
          abstract: "Anche nella scelta delle scarpe bisogna seguire alcune regole per scegliere quelle adatte. E’ importante comprarle con largo anticipo per poterle provare con l'abito."
        },
        {
          id: 'three',
          url: 'perche-la-sposa-lancia-il-bouquet',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Perché la sposa lancia il bouquet?",
          abstract: "Tutte le spose a fine ricevimento lanciano il bouquet per tradizione ma ecco perché nessuna vuole rinunciarci."
        },
        {
          id: 'zero',
          url: '',
          cover: '',
          title: "",
          abstract: ""
        },
        {
          id: 'two',
          url: 'come-scegliere-il-bouquet-per-il-matrimonio',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Come scegliere il bouquet perfetto per il vostro matrimonio",
          abstract: "Quel che è certo sul bouquet è che a fine ricevimento va lanciato ma non va scelto con leggerezza. Infatti ci sono dei criteri ben precisi che ogni sposa deve sapere."
        },
        {
          id: 'one',
          url: 'scegliere-auto-per-il-matrimonio',
          cover: 'bemylove-one-anteprima.jpg',
          title: "Come scegliere l'auto giusta per il Matrimonio",
          abstract: "L'auto degli sposi è certamente uno degli elementi fondamentali che renderanno il vostro matrimonio davvero unico. Ecco le regole di galateo da seguire nella scelta dell’auto per il vostro giorno speciale."
        }
    ]
    };

    this.renderBlog = this.renderBlog.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // axios.get(process.env.REACT_APP_API_URL + '/get-articles/')
    //     .then(response => {
    //       if(response.data.error.status === false) {
    //         // OK
    //         this.setState({
    //           articles: response.data.rls
    //         });
    //       }
    //       else {
    //         // NO
    //         window.top.location.href = '/';
    //       }
    //     });
  }

  renderBlog() {
    return <div className="row">
    { this.state.articles.map( (article, key) => {
          if( article.id == 'zero' ) {
            return (
              <div className="col-lg-6 col-sm-12 bg-white" key={key}>
                <div className="card">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='3579461867'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                  />
                </div>
              </div>
            );
          }
          let title = article.url;
          return (
            <div className="col-lg-6 col-sm-12 bg-white" key={key}>
              <Link to={'blog/' + title}>
                <div className="card" style={{ backgroundImage: 'url(../media/blog/' + article.id + '/' + article.cover + ')'}}>
                  <div className="title">
                    <h2>{ article.title }</h2><br /><br />
                    <div className="abstract" dangerouslySetInnerHTML={{__html: article.abstract }}></div>
                  </div>
                </div>
              </Link>
            </div>
        );
        }) }
    </div>
  }

  renderNull( article, key) {
    return (
      <div className="col-lg-6 col-sm-12 bg-white" key={key}></div>
    );
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Blog">
          { this.renderBlog() }
        </div>
        <Footer />
      </div>
    );
  }
}

export default Blog;
