import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleFiveteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Trucco sposa: errori da evitare assolutamente!</title>
          <meta name="description" content="Come abbiamo visto, l’attenzione da dedicare al trucco della sposa è importante quanto la scelta del suo abito. Ma quali sono gli errori più comuni che dobbiamo assolutamente evitare? Vediamoli insieme!" />
          <meta property="og:title" content="Trucco sposa: errori da evitare assolutamente!" />
          <meta property="og:url" content="https://bemylove.it/blog/trucco-sposa-errori-da-evitare-assolutamente" />
          <link rel="canonical" href="https://bemylove.it/blog/trucco-sposa-errori-da-evitare-assolutamente" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/fiveteen/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Trucco sposa: errori da evitare assolutamente!</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  31/01/2021
                </time>
              </div>
              <div className="content">
                Come abbiamo visto, l’attenzione da dedicare al trucco della
                sposa è importante quanto la scelta del suo abito. Ma quali sono
                gli errori più comuni che dobbiamo assolutamente evitare?
                Vediamoli insieme!
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Hai già scaricato la nostra App? Non dimenticare di aggiungere
                  alla lista delle cose da fare la prenotazione presso la tua
                  truccatrice di fiducia!
                </a>
                <hr />
                Vi sarà sicuramente capitato di passare ore a guardare foto di
                abiti da sposa e di acconciature che avrete mostrato al vostro
                parrucchiere o al vostro sarto di fiducia. Dovrete assolutamente
                fare lo stesso con il vostro make-up preferito. Cercate quello
                che vi ispira di più sui social e quello che rispecchia la vostra
                personalità ed il vostro stile, non sarà per niente del tempo perso!
                <br /><br />
                <div>
                  <img src="../media/blog/fiveteen/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Evitate di provare nuovi stili se non vi rispecchiano. È un errore
                classico che spesso le spose commettono. Ad esempio se non siete
                abituate ad indossare un trucco molto pesante, ad esempio osando
                con uno smokey eyes, sarebbe meglio non farlo il giorno del
                matrimonio. Questo potrebbe correre il rischio di farsi sentire
                a disagio e non “voi stesse”, proprio nel giorno in cui dovrete
                essere radiose e naturali!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Fate sempre la prova trucco con il vostro truccatore di fiducia.
                Testando la tenuta dei prodotti per tutta la giornata che dovrete
                affrontare e abbinando il vostro trucco con un abito bianco
                potrete vedere se il make-up che avete scelto è quello giusto
                per voi o dovrete optare per qualcos’altro. Ricordate, anche
                se decidete di truccarvi da sole, fate sempre molte prove!
                <br /><br />
                <img src="../media/blog/fiveteen/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Un altro degli errori più comuni è seguire i trend del momento
                senza un’adeguata preparazione. Non fatevi affascinare da un
                bellissimo contouring se non lo avete mai provato! La maggior
                parte dei matrimoni si svolgono alla luce del giorno, che esalta
                la bellezza naturale e un make-up fresco e leggero potrebbe
                essere la scelta giusta!
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Preparate accuratamente la pelle prima di qualsiasi trattamento.
                Utilizzate delle maschere idratanti e state alla larga da
                trattamenti aggressivi. Non tormentate i brufoli i giorni
                precedenti al matrimonio! È sicuramente più facile nasconderli
                se non ci sono croste. Non utilizzate una crema solare a base
                minerale sotto al make-up, poiché potrebbe generare degli aloni
                biancastri nelle foto.
                <br /><br />
                <div>
                  <img src="../media/blog/fiveteen/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Infine, anche se avrete un trucco waterproof e a lunga tenuta,
                non dimenticate di portare con voi il vostro beauty case di
                emergenza! Sarà una giornata davvero lunga e ricca di emozioni
                e tutto questo potrebbe mettere a rischio il trucco più performante.
                E ricordate, portate con voi i 3 prodotti essenziali: rosseto,
                salviettine assorbisebo e correttore!
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleFiveteen;
