import React, { Component } from 'react';
import axios from 'axios';

class AccessInvited extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      type: props.type,
      emailList: [],
      error: false,
      show: false,
      email: ''
    };

    this.searchEmail= this.searchEmail.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setUser = this.setUser.bind(this);

  }

  searchEmail(event) {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      type: this.state.type,
      email: this.refs.email.value
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-name-guests/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            if( response.data.rls.length > 0) {
              this.setState({
                emailList: response.data.rls,
                error: false,
                show: true,
                email: this.refs.email.value
              });
              return;
            }
            else {
              this.setState({
                error: true,
                show: false
              });
            }
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
        event.preventDefault();
  }

  handleClose() {
    this.setState({ show: false });
  }

  setUser( user ) {
    this.props.history.push(
      '/invitati/chat',
      {
        id: this.state.id,
        level: this.state.level,
        page: 'chat',
        type: 'invitati',
        user: user,
        email: this.state.email
      }
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-4 offset-lg-4 col-sm-12">
          <div className="form-group text-center">
            <label htmlFor="email">Inserisci l'email che hai usato per confermare l'invito</label>
            <input ref="email" type="text" id="email" required />
            <p id="error-login" style={this.state.error ? { display: 'block' } : {} }>Email non trovata.</p>
          </div>
        </div>
        <div className="row">
          <div className="col center-single-btn">
            <button type="button" className="btn-submit" onClick={ this.searchEmail }>Invia</button>
          </div>
        </div>



        <div className="modal show-modal" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
            <div className="modal-content">
                <span className="close-button" onClick={this.handleClose}>&times;</span>

                {this.state.emailList.map( (guestList, key) => {
                    return (
                      <div className="row" key={key}>
                        <div className="col center-single-btn">
                          <button type="button" className="btn-submit" onClick={ ()=> this.setUser(guestList.name) }>{ guestList.name }</button>
                          <br /><br />
                        </div>
                      </div>
                    );
                })}

            </div>
          </div>
      </div>
    );
  }
}

export default AccessInvited;
