import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class BudgetSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Controllo del Budget - bemylove - Fai tornare i conti!</title>
          <meta name="description" content="Tieni sotto controllo tutte le spese ed evita le sorprese dell'ultima ora. Registra tutte le entrate e uscite ed evita le indesiderate spese last minute." />
          <meta property="og:title" content="Controllo del Budget - bemylove - Fai tornare i conti!" />
          <meta property="og:url" content="https://bemylove.it/controllo-budget" />
          <link rel="canonical" href="https://bemylove.it/controllo-budget"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Controllo del Budget</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/PwIM6H2BXhg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Ebbene sì, per sposarsi è necessario avere un budget a disposizione!<br /><br />
                Prima di iniziare ad organizzare il “grande evento”, la cosa più
                importante da fare è stabilire il <strong>budget</strong> a disposizione per il
                matrimonio. Una volta deciso, è necessario tenere la situazione
                sotto controllo durante le fasi di organizzazione annotando tutte
                le voci di uscita e i vari importi.<br /><br />
                Tieni sotto controllo facilmente tutte le spese ed evita le sorprese
                dell'ultima ora. Registra tutte le entrate e uscite ed evita le
                indesiderate spese last minute. Qui di seguito l’opinione di un
                giovane sposo che ha trovato molto utile la funzione Controllo
                del budget per monitorare con un semplice click le spese del
                suo giorno speciale: <br /><br />
                <h3>L'esperienza di Giorgio (lo sposo):</h3>
                "Io e mia moglie stavamo cercando un'app che ci aiutasse
                nell'organizzazione del nostro matrimonio e in rete abbiamo
                scoperto <strong>bemylove</strong>. La funzione che più ho trovato utile è stata
                il <strong>Controllo del Budget.</strong> Io, essendo molto preciso con le spese
                quotidiane (e allo stesso tempo, cercando di tenere sotto
                controllo le spese pazze di mia moglie!), ho subito iniziato
                a provare questa funzione. L’ho trovata davvero molto intuitiva.<br /><br />
                Dapprima bisogna inserire il <strong>budget</strong> di cui si dispone. La somma
                potrà essere e modificata a piacimento di volta in volta.
                La Funzione ti permette poi di inserire una lista delle cose
                da acquistare e le informazioni sui pagamenti da effettuare
                specificandone il relativo importo. Ogni volta che avrete
                completato un acquisto o effettuato un pagamento presente
                sulla lista, basterà cliccare sulla crocetta accanto alla
                relativa voce. Così facendo, il costo della voce barrata sarà
                detratto automaticamente dal budget totale e la somma rimanente
                apparirà alla voce <strong>"Budget rimanente".</strong> Questa sezione calcola
                anche l’importo totale già speso man mano che le varie voci
                saranno sbarrate, così sarà più facile avere un quadro chiaro
                dell’importo che è già stato speso. Dopo aver effettuato tutte
                le modifiche, attraverso il tasto "salva cambiamenti" avrete
                la possibilità di aggiornare tutte le informazioni. Sicuramente
                senza <strong>bemylove</strong> non avrei mai potuto gestire le spese del mio
                matrimonio nel migliore dei modi."
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BudgetSite;
