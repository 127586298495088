import React, { Component } from 'react';
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faTimes, faCheck, faMapMarkerAlt, faTag, faGripLines } from '@fortawesome/free-solid-svg-icons/index';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Results from '../results/Results';

library.add({
  faTrash,
  faTimes,
  faCheck,
  faMapMarkerAlt,
  faTag,
  faGripLines
});

var gift = {
  id: 0,
  what: '',
  whither: '',
  price: '',
  done: 0
};

const DragHandle = SortableHandle((singleGift) =>
        <div className="col-lg-1 col-12 drag" style={ window.innerWidth < 1000 ? { display: 'none' } : { display: 'block' }}>
          <FontAwesomeIcon icon="grip-lines" className="margin-icons" />
        </div>
  );


const SortableGift = SortableElement(({giftList, index, removeGift, signGift, getTooltipMobile}) =>
    <div key={index}  className={ giftList.done == 1 ? 'row strike' : 'row' }>
      <DragHandle singleGift={ giftList } />
        <div className="col-lg-8 col-9">
          <div className="row" style={{ borderBottom: "0" }}>
            <div className="col-lg-6 col-12">
              <p><FontAwesomeIcon icon="gift" className="margin-icons" /> { giftList.what }</p>
              <p><FontAwesomeIcon icon="map-marker-alt" className="margin-icons" /> { giftList.whither }  </p>
            </div>
            <div className="col-lg-4 col-12">
              <div className="d-flex justify-content-center"><FontAwesomeIcon icon="tag" className="margin-icons" /> { parseFloat(giftList.price).toFixed(2).replace(".", ",") }€</div>
            </div>
          </div>
      </div>
      <div className={localStorage.getItem('bml_session') == null ? 'col-lg-1 col-4 text-center' : 'col-lg-1 col-4 text-center tooltip' } style={localStorage.getItem('bml_session') === null ? { display: 'none' } : { display: 'block' } }>
        <FontAwesomeIcon className="margin-top-50" icon={ giftList.done == 0 ? 'times' : 'check'} onClick={()=> signGift(giftList.id, giftList.done)} />
        { getTooltipMobile( 1, giftList.done ) }
      </div>
      <div className="col-lg-1 col-4 text-center tooltip">
        <a href={"https://www.google.it/maps/place/" + giftList.whither } target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon="map-marker-alt" className="margin-top-50" /></a>
        { getTooltipMobile( 2 ) }
      </div>
      <div className="col-lg-1 col-4 text-center tooltip">
        <FontAwesomeIcon icon="trash" className="margin-top-50" onClick={()=> removeGift(index)} />
        { getTooltipMobile( 3 ) }
      </div>
    </div>
  );

const SortableGiftList = SortableContainer(({allGifts, removeGift, signGift, getTooltipMobile}) => {
  return (
    <div className="content-elms">
      {allGifts.map((value, index) => (
        <SortableGift key={`item-${index}`} index={index} giftList={value} removeGift={removeGift} signGift={signGift} getTooltipMobile={getTooltipMobile} />
      ))}
    </div>
  );
});

class GiftLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: this.props.user_id,
      badPrice: false,
      allGifts: this.props.allGifts,
      isRegister: this.props.isRegister,
      resultsText: "Clicca su \"Salva cambiamenti\" per confermare!",
      updateInfo: false,
      urlWhere: '/'
    };

    this.whatGiftChange = this.whatGiftChange.bind(this);
    this.whitherGiftChange = this.whitherGiftChange.bind(this);
    this.priceGiftChange = this.priceGiftChange.bind(this);

    this.addGift = this.addGift.bind(this);
    this.removeGift = this.removeGift.bind(this);
    this.signGift = this.signGift.bind(this);
    this.getTooltipMobile = this.getTooltipMobile.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.allGifts !== this.state.allGifts ) {
        this.setState({
          allGifts: nextProps.allGifts,
        });
        return true;
    }
    return false;
  }

  getTooltipMobile( ctrl, done = '' ) {
    switch (ctrl) {
      case 1:
        if(window.innerWidth < 1000) {
          return  <span>
                    { done == 0 ?
                      'Clicca qui se hai ricevuto il regalo' :
                      'Clicca qui se non hai ricevuto il regalo'
                    }
                  </span>
        }
        else {
          return  <span className="tooltiptext" style={{ top: '-20px', left: '40px' }}>
                    { done == 0 ?
                      'Clicca qui se hai ricevuto il regalo' :
                      'Clicca qui se non hai ricevuto il regalo'
                    }
                  </span>

        }
        break;
      case 2:
        if(window.innerWidth < 1000) {
          return  <span>Guarda dove si trova!</span>
        }
        else {
          return  <span className="tooltiptext" style={{ top: '-20px', left: '40px' }}>
                    Guarda dove si trova!
                  </span>

        }
        break;
      case 3:
        if(window.innerWidth < 1000) {
          return  <span>Elimina questo elemento</span>
        }
        else {
          return  <span className="tooltiptext" style={{ top: '-20px', left: '40px' }}>
                    Elimina questo elemento
                  </span>

        }
        break;

    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ allGifts }) => ({
      allGifts: arrayMove(allGifts, oldIndex, newIndex),
    }));

    this.props.allGifts.length = 0;

    this.state.allGifts.forEach( giftPush => {
      this.props.allGifts.push(giftPush);
    });

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);
  };

  whatGiftChange(event) {
    gift.what = event.target.value;
    event.preventDefault();
  }

  whitherGiftChange(event) {
    gift.whither = event.target.value;
    this.setState({urlWhere: "https://www.google.it/maps/place/" + event.target.value });
    event.preventDefault();
  }

  priceGiftChange(event) {
    this.setState({badPrice: false });
    let value = event.target.value;
    if( !isNaN(value) ) {
      gift.price = value.replace(",", ".");
    }
    else {
      this.setState({badPrice: true });
    }
    event.preventDefault();

  }

  addGift() {
    if( gift.what !== '' && gift.whither !== '' && gift.price !== '') {

      let tmpGifts = this.state.allGifts;

      tmpGifts.push(gift);

      this.setPropsState( tmpGifts );

      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);

      gift = {
        id: 0,
        what: '',
        whither: '',
        price: '',
        done: 0
      };
      this.refs.GiftWhat.value = '';
      this.refs.GiftWhither.value = '';
      this.refs.GiftPrice.value = '';
    }
  }

  removeGift(index) {

    let elm = this.state.allGifts;

    elm.splice( index , 1);

    this.setPropsState( elm );

    if( this.state.isRegister == 0 ) {
      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);
    }

  }

  signGift(id, done) {
    let value = {
      id: this.state.user_id,
      giftId: id,
      token: localStorage.getItem("bml_session"),
      done: done == 1 ? 0 : 1
    }

    axios.post(process.env.REACT_APP_API_URL + '/set-gift-done/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setPropsState(response.data.rls);
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
      });

  }

  setPropsState( gifts ) {
    this.setState({allGifts: gifts});

    if( this.state.allGifts.length !== this.props.allGifts.length ) {
      this.props.allGifts.length = 0;

      this.state.allGifts.forEach( giftPush => {
        this.props.allGifts.push(giftPush);
      });
    }
  }

  render() {
    let results;
    if ( this.state.isRegister == 0 ) {
        results = <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
    }
    return (
      <div>
        { /* GIFT */}
        <div className="row-content">
        <div className="row" style={this.state.isRegister == 1 ? { display: 'block' } : { display: 'none' } }>
            <div className="title-section title-section-four">
              <p>Lista Nozze <FontAwesomeIcon icon="gifts" /></p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label htmlFor="priceGift">Prezzo</label>
                <input type="text" aria-describedby="0,00" maxLength="5" ref="GiftPrice" onChange={ this.priceGiftChange } />
                <small className="price-error" style={this.state.badPrice ? { display: 'block' } : { display: 'none' } }>Solo Numeri.</small>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label htmlFor="whatGift">Cosa?</label>
                <input type="text" aria-describedby="Cosa?" ref="GiftWhat" onChange={ this.whatGiftChange } />
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label htmlFor="whereGift">Dove?</label>
                <input type="text" aria-describedby="Dove?" ref="GiftWhither" onChange={ this.whitherGiftChange } />
                <small>Inserisci l'indirizzo completo di dove acquistarlo. Assicurati che su Maps di veda esattamente il luogo.</small>
                <div className="button text-center margin-top-5" style={ this.state.urlWhere == '/' ? { display: 'none' } : { display: 'block' } } >
                  <a href={ this.state.urlWhere } target="_blank" rel="noopener noreferrer" >Controlla <FontAwesomeIcon icon="external-link-alt" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="button center-single-btn btn-edit" onClick={this.addGift}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
          </div>
          <SortableGiftList allGifts={this.state.allGifts} onSortEnd={this.onSortEnd} removeGift={this.removeGift} signGift={this.signGift} getTooltipMobile={this.getTooltipMobile} useDragHandle />
        </div>
          { results }
      </div>
  )};
}

export default GiftLists;
