import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      allPrograms: [],
      updateSuccess: false,
      updateError: false
    };

  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-programs/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allPrograms: response.data.rls
            });
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  render() {
    return (
      <div id="register" className="Program InvitedList" >
      <h2 className="text-right">Programma</h2>
      <div>

        <div className="content-elms" style={ this.state.allPrograms.length == 0 ? { display: 'block' } : { display: 'none' }}>
          <div className="row-contents">
            <div className="row">
              <div className="col-12">
                <p className="text-center">Gli sposi non hanno ancora inserito il Programma di Nozze</p>
              </div>
            </div>
          </div>
        </div>

        <div className="content-elms">
        { this.state.allPrograms.map( (programList, key) => {
              return (
                <div className="row" key={key}>
                  <div className="col-lg-10 col-12">
                    <div className="row" style={{ borderBottom: "0" }}>
                      <div className="col-lg-4 col-12">
                        <div className="d-flex justify-content-center"><FontAwesomeIcon icon="clock" className="margin-icons" /> { programList.whatTime }</div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <p><FontAwesomeIcon icon="calendar-check" className="margin-icons" /> { programList.what }</p>
                        <p><FontAwesomeIcon icon="map-marker-alt" className="margin-icons" /> { programList.whither }</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-12 text-center tooltip">
                    <a href={"https://www.google.it/maps/place/" + programList.whither } target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon="map-marker-alt" className="margin-top-50" /></a>
                    <span className="tooltiptext" style={ window.innerWidth < 1000 ? { top: '-60px', left: '160px' } : { top: '-25px', left: '40px' } }>Guarda dove si trova!</span>
                  </div>
                </div>
            );
            }) }
          </div>
        </div>
      </div>
    );
  }
}

export default Program;
