import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class GuestsSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Lista invitati - bemylove - Chi verrà al tuo matrimonio?</title>
          <meta name="description" content="Conoscere chi ci sarà al tuo matrimonio non sarà più la parte più snervante. Infatti grazie alla Lista invitati potrai conoscere in tempo chi sarà presente!" />
          <meta property="og:title" content="Lista invitati - bemylove - Chi verrà al tuo matrimonio?" />
          <meta property="og:url" content="https://bemylove.it/lista-invitati" />
          <link rel="canonical" href="https://bemylove.it/lista-invitati"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Lista invitati</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/zfg0UHiHBNE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Conoscere chi ci sarà al tuo matrimonio non sarà più la parte più
                snervante dell'organizzazione del vostro mastrimonio. Infatti grazie
                alla <strong>Lista invitati</strong> di <strong>bemylove</strong> potrai
                conoscere in tempo reale chi sarà presente al giorno più importante della vostra
                vita, ovvero il vostro matrimonio.<br /><br />
                <h3>L’esperienza di Ginevra (la sposa):</h3>
                “Ho scoperto <strong>bemylove</strong> grazie a mia cugina che l’ha usato per
                organizzare le sue nozze e dopo aver visto tutte le funzioni
                di cui disponeva, non vedevo l’ora di provarlo per il mio matrimonio.<br /><br />
                A mio avviso la sezione che più è stata utile per l’organizzazione
                del nostro matrimonio è la sezione <strong>Lista invitati</strong>.<br /><br />
                Io e Luca ci siamo sposati a Settembre. Tutti e due abbiamo
                famiglie molto numerose e ci siamo subito resi conto che per
                l’organizzazione del nostro matrimonio, avere una lista degli
                invitati sempre a portata di mano sarebbe stato l’ideale.
                Dopo aver scoperto <strong>bemylove</strong>, ho chiesto a tutti i miei invitati
                di accedere alla piattaforma e di accedere alla sezione <strong>“invitati”</strong>.<br /><br />
                Da lì ognuno di loro ha potuto inserire cliccando su
                <strong>“Aggiungi invitati”</strong> il nome e cognome delle persone nel nucleo
                familiare e delle informazioni come allergie, intolleranze o note
                aggiuntive generali. Dopo aver aggiunto anche la loro mail, la
                piattaforma gli invierà automaticamente una mail e cliccando sul
                link potranno confermare la loro presenza al matrimonio.
                Sarò inviata anche una mail agli sposi che li informerà dei
                nuovi invitati confermati al loro matrimonio.<br /><br />
                Accedendo alla piattaforma, anche noi abbiamo potuto visualizzare
                tutti gli invitati che hanno confermato la loro presenza al nostro
                matrimonio. Grazie a <strong>bemylove</strong>, il mio matrimonio è stato un vero
                e proprio successo!”
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default GuestsSite;
