import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

class ArticleOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Come scegliere l'auto giusta per il Matrimonio</title>
          <meta name="description" content="L'auto degli sposi è certamente uno degli elementi fondamentali che renderanno il vostro matrimonio davvero unico. Ecco le regole di galateo da seguire nella scelta dell’auto per il vostro giorno speciale." />
          <meta property="og:title" content="Come scegliere l'auto giusta per il Matrimonio" />
          <meta property="og:url" content="https://bemylove.it/blog/scegliere-auto-per-il-matrimonio" />
          <link rel="canonical" href="https://bemylove.it/blog/scegliere-auto-per-il-matrimonio" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/one/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Come scegliere l'auto giusta per il Matrimonio</h1>
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  27/09/2020
                </time>
              </div>
              <div className="content">
                L'auto degli sposi è certamente uno degli elementi fondamentali
                che renderanno il vostro matrimonio davvero unico. Ecco le regole
                di galateo da seguire nella scelta dell’auto per il vostro giorno
                speciale.
              </div>
              <hr />
              <div className="content">
                <a href="https://bemylove.it/lista-impegni" target="_blank">
                  Avete già scelto l’auto per il vostro matrimonio? Con la lista
                  degli impegni di Bemylove controllate tutta l’organizzazione
                  del vostro giorno più bello!
                </a>
                <hr />
                L'auto degli sposi è certamente uno degli elementi fondamentali
                che renderanno il vostro matrimonio davvero unico. Come tutta
                l'organizzazione del matrimonio, infatti anche la scelta dell'auto
                deve seguire delle precise regole di stile e bon ton ed anche
                se al giorno d'oggi il rispetto del galateo del matrimonio non
                è considerato in maniera così rigida, è bene comunque sapere
                cosa prevede in merito alla scelta dell'automobile degli sposi.
                <br /><br />
                Secondo la tradizione, il costo dell'auto è interamente a carico
                della famiglia della sposa e non si deve esagerare con addobbi
                floreali esterni, ma è bene usare il classico ed elegante nastro
                bianco, che farà risaltare qualsiasi tipo di auto, dalla più
                classica alla più sportiva.

                <br /><br />
                <img src="../media/blog/one/uno.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />

                Un'auto a due posti è permessa solo dopo la cerimonia, mentre
                per il tragitto dalla casa della sposa al luogo della cerimonia,
                è bene utilizzare un'auto classica. Inoltre, seguendo le regole
                del il galateo, è di cattivo gusto scegliere un'auto molto
                grande se la casa della sposa o il luogo della cerimonia si
                trovano in una strada stretta o molto trafficata. Sicuramente,
                l'auto dovrà essere guidata da un autista, estraneo alla coppia,
                o da un fratello o da un amico degli sposi, mentre il posto
                accanto al guidatore dovrà rimanere vuoto. Ciò permetterà al
                fotografo di immortalare al meglio il momento dell’arrivo della
                sposa al luogo della cerimonia.

                <br /><br />
                <img src="../media/blog/one/due.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />

                In ogni caso per la scelta dell’auto degli sposi, è bene ricordare
                che il tragitto che la sposa percorre da casa al luogo in
                cui verrà celebrato il matrimonio rappresenta il percorso
                che la conduce ad un appuntamento che le cambierà la vita.
                Quindi sarà bene scegliere un'auto davvero speciale, che si
                adegui allo stile delle nozze e che rappresenterà un dettaglio
                difficile da dimenticare.
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleOne;
