import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUserCheck} from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';

import MetaTags from 'react-meta-tags';

library.add({
  faUserCheck
});

class ConfirmInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: this.props.match.params.otp,
      allGuest: []
    };
  }

  componentDidMount() {
    let value = {
      otp: this.state.otp
    }
    axios.post(process.env.REACT_APP_API_URL + '/set-guest/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              allGuest: response.data.rls
            });
            this.sendEmail();
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  sendEmail() {

    let input = {
      guests: this.state.allGuest,
      url: window.location.host
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-married/', input)
    .then(response => {

      setTimeout( () => {
        this.props.history.push(
          '/',
          {
            guest: true
          }
        );
      }, 10000);

    });

  }

  render() {
    return (
      <div id="register" className="ConfirmInvite">
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <div className="row">
          <div className="content-elms">
            <h2 className="text-center col-lg-12">Grazie!</h2>
            <p className="text-center">I seguenti invitati sono stati confermati e gli sposi sono stati avvisati!</p>
            {this.state.allGuest.map( (guestList, key) => {
                return (
                  <div>
                    <div className="col-12 col-lg-4" style={ { margin: '0 auto' } } key={key}>
                      <p><FontAwesomeIcon icon="user-check"/> { guestList.name } </p>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmInvite;
