import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleSeventeen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Matrimoni 2021 e Covid: Quali misure adottare per festeggiare in sicurezza?</title>
          <meta name="description" content="Dopo un lungo periodo di incertezza, finalmente arrivano le linee guida che regolano le cerimonie e i ricevimenti. Le troviamo in un documento che riguarda in generale le “Linee guida per la ripresa delle attività economiche e sociali”." />
          <meta property="og:title" content="Matrimoni 2021 e Covid: Quali misure adottare per una ripartenza in sicurezza?" />
          <meta property="og:url" content="https://bemylove.it/blog/matrimonio-2021-e-covid-quali-misure-adottare-per-festeggiare-in-sicurezza" />
          <link rel="canonical" href="https://bemylove.it/blog/matrimonio-2021-e-covid-quali-misure-adottare-per-festeggiare-in-sicurezza" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/seventeen/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Matrimoni 2021 e Covid: Quali misure adottare per festeggiare in sicurezza?</h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  02/05/2021
                </time>
              </div>
              <div className="content">
                Dopo un lungo periodo di incertezza, finalmente arrivano le linee
                guida che regolano le cerimonie e i ricevimenti. Le troviamo in un
                documento che riguarda in generale le “Linee guida per la ripresa
                delle attività economiche e sociali”.
                {/* Massimo 160 caratteri. */}
                <hr />
                <span style={{ color: 'rgba(167, 57, 114, 1)', textAlign: 'center !important' }}>
                  Stai organizzando il mio matrimonio? Scarica l’App di Bemylove e
                  inizia a organizzare il tuo matrimonio con un click!<br /><br />
                </span>
                <div className="download-app">
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=app.bemylove" target="_blank">
                      ANDROID
                    </a>
                  </div>
                  <div>
                    <a href="https://apps.apple.com/it/app/bemylove/id1552841878" target="_blank">
                      iOS
                    </a>
                  </div>
                </div>
                <hr />
                Abbiamo già trattato in precedenza il tema dei matrimoni 2021 in
                relazione al covid e alle misure prese dal governo italiano nel
                nostro articolo che trovate
                <a style={{ marginLeft: '5px', marginRight: '5px' }}
                  href="https://bemylove.it/blog/matrimoni-e-covid-19-tutto-quello-che-ce-da-sapere-sul-nuovo-dcpm-in-relazione-ai-matrimoni" target="_blank">qui.</a>
                Ma cosa prevede il nuovo DPCM in materia wedding e proprio mentre la
                stagione dei matrimoni sta per cominciare?
                <br /><br />
                <div>
                  <img src="../media/blog/seventeen/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Innanzitutto bisogna dire che tutte le norme da osservare per cerimonie
                e ricevimenti dopo matrimoni e congressi, sono state raccolte in
                un protocollo stilato durante la Conferenza delle regioni per la
                ripartenza. Questo documento riguarda appunto tutte le linee
                guida da adottare per evitare il contagio e nel contempo far
                ripartire le attività economiche e sociali, in cui appunto
                rientrano i matrimoni. Ma quali sono queste misure nello specifico?
                <br /><br />
                Prima di tutto bisognerà “predisporre una adeguata informazione
                sulle misure di prevenzione da rispettare durante l’evento”.
                Dovrete bene aver cura di informare preventivamente tutti gli
                invitati e chiunque partecipi all’organizzazione e allo svolgimento
                dell’evento. Inoltre, sarà d’obbligo mantenere l’elenco dei
                partecipanti per un periodo di almeno 14 giorni.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Sarà importante “riorganizzare gli spazi, per garantire l’accesso
                alla sede dell’evento in modo ordinato, al fine di evitare
                assembramenti di persone e di assicurare il mantenimento di
                almeno 1 metro di separazione tra gli utenti” ed inoltre
                “organizzare percorsi separati per l’entrata e per l’uscita”.
                Organizzate quindi in anticipo gli spazi interni ed i percorsi
                da seguire con i proprietari delle location. In più, i tavoli
                dovranno essere disposti “in modo da assicurare il mantenimento
                di almeno 1 metro di separazione tra i clienti di tavoli diversi
                negli ambienti al chiuso (estendibile ad almeno 2 metri in base
                allo scenario epidemiologico di rischio) e di almeno 1 metro
                di separazione negli ambienti all’aperto (giardini, terrazze,
                plateatici, dehors)”
                <br /><br />
                <div className="">
                  <img src="../media/blog/seventeen/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Per quanto riguarda il distanziamento interpersonale, è prevista
                un’eccezione per le persone che “in base alle disposizioni vigenti
                non siano soggetti al distanziamento interpersonale”, come è il
                caso dei conviventi. Inoltre è bene ricordare che le distanze
                possono essere eventualmente ridotte “solo con barriere fisiche
                di separazione”. Bisognerà sicuramente “privilegiare l’utilizzo
                degli spazi esterni (es. giardini, terrazze), sempre nel rispetto
                del distanziamento di almeno 1 metro”.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                E’ obbligatorio l’uso della mascherina? Di certo tutti gli ospiti
                “dovranno indossare la mascherina negli ambienti interni (quando
                non sono seduti al tavolo) e negli ambienti esterni (qualora
                non sia possibile rispettare la distanza di almeno 1 metro)”,
                mentre il personale di servizio deve “utilizzare la mascherina
                e deve procedere ad una frequente igiene delle mani con
                prodotti igienizzanti”.
                <br /><br />
                <div>
                  <img src="../media/blog/seventeen/three.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                E’ possibile usare la formula del buffet? Il buffet sarà consentito
                solo “mediante somministrazione da parte di personale incaricato”
                e gli ospiti non potranno toccare il cibo esposto. La modalità
                self-service “può essere eventualmente consentita per buffet
                realizzati esclusivamente con prodotti confezionati in monodose”.
                In ogni caso la distribuzione del cibo dovrà essere regolata “con
                modalità organizzative che evitino la formazione di assembramenti”
                anche “attraverso una riorganizzazione degli spazi in relazione
                alla dimensione dei locali”. C’è inoltre l’obbligo di tenere
                aperte “porte, finestre e vetrate al fine di favorire il ricambio
                d’aria naturale negli ambienti interni”, sempre che le condizioni
                meteorologiche lo consentano. Infine, conclude il protocollo,
                tutti gli indumenti e gli oggetti personali depositati nei
                guardaroba “devono essere riposti in appositi sacchetti porta abiti”.
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleSeventeen;
