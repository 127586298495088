import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUser, faClock, faPaperPlane, faImage } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';
// import { ReactComponent as Logo } from '../../svg/BeMyLove.svg';

library.add({
  faClock,
  faUser,
  faPaperPlane,
  faImage
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      user: props.user,
      type: props.type,
      email: props.email,
      level: props.level,
      messages: [],
      text: '',
      noScroll: false,
      isMobile: window.innerWidth < 1000 ? true : false,
      hideImage: true,
      image: '',
      nameImage: '',
      imageModal: '',
      widthBoxImage: window.innerWidth < 1000 ? '100%' : '80vh',
      opacityImage: 0,
      showLoading: window.innerWidth < 1000 ? false : true,
      totalGuests: 0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setValueChat = this.setValueChat.bind(this);
    this.getIcons = this.getIcons.bind(this);
    this.getImage = this.getImage.bind(this);
    this.hideImage = this.hideImage.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session"),
      type: this.state.type
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-chat/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            if( response.data.rls.length != this.state.messages.length ) {
              this.setState({
                messages: response.data.rls,
                noScroll: false
              });
            }

            this.scrollChat(1);
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });

        this.intervalId  = setTimeout(function() {
                                          this.componentDidMount();
                                      }
                                      .bind(this), 500);

        let valueGuests = {
          id: this.state.id,
          token: localStorage.getItem("bml_session")
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-total-guests/', valueGuests )
            .then(response => {
              if(response.data.error.status === false) {
                // OK
                this.setState({
                  totalGuests: parseInt(response.data.rls) +2
                });
              }
            });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setValueChat(event) {
    this.setState({
      text: event.target.value
    });
  }

  handleSubmit(event) {

    if( this.state.text != '' ) {

      let value = {
        id: this.state.id,
        token: localStorage.getItem("bml_session"),
        user: this.state.user,
        email: this.state.email,
        message: this.state.text,
        type: this.state.type,
        image: this.state.image,
        nameImage: this.state.nameImage,
        show: false,
        bigImage: false
      }

      axios.post(process.env.REACT_APP_API_URL + '/set-message/', value )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              this.setState({
                messages: response.data.rls,
                text: '',
                image: '',
                nameImage: '',
                hideImage: true
              });
              this.scrollChat(2);
            }
            else {
              // NO
              localStorage.removeItem("bml_session");
              window.top.location.href = '/';
            }
          });
    }
  }

  scrollChat(ctrl) {
    if( (ctrl == 1 && this.state.noScroll == false) || ctrl == 2 ) {
      document.getElementById("view-chat").lastElementChild.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end"
      });
      this.setState({
        noScroll: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if( this.state.isMobile == false ) {
      this._input.focus();
    }
  }

  getIcons( type ) {
    if( type == 'sposi' ) {
      return [<FontAwesomeIcon icon="ring" />, <FontAwesomeIcon icon="ring" className="my-ring"/>];
    }
    else {
      return <FontAwesomeIcon icon="user" />;
    }
  }

  getImage( event ) {
    var files = event.target.files;
    if(files[0].size > 5000000) {

      this.setState({
        bigImage: true
      });
      return;
    }
    else {
      this.setState({
        bigImage: false
      });
    }
    if (files.length > 0) {
      var file = files[0];
      //$('#snackbar').removeClass('active');
      if(file.name.indexOf('.') == -1) {
        //this.errorSnackbarService.snackbarMessage(-1, 'Nel nome dell\'immagine manca l\'estensione es: ".jpg" o ".png"', 10000);
        return;
      }
      var img = document.querySelector('#preview');

      var reader = new FileReader();
      reader.onload = (function (aImg) {
        return function (e) {
          aImg.src = e.target.result;
        };
      })(img);

      reader.readAsDataURL(file);

      setTimeout( () => {
        this.setState({
          hideImage: false,
          image: reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""),
          nameImage: file.name
        });
      }, 500);
    }

  }

  hideImage() {
    this.setState({
      hideImage: true
    });
  }

  handleClose() {
    this.setState({
      showLoading: this.state.isMobile ? false : true,
      show: false,
      opacityImage: this.state.isMobile ? 1 : 0
    });
  }

  handleShow( name ) {
    this.setState({
      showLoading: this.state.isMobile ? false : true,
      show: true,
      imageModal : this.state.messages[name].image,
      opacityImage: this.state.isMobile ? 1 : 0
     });

     if ( !this.state.isMobile ) {
       setTimeout( () => {
          var img = document.getElementById('img-modal');
          var width = img.clientWidth;

          this.setState({
            showLoading: false,
            widthBoxImage : width + 'px',
            opacityImage: 1
          });
       }, 1500);
     }
  }

  getGuests() {

  }

  render() {
    return (
      <div>
        <div className="row" >
        <div className="col-lg-4 col-sm-12">
          <p><FontAwesomeIcon icon="user"/> Ciao { this.state.user }</p>
        </div>
        <div className="col-lg-4 col-sm-12">
          {/*<div className="center-single-btn btn-edit" onClick={()=>this.componentDidMount()}>Aggiorna</div>*/}
        </div>
        </div>
        <div className="row view-chat" id="view-chat">
          {this.state.messages.map((value, key) => {
            return (
            <div key={ key } className={ value.name == this.state.user ? 'speech-bubble-1' : 'speech-bubble-2'}>
              <div className="row" >
                <div className="col-lg-6 col-sm-12">
                <span> { this.getIcons( value.type ) } { value.name } </span>
                </div>
                <div className="col-lg-6 col-sm-12">
                 <FontAwesomeIcon icon="clock"/> <Moment format="DD/MM/YYYY H:mm:ss">
                   {value.createdAt}
                 </Moment>
                </div>
              </div>
              <div className="row" >
                <div className={ value.name == this.state.user ? 'col-lg-3 offset-md-8 col-sm-12' : 'col-lg-3 col-sm-12'} style={ value.image == '' || !value.image ? { display: 'none'} : { display: 'block'} }>
                  <img onClick={()=> this.handleShow( key )} src={ process.env.REACT_APP_MEDIA_URL + 'chat/' + this.state.id + '/' + value.image } width="250" className="chat-img chat-img-bubble" />
                </div>
                <p>{ value.text }</p>
              </div>
            </div>
          )})}
          <div id="view-chat-last" className="row" ></div>

        </div>
          <div className="row padding-top-10">
            <div className="col-lg-12 text-center" style={ this.state.hideImage ? { display: 'none'} : { display: 'block'} }>
            <img id="preview" className="chat-img" onError={ this.hideImage } width="250" />
            </div>
            <div className="row">
              <p className="text-center big-image-chat" style={ !this.state.bigImage ? { display: 'none'} : { display: 'block'} }>
                Immagine troppo grande.
              </p>
            </div>
            <div className="col-lg-4 offset-lg-4 col-sm-12 text-center padding-top-10">
              <input type="file" name="file" id="file" className="inputfile" onChange={ this.getImage } />
              <FontAwesomeIcon icon="image" className="button-image" />
            </div>
            <div className="col-lg-4 offset-lg-4 col-sm-12">
              <div className="form-group">
                <label htmlFor="chat">Scrivi qui il tuo messaggio</label>
                <div className="button-chat-users tooltip">
                  <FontAwesomeIcon icon="users" />
                  <span className="tooltiptext">Questa chat è letta da { this.state.totalGuests } utenti (sposi inclusi)</span>
                </div>
                <textarea autoComplete="off" ref="chat" type="text" id="chat" value={ this.state.text } required onChange={ this.setValueChat } ref={c => (this._input = c)}></textarea>
                <FontAwesomeIcon icon="paper-plane" className="button-chat" onClick={ this.handleSubmit } />
              </div>
            </div>
          </div>


          <div className="modal show-modal" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
              <div className="modal-content">
                  <span className="close-button" onClick={this.handleClose}>&times;</span>
                  <div className="row chat-modal-loading" style={ this.state.showLoading ? { display: 'block' } : { display: 'none' } }>
                    <div className="col-md-6 offset-md-3 text-center">
                      <img src="/img/bemylove_logo.png" width="250" />
                    </div>
                    <div className="col-md-6 offset-md-3">
                      <div className="chat-modal-loading-dot">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-sm-12">
                      <div className="content-img-modal" style={ { width: this.state.widthBoxImage, opacity: this.state.opacityImage } }>
                        <img src={ process.env.REACT_APP_MEDIA_URL + 'chat/' + this.state.id + '/' + this.state.imageModal } className="chat-img img-modal" id="img-modal" />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
      </div>
    );
  }
}

export default Chat;
