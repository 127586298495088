import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

library.add(
  faHeart
)

class Interested extends Component {

  render() {
    return (
      <div className="col-lg-12 bg-white interested">
        <h3 className="text-center">Potrebbe interessarti anche</h3>
        <br />
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <ul className="black">
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/controllo-budget">Controllo del Budget</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/lista-impegni">Lista degli impegni</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/i-luoghi-degli-eventi">I luoghi degli eventi</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/chat">Chat</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-12">
            <ul>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/chi-siamo">Chi siamo?</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/inizia">Inizia</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-12">
            <ul>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/lista-invitati">Lista invitati</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/programma-delle-nozze">Programma nozze</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/menu-di-nozze">Menù di nozze</Link>
              </li>
              <li>
                <FontAwesomeIcon icon="heart" />
                <Link to="/lista-nozze">Lista nozze</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Interested;
