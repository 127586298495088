import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class ChatSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Chat - bemylove - Chatta con tutti gli invitati del tuo matrimonio!</title>
          <meta name="description" content="Tra le varie funzioni che ho trovato su bemylove, quella davvero interessante e originale e la funzione Chat. Non appena si accede alla piattaforma, basta cliccare sull'apposita sezione chat. Si dovrà scrivere l'email inserita quando si da conferma della propria presenza e accedere." />
          <meta property="og:title" content="Chat - bemylove - Chatta con tutti gli invitati del tuo matrimonio!" />
          <meta property="og:url" content="https://bemylove.it/chat" />
          <link rel="canonical" href="https://bemylove.it/chat"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Chat</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/AyhAJeHZJ7g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                <h3>L'esperienza di Francesco (il cugino della sposa):</h3><br />
                Non appena ho saputo del matrimonio di mia cugina Elisa, sono stato
                subito molto felice per lei e per Gianni. Loro, gli eterni fidanzati,
                avevano finalmente la possibilità di coronare il loro sogno d'amore
                con un bellissimo matrimonio in Sicilia. Il sole, il mare, i paesaggi
                mozzafiato e il buon cibo… Cosa volere di più? <br /><br /><br />
                Gianni e Elisa rappresentano una coppia davvero moderna e la scelta
                di utilizzare <strong>bemylove</strong> per organizzare il matrimonio di certo è
                stata azzeccata per due come loro! Tra le varie funzioni che ho
                trovato su <strong>bemylove</strong>, quella davvero interessante e originale e la funzione Chat.<br /><br /><br />
                Non appena si accede alla piattaforma, basta cliccare sull'apposita
                sezione chat. Si dovrà scrivere l'email inserita quando si da conferma
                della propria presenza e accedere. <br /><br /><br />
                Immediatamente si aprirà la Finestra
                chat che vi metterà in contatto con tutti gli invitati al
                matrimonio e gli sposi stessi. Un filo diretto con i festeggiati
                e con tutti i vostri amici e parenti a cui potrete inviare messaggi
                o addirittura immagini! Proprio sulla chat di <strong>bemylove</strong> ho avuto
                modo di poter leggere le parole di quella che adesso è la donna
                della mia vita, Anna, la testimone di Gianni che ho incontrato
                per la prima volta alle nozze.<br /><br /><br />
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ChatSite;
