import React, { Component } from 'react';
import axios from 'axios';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import PlayCard from '../play-card/PlayCard';
import { ReactComponent as Logo } from '../../svg/bemylove_logo_2.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUsers, faHeart, faMoneyBillWaveAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

import MetaTags from 'react-meta-tags';

library.add(
  faUsers,
  faHeart,
  faMoneyBillWaveAlt,
  faMapMarkedAlt
)

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagN: 0,
      lang: navigator.language || navigator.userLanguage
    };

    document.body.classList.remove('back');

    this.setTag = this.setTag.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setTag( 0 );
  }

  goToFirstSection() {
    let intViewportHeight = window.innerHeight;
    window.scrollTo(0, intViewportHeight);
    // document.querySelector("#bemylove").classList.add("set-animate");
  }

  setTag( value ) {
    this.setState({
      tagN: value
    });
  }

  getTag( value ) {
    switch (value) {
      case 0:
        return <p>
                  Mi devo sposare oggi o tra un mese? Vivi in tranquillità l'attesa ma,
                  controlla constantemente il countdown per non arrivare tardi!
               </p>;
        break;
      case 1:
        return <p>
                  Inserite in questa sezione tutte le informazioni utili per il
                  vostro matrimonio. L'indirizzo della cerimonia, del ricevimento,
                  le informazioni sugli sposi, i codici di accesso all'APP e tanto altro!
               </p>;
        break;
      case 2:
        return <p>
                  Siete stanchi della solita lista nozze tradizionale? Perché non
                  dare ai tuoi invitati la possibilità di vedere in tempo reale cosa
                  possono ancora regalarti con accesso direttamente dal proprio smartphone?
                  Con <strong>bemylove</strong> puoi creare la lista nozze su misura per te! Inserisci i
                  regali che desideri, informa i tuoi invitati sul luogo dove poterli
                  acquistare e sul prezzo. Niente di più utile per i regali di nozze!
                  I tuoi invitati finalmente sapranno dove recarsi per la lista nozze,
                  avranno accesso agli oggetti scelti da voi, il loro prezzo e l'indirizzo
                  del negozio dove acquistarli!
               </p>;
        break;
      case 3:
        return <p>
                  Dove e quando? In questa sezione potrete mettere al corrente i vostri
                  invitati sui luoghi del vostro matrimonio e gli orari. Infatti l'invitato
                  tramite <strong>Google Maps</strong> raggiungerà la location senza dover stressare gli sposi.<br /> <br />
                  <p style={{ textAlign: 'center', color: 'inherit' }}><strong>Stress Sposi zero + Stress Invitati zero = Moglie felice = Matrimonio felice</strong></p>
               </p>;
        break;
      case 4:
        return <p>
                  Conoscere chi ci sarà al vostro matrimonio non sarà più la parte più
                  snervante dell'organizzazione. Infatti grazie alla Lista invitati di <strong>bemylove</strong>
                  potrai conoscere in tempo reale chi sarà presente al giorno più importante della vostra vita!
               </p>;
        break;
      case 5:
        return <p>
                  Grazie alla sezione Menù di nozze è possibile far sapere agli invitati
                  ogni dettaglio del menù! Questo permette anche gli invitati di poter
                  segnalare intolleranze o preferenze alimentari in modo che sia gli
                  sposi che gli invitati avranno tutti ciò che vorranno e quindi rendere
                  perfetto e indimenticabile il vostro matrimonio.
               </p>;
        break;
      case 6:
        return <p>
                  Ebbene sì, per sposarsi è necessario avere un budget a disposizione!<br/ ><br />
                  Prima di iniziare ad organizzare il “grande evento”, la cosa più
                  importante da fare è stabilire il budget a disposizione per il matrimonio.
                  Una volta deciso, è necessario tenere la situazione sotto controllo
                  durante le fasi di organizzazione annotando tutte le voci di uscita e i vari importi.
                  Tenete sotto controllo facilmente tutte le spese ed evita le sorprese
                  dell'ultima ora. Registrate tutte le entrate e uscite ed evitate le
                  indesiderate spese last minute!
               </p>;
        break;
      case 7:
        return <p>
                  Il matrimonio, si sa, deve essere organizzato sempre nei minimi dettagli
                  e avere a portata di mano, direttamente dal proprio smartphone la lista
                  degli impegni per una sposa è il top! Con <strong>bemylove</strong> puoi tenere conto di
                  tutte le cose da fare e di quelle già fatte con un semplice click.
                  Tenete l'organizzazione del vostro matrimonio sotto controllo. Create una lista
                  degli impegni per non dimenticarvi nulla e man mano che svolgete le attività,
                  segnatele come fatte! Ritirare le bomboniere, scegliere la location,
                  passare dal fioraio, comprare le fedi, l'estetista, il parrucchiere,
                  l’auto, gli abiti di nozze, ingaggiare la band... Quante cose si devono fare
                  per arrivare al fatidico giorno del vostro matrimonio e fare in modo che
                  sia tutto perfetto? Tenete traccia dei vostri impegni da affrontare
                  durante l'organizzazione e man mano che svolgete le attività
                  segnateli per avere tutto sotto controllo! Un pratico contatore vi permetterà
                  di capire quanto vi manca per il 100%! Non puoi dimenticare qualcosa da
                  fare se stai organizzando il tuo matrimonio con <strong>bemylove!</strong>
               </p>;
        break;
      case 8:
        return <p>
                  Chat? Gruppo? Oh no ancora un altro gruppo? <br /><br />
                  Niente paura, qui ci sarà solo chi ha accettato l'invito e sopratutto la zia
                  non potrà condividere delle GIF con scritto "buongiornissimo!!!"
               </p>;
        break;
        case 10:
          return <p>
                    Nessun invitato potrà mai dire "Ma era oggi??" Tutti puntuali grazie
                    al countdown!
                 </p>;
          break;
        case 11:
          return <p>
                    Come si chiama lo sposo? Dove si mangia? A che ora? Questa e tante altre
                    risposte verranno date agli invitati!
                 </p>;
          break;
        case 12:
          return <p>
                    Quale regalo acquistare? Ce l'hanno la macchinetta del caffè? Ma quale modello
                    vogliono? Grazie a questa sezione ogni invitato saprà cosa comprare, dove e il
                    prezzo!
                 </p>;
          break;
        case 13:
          return <p>
                    Dove e quando? In questa sezione i vostri
                    invitati sapranno tutto sui luoghi del vostro matrimonio e gli orari. Infatti l'invitato
                    tramite <strong>Google Maps</strong> raggiungerà la location senza dover stressare gli sposi.<br /> <br />
                    <p style={{ textAlign: 'center', color: 'inherit' }}><strong>Stress Sposi zero + Stress Invitati zero = Moglie felice = Matrimonio felice</strong></p>
                 </p>;
          break;
        case 14:
          return <p>
                    Chi sarà presente alle nozze? Gli invitati sapranno chi ha dato conferma all'invito in modo
                    da potersi preparare psicologicamente.
                 </p>;
          break;
        case 15:
          return <p>
                    Si va bene, tutto molto bello ma... che si mangia? C'è la pizza? Grazie a questa sezione
                    ogni invitato saprà se dovrà portarsi il cibo da casa o no!
                 </p>;
          break;
        case 18:
          return <p>
                    Chat? Gruppo? Oh no, ancora un altro gruppo? <br /><br />
                    Niente paura, qui ci sarà solo chi ha accettato l'invito e sopratutto la zia
                    non potrà condividere delle GIF con scritto "buongiornissimo!!!"
                 </p>;
          break;
      default:
        return <p>
                <p style={{ textAlign: 'center', color: 'inherit' }}><strong>Seleziona una voce in alto</strong></p>
               </p>;
    }

  }

  // ctrlScroll() {
  //   let offset = 400;
  //   let posScroll = Math.abs(document.body.getBoundingClientRect().top);
  //   let posBoxOne = parseInt(document.querySelector("#bemylove").offsetTop) - offset;
  //   let posBoxTwo = parseInt(document.querySelector("#section1").offsetTop) - offset;
  //   let posBoxThree = parseInt(document.querySelector("#section3").offsetTop) - offset;
  //   let posBoxFour = parseInt(document.querySelector("#section5").offsetTop) - offset;
  //   let posBoxFive = parseInt(document.querySelector("#section6").offsetTop) - offset - 200;
  //   let posBoxSix = parseInt(document.querySelector("#section7").offsetTop) - offset;
  //
  //   if( posScroll >= posBoxOne ) {
  //     document.querySelector("#bemylove").classList.add("set-animate");
  //   }
  //
  //   if( posScroll >= posBoxTwo ) {
  //     document.querySelector("#section1").classList.add("set-animate");
  //   }
  //
  //   if( posScroll >= posBoxThree ) {
  //     document.querySelector("#section3").classList.add("set-animate");
  //   }
  //
  //   if( posScroll >= posBoxFour ) {
  //     document.querySelector("#section5").classList.add("set-animate");
  //   }
  //
  //   if( posScroll >= posBoxFive ) {
  //     document.querySelector("#section6").classList.add("set-animate");
  //   }
  //
  //   if( posScroll >= posBoxSix ) {
  //     document.querySelector("#section7").classList.add("set-animate");
  //   }
  //
  // }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <Header ctrl="0" />
        <div id="home">

          <div id="fullpage">
            <div className="section" id="section0">
              <div className="white">
                <Logo className="logo" width="200" height="200" />
                <h1>bemylove</h1>
                <p>Il tuo wedding planner digitale!</p>
                <div className="content-scroll">
                  <div className="mouse" onClick={ (e) => this.goToFirstSection() }>
                    <div className="scroll"></div>
                  </div>
                  <div className="help-scroll" onClick={ (e) => this.goToFirstSection() }>Scopri in che modo possiamo aiutarti</div>
                </div>
                <div className="waveWrapper waveAnimation">
                  <div className="waveWrapperInner bgTop">
                    <div className="wave waveTop" style={{backgroundImage: "url('/img/wave-top.png')"}}></div>
                  </div>
                  <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle" style={{backgroundImage: "url('/img/wave-mid.png')"}}></div>
                  </div>
                  <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom" style={{backgroundImage: "url('/img/wave-bot.png')"}}></div>
                  </div>
                </div>
              </div>
            </div>

            <section className="section-1">
              <div className="content-title">
                <div className="content-section-1">
                  <div className="col-1">
                    <div className="box-text">
                      <h2>Registrati</h2>
                    </div>
                    <hr className="gray" />
                    <p className="content-text">
                      Clicca su Registrati ed inserisci le informazioni base tue
                      e del tuo futuro sposo!<br /><br />
                      Una volta inserite queste informazioni <strong>ti verranno forniti 2
                      codici,</strong> uno è per voi sposi e l'altro è da comunicare agli invitati.
                      Questi codici vi permetteranno di accedere nelle rispettive aree.
                      Allo stesso tempo, riceverai un email per confermare e abilitare il
                      tuo account.<br /><br />
                      <strong>Che differenza c'è tra l'accesso con il codice sposi e l'accesso con il
                      codice invitati?</strong><br /><br />
                      <strong>ACCESSO CON IL CODICE SPOSI:</strong> Gli sposi potranno
                      modificare e aggiornare tutte le informazioni riguardo il proprio
                      matrimonio e accederanno a delle aree che non saranno visibili
                      agli invitati come ad esempio la Gestione del Budget e la Lista degli
                      Impegni.<br /><br />
                      <strong>ACCESSO CON IL CODICE INVITATI:</strong> Gli invitati potranno
                      solo consultare tutto quello che gli sposi gli renderanno visibile.
                      Potranno inserire la propria conferma al matrimonio o utilizzare la chat per
                      comunicare con gli altri invitati e gli sposi.
                    </p>
                    <div className="single-btn">
                      <p className="text-center margin-bottom-0" style={{ color: 'black' }}><strong>Vuoi sposarti?</strong></p>
                      <Link to="/registrati">
                        <div className="button margin-top-0 " style={{backgroundColor : '#ef5aa7'}}>
                          Sì, lo voglio! <FontAwesomeIcon icon="male" style={{ color: 'white' }} /><FontAwesomeIcon icon="female" style={{ color: 'white' }} />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-2">
                    <img src="/img/mobile-login-w.png" width="250" title="App Bemylove" alt="App Bemylove" />
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2 trittico">
              <div className="content-title">
                <div className="box-text">
                  <h2>Accedi da qualunque dispositivo!</h2>
                </div>
                <hr className="whitehr" />
                <p className="content-text">
                  PC? Tablet? Smartphone? Decidi tu da dove accedere!
                </p>
                <div className="content-trittico">
                  <img src="/img/trittico.png" title="Scarica l'APP Bemylove" alt="Scarica l'APP Bemylove" />
                </div>
                <div className="cta-point-2">
                  <Link to="/login">
                    <div className="button margin-top-0">
                      Accedi <FontAwesomeIcon icon="sign-in-alt" style={{ color: 'white' }} />
                    </div>
                  </Link>
                  <a href={ "https://play.google.com/store/apps/details?id=app.bemylove&gl=" + this.state.lang } target="_blank">
                    <div className="button margin-top-0">
                      <div className="text-center">Scarica l'APP per Android! <FontAwesomeIcon icon="download" style={{ color: 'white' }} /></div>
                    </div>
                  </a>
                  <a href={ "https://apps.apple.com/" + this.state.lang + "/app/bemylove/id1552841878" } target="_blank">
                    <div className="button margin-top-0">
                      <div className="text-center">Scarica l'APP per iOS! <FontAwesomeIcon icon="download" style={{ color: 'white' }} /></div>
                    </div>
                  </a>
                </div>
              </div>
            </section>

            <section className="">
              <div className="content-title">
                <div className="box-text">
                  <h2>Scarica l'APP</h2>
                </div>
                <hr className="gray" />
                <p className="content-text">
                  Scopri in che modo possiamo aiutare gli sposi e gli invitati.
                </p>
                <div className="content-mobile">
                  <img src="/img/mobile-w-2.png" title="Scarica l'APP Bemylove" alt="Scarica l'APP Bemylove" width="250" />
                </div>
                <div className="cta-point-2">
                  <a href={ "https://play.google.com/store/apps/details?id=app.bemylove&gl=" + this.state.lang } target="_blank">
                    <div className="button margin-top-0">
                      <div className="text-center">Scarica l'APP per Android! <FontAwesomeIcon icon="download" style={{ color: 'white' }} /></div>
                    </div>
                  </a>
                  <a href={ "https://apps.apple.com/" + this.state.lang + "/app/bemylove/id1552841878" } target="_blank">
                    <div className="button margin-top-0">
                      <div className="text-center">Scarica l'APP per iOS! <FontAwesomeIcon icon="download" style={{ color: 'white' }} /></div>
                    </div>
                  </a>
                </div>
              </div>
            </section>

            <section className="section-3">
              <div className="">
                <div className="box-text">
                  <div>
                    <p>Accesso Sposi</p>
                    <p><small><i>Cosa potranno fare gli sposi?</i></small></p>
                  </div>
                  <div>
                    <p>Accesso Invitati</p>
                    <p><small><i>Cosa potranno fare gli invitati?</i></small></p>
                  </div>
                </div>
                <hr className="gray" />
                <div className="content-how">
                  <div className="list-tags">
                    <div className={this.state.tagN === 0 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 0 ) }>
                      COUNTDOWN<br />
                    </div><div className="arrow"></div>
                    <div className={this.state.tagN === 1 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 1 ) }>
                      GENERALE<br />
                    </div>
                    <div className={this.state.tagN === 2 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 2 ) }>
                      LISTA NOZZE<br />
                    </div>
                    <div className={this.state.tagN === 3 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 3 ) }>
                      PROGRAMMA<br />
                    </div>
                    <div className={this.state.tagN === 4 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 4 ) }>
                      INVITATI<br />
                    </div>
                    <div className={this.state.tagN === 5 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 5 ) }>
                      MENU'<br />
                    </div>
                    <div className={this.state.tagN === 6 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 6 ) }>
                      BUDGET<br />
                    </div>
                    <div className={this.state.tagN === 7 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 7 ) }>
                      LISTA IMPEGNI<br />
                    </div>
                    <div className={this.state.tagN === 8 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 8 ) }>
                      CHAT<br />
                    </div>
                  </div>
                  <div className="read-tags">
                    { this.getTag( this.state.tagN ) }
                  </div>
                  <div className="list-tags">
                    <div className={this.state.tagN === 10 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 10 ) }>
                      COUNTDOWN<br />
                    </div>
                    <div className={this.state.tagN === 11 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 11 ) }>
                      GENERALE<br />
                    </div>
                    <div className={this.state.tagN === 12 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 12 ) }>
                      LISTA NOZZE<br />
                    </div>
                    <div className={this.state.tagN === 13 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 13 ) }>
                      PROGRAMMA<br />
                    </div>
                    <div className={this.state.tagN === 14 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 14 ) }>
                      INVITATI<br />
                    </div>
                    <div className={this.state.tagN === 15 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 15 ) }>
                      MENU'<br />
                    </div>
                    <div className={this.state.tagN === 18 ? 'button margin-top-0 active' : 'button margin-top-0'} onClick={ (e) => this.setTag( 18 ) }>
                      CHAT<br />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="cta-one section-2 cards">
              <div className="content-title">
                <div className="box-text">
                  <h2>Come andrà il mio matrimonio?</h2>
                </div>
                <hr className="whitehr" />
                <p className="content-text">
                  Arrivate preparati al giorno più importante della vostra vita e
                  scoprite come andrà giocando con le carte di <strong>bemylove!</strong>
                </p>
                <PlayCard title="off" />
              </div>
            </section>

            <div className="cta-one section-2 section-4 content-minilove-div">
              <div className="content-title">
                <div className="box-text">
                  <h2>Minilove</h2>
                </div>
                <hr className="whitehr" />
              </div>
              <div className="content-minilove">
                <div className="single-col-minilove">
                  <img src="/img/minilove-m.png" width="200" />
                </div>
                <div className="single-col-minilove-text">
                  <p>
                    Fatevi un selfie e divertitevi a creare il vostro <strong>MiniLove</strong>! Non
                    dimenticatevi di stamparlo e ritagliarlo!
                  </p>
                </div>
                <div className="single-col-minilove">
                  <img src="/img/minilove-f.png" width="200" />
                </div>
              </div>
              <Link to="/minilove">
                <div className="button margin-top-0" style={{backgroundColor : '#ef5aa7', width: '200px', margin: '0 auto', textAlign: 'center'}}>
                  Crea!
                </div>
              </Link>
              <br /><br />
              <div className="waveWrapper waveAnimation waveWrapperFooter" style={{ height: '130px' }}>
                <div className="waveWrapperInner bgTop">
                  <div className="wave waveTop" style={{backgroundImage: "url('/img/wave-top.png')"}}></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                  <div className="wave waveMiddle" style={{backgroundImage: "url('/img/wave-mid.png')"}}></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                  <div className="wave waveBottom" style={{backgroundImage: "url('/img/wave-bot-last.png')"}}></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
