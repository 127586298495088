import React, { Component } from 'react';
import './Payments.css';


class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };


  }

  render() {
    return (
      <div className="Payments" >
        <h2>Pagamenti</h2>
      </div>
    );
  }
}

export default Payments;
