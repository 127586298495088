import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';


import MetaTags from 'react-meta-tags';


class ControlLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session') ) {
      axios.post(process.env.REACT_APP_API_URL + '/login-session/', { token: localStorage.getItem('bml_session') } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              this.props.history.push(
                '/' + response.data.access + '/home',
                {
                  id: response.data.user_id,
                  level: response.data.user_level,
                  page: 'home',
                  type: response.data.access
                }
              );
            }
            else {
              // NO
              this.setState({error: true});
            }
          });
    }
    else {
      this.handleSubmit( this.props.match.params.code );
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit( value ) {
    if( value.length === 8 ) {
      axios.post(process.env.REACT_APP_API_URL + '/login/', { login: value } )
          .then(response => {

            if(response.data.error.status === false) {
              // OK
              localStorage.setItem("bml_session", response.data.token);
              this.props.history.push(
                '/' + response.data.access + '/home',
                {
                  id: response.data.user_id,
                  level: response.data.user_level,
                  page: 'home',
                  type: response.data.access
                }
              );
            }
            else {
              // NO
              this.setState({error: true});
              this.props.history.push('/');
            }
          });
    }
    else {
      this.setState({error: true});
    }
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>Login - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Login - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div id="login">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row">
            <h2 className="text-center">Attendi...</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default ControlLogin;
