import React, { Component } from 'react';
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons/index';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Results from '../../../partials/results/Results';

library.add({
  faTrash,
  faTimes,
  faCheck
});

var elm = {
  id: 0,
  what: '',
  done: 0
};

const DragHandle = SortableHandle(({singleElm, index}) => {
  return (
    <div className="col-lg-1 col-12 drag" style={ window.innerWidth < 1000 ? { display: 'none' } : { display: 'block' }}>
      <FontAwesomeIcon icon="grip-lines" className="margin-icons" />
    </div>
    )}
  );


const SortableElm = SortableElement(({elmList, index, removeElm, signElm}) => {
    return (
      <div key={index}  className={ elmList.done == 1 ? 'row strike' : 'row' }>
        <DragHandle singleElm={ elmList } index={ index } />
        <div className="col-lg-9">
          <div className="row padding-top-20" style={{ borderBottom: "0" }}>
            <div className="col-12">
              <FontAwesomeIcon icon="clipboard" className="margin-icons" /> { parseInt(index+1) + '. ' + elmList.what }
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-6 text-center tooltip">
          <FontAwesomeIcon className="margin-top-50" icon={ elmList.done == 0 ? 'times' : 'check'} onClick={()=> signElm(elmList.id, elmList.done)} />
          <span className="tooltiptext">{ elmList.done == 0 ? 'Clicca qui se è stato fatto' : 'Clicca qui se non è stato fatto'}</span>
        </div>
        <div className="col-lg-1 col-6 text-center tooltip">
          <FontAwesomeIcon icon="trash" className="margin-top-50" onClick={()=> removeElm(index)} />
          <span className="tooltiptext" style={ window.innerWidth < 1000 ? {} : { top: '-20px', left: '40px' } }>Elimina questo elemento</span>
        </div>
      </div>
    )}
  );

const SortableElmList = SortableContainer(({allElms, removeElm, signElm}) => {
  return (
    <div className="content-elms">
      {allElms.map((value, index) => {
        return (
        <SortableElm key={`item-${index}`} index={index} elmList={value} removeElm={removeElm} signElm={signElm} />
      )})}
    </div>
  );
});

class TodoLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: this.props.user_id,
      allElms: this.props.allElms,
      resultsText: "Clicca su \"Salva cambiamenti\" per confermare!",
      updateInfo: false,
      completed: 60
    };

    this.whatElmChange = this.whatElmChange.bind(this);

    this.addElm = this.addElm.bind(this);
    this.removeElm = this.removeElm.bind(this);
    this.signElm = this.signElm.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.allElms !== this.state.allElms ) {
        this.setState({
          allElms: nextProps.allElms
        });
        return true;
    }
    return false;
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ allElms }) => ({
      allElms: arrayMove(allElms, oldIndex, newIndex),
    }));

    this.props.allElms.length = 0;

    this.state.allElms.forEach( elmPush => {
      this.props.allElms.push(elmPush);
    });

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);
  };

  whatElmChange(event) {
    elm.what = event.target.value;
  }

  addElm() {
    if( elm.what !== '' ) {

      let tmpElms = this.state.allElms;

      tmpElms.push(elm);

      this.setPropsState( tmpElms );

      this.setState({updateInfo: true });

      setTimeout( () => {
        this.setState({
          updateInfo: false
        });
      }, 3000);

      elm = {
        id: 0,
        what: '',
        done: 0
      };
      this.refs.elmWhat.value = '';
    }
  }

  removeElm(index) {

    let elmRemove = this.state.allElms;

    elmRemove.splice( index , 1);

    this.setPropsState( elmRemove );

    this.setState({updateInfo: true });

    setTimeout( () => {
      this.setState({
        updateInfo: false
      });
    }, 3000);

  }

  signElm(id, done) {
    let value = {
      id: this.state.user_id,
      elmId: id,
      token: localStorage.getItem("bml_session"),
      done: done == 1 ? 0 : 1
    }

    axios.post(process.env.REACT_APP_API_URL + '/set-todo-done/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setPropsState(response.data.rls);
          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
      });

  }

  setPropsState( elms ) {
    this.setState({allElms: elms});

    if( this.state.allElms.length !== this.props.allElms.length ) {
      this.props.allElms.length = 0;

      this.state.allElms.forEach( elmPush => {
        this.props.allElms.push(elmPush);
      });
    }
  }

  completed() {
    let done = 0;
    if( this.state.allElms.length > 0) {
      this.state.allElms.forEach( elmCtrl => {
        if( elmCtrl.done == true ) {
          done++;
        }
      });
    }

    if( done == 0 ) {
      return 0;
    }

    return Math.round((done*100)/this.state.allElms.length);
  }

  render() {
    return (
      <div>
        { /* TODO */}
        <div className="row-content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="whatGift">Cosa?</label>
                <input type="text" aria-describedby="Cosa?" ref="elmWhat" onChange={ this.whatElmChange } />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="center-single-btn button" onClick={this.addElm}>Aggiungi <FontAwesomeIcon icon="plus" /></div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="my-progress-bar">
                <span className="progress-value" style={{ width: this.completed() + "%" }}></span>
              </div>
              <div className="completed text-right">
                <small>Hai Completato il { this.completed() }%</small>
              </div>
            </div>
          </div>
          <SortableElmList allElms={this.state.allElms} onSortEnd={this.onSortEnd} removeElm={this.removeElm} signElm={this.signElm} useDragHandle  />
        </div>
        <Results text={ this.state.resultsText } ctrl={ this.state.updateInfo } />
      </div>
  )};
}

export default TodoLists;
