import React, { Component } from 'react';
import axios from 'axios';
import { Link, Switch, Router } from "react-router-dom";
import { ReactComponent as Logo } from '../svg/bemylove_logo_2.svg';

import General from './tabs/general/General';
import List from './tabs/list/List';
import Program from './tabs/program/Program';
import Guest from '../sharing/guest/Guest';
import Cars from './tabs/cars/Cars';
import Payments from './tabs/payments/Payments';
import Menu from './tabs/menu/Menu';
import Todo from './tabs/todo/Todo';
import Budget from './tabs/budget/Budget';
import Messages from '../sharing/messages/Messages';
import ModalPay from '../sharing/modal-pay/ModalPay';
import HomeLog from '../sharing/home/HomeLog';

import MetaTags from 'react-meta-tags';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLock } from '@fortawesome/free-solid-svg-icons';

library.add(
  faLock
)

class Married extends Component {
  constructor(props) {

    super(props);

    this.state = {
      tab: props.history.location.state ? props.history.location.state.page : 'generale',
      menu: false,
      level: props.history.location.state ? props.history.location.state.level : 0,
      type: props.history.location.state ? props.history.location.state.type : 0,
      isMobile: window.innerWidth < 1000 ? '50' : '80',
      show: props.history.location.state && props.history.location.state.level == 0 && props.history.location.state.page == 'generale' ? true : false
    };

    this.setTab = this.setTab.bind(this);
    this.goTo = this.goTo.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.logout = this.logout.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.handleShow = this.handleShow.bind(this);

    document.body.classList.add('back');

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( !localStorage.getItem('bml_session') ) {
      this.logout();
    }
  }

  setTab( value ) {
    this.setState({
      tab: value,
      menu: false,
      show: false
    });
    localStorage.setItem("bml_menu", value);
    return true;
  }

  logout() {
    let input = {
      token: localStorage.getItem("bml_session")
    };

    axios.post(process.env.REACT_APP_API_URL + '/logout/', input)
        .then(response => {
          localStorage.removeItem("bml_session");
          this.props.history.push('/');
        });
  }

  renderSwitch() {
    if( !this.props.history.location.state ) {
      this.logout();
      return;
    }

    switch( this.state.tab ) {
      case 'home':
        return <HomeLog id={ this.props.history.location.state.id } />;
      case 'generale':
        return <General id={ this.props.history.location.state.id } />;
      case 'lista-nozze':
        return <List id={ this.props.history.location.state.id } />;
      case 'programma':
        return <Program id={ this.props.history.location.state.id } />;
      case 'invitati':
        return <Guest id={ this.props.history.location.state.id } ctrl="1" myprops={ this.props } />;
      case 'gestione-auto':
        return <Cars id={ this.props.history.location.state.id } ctrl="1" />;
      case 'pagamenti':
        return <Payments  id={ this.props.history.location.state.id } />;
      case 'menu':
        return <Menu id={ this.props.history.location.state.id } />;
      case 'lista-impegni':
        return <Todo id={ this.props.history.location.state.id } />;
      case 'budget':
        return <Budget id={ this.props.history.location.state.id } />;
      case 'chat':
        return <Messages
                  id={ this.props.history.location.state.id }
                  level={ this.props.history.location.state.level }
                  type={ this.props.history.location.state.type }
                  history={ this.props.history } />;
    }

  }

  openMenu() {
    this.setState({
      menu: !this.state.menu
    });
  }

  handleShow( value ) {
    this.setState({
      show: value
    });
  }

  goTo( value ) {
    if( this.state.level == 0 && ( value == 'menu' || value == 'budget' || value == 'lista-impegni' || value == 'chat' ) ) {
        this.setState({
          show: true
        });
        return true;
    }

    this.props.history.push(
      '/sposi/' + value,
      {
        id: this.props.history.location.state.id,
        level: this.props.history.location.state.level,
        page: value,
        type: this.props.history.location.state.type
      }
    );
    return true;
  }

  componentWillReceiveProps(nextProps) {
    this.setTab( nextProps.history.location.state.page )
  }

  render() {
    return (
      <div className="Married container">
        <MetaTags>
          <title>Area Sposi - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Area Sposi - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div className="row">
          <nav id="nav-site" className={this.state.menu ? 'navbar fixed-top navbar-expand-lg open' : 'navbar fixed-top navbar-expand-lg'}>
            <div className="nav-brand">
              <Link to="/">
                <Logo className="logo" width="50" height="50" /> bemylove
              </Link>
              <span className="section-text"><br /><small><i>Sezione Sposi</i></small></span>
            </div>
            <span className={this.state.menu ? 'change navbar-toggler' : 'navbar-toggler'} onClick={this.openMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </span>
            <div className="collapse navbar-collapse list-menu-site-hover-one" id="collapsibleNavbar" style={this.state.menu ? { display: 'block' } : { display: 'none' } }>
              <ul className="navbar-nav">
                <li className={this.state.tab === 'home' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('home')}>
                  <span>Home</span>
                </li>
                <li className={this.state.tab === 'generale' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('generale')}>
                  <span>Generale</span>
                </li>
                <li className={this.state.tab === 'lista-nozze' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('lista-nozze')}>
                  <span>Lista nozze</span>
                </li>
                <li className={this.state.tab === 'programma' ? 'active nav-item' : 'nav-item'} onClick={ (e) => this.goTo('programma') }>
                  <span>Programma</span>
                </li>
                <li className={this.state.tab === 'invitati' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('invitati')}>
                  <span>Invitati</span>
                </li>
                <li className={this.state.tab === 'menu' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('menu')}>
                  <span>Menù { this.state.level == 0 ? <FontAwesomeIcon icon="lock" /> : '' }</span>
                </li>
                <li className={this.state.tab === 'budget' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('budget')}>
                  <span>Budget { this.state.level == 0 ? <FontAwesomeIcon icon="lock" /> : '' }</span>
                </li>
                <li className={this.state.tab === 'lista-impegni' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('lista-impegni')}>
                  <span>Gli Impegni { this.state.level == 0 ? <FontAwesomeIcon icon="lock" /> : '' }</span>
                </li>
                <li className={this.state.tab === 'chat' ? 'active nav-item' : 'nav-item'} onClick={(e) => this.goTo('chat')} >
                  <span>Chat { this.state.level == 0 ? <FontAwesomeIcon icon="lock" /> : '' }</span>
                </li>
                <li className="nav-item" >
                  <Link to="/minilove">
                    <span>Minilove</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <span onClick={(e) => this.logout()}>Logout</span>
                </li>
              </ul>
            </div>
          </nav>
      </div>
        { this.renderSwitch() }
        <ModalPay id={ localStorage.getItem("bml_session") ? this.props.history.location.state.id : null } show={ this.state.show } />
      </div>
    );
  }
}

export default Married;
