import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { Link } from "react-router-dom";

import ModalConfirm from '../modal/ModalConfirm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faTrash} from '@fortawesome/free-solid-svg-icons';

class Step3 extends Component {
  constructor(props) {

    super(props);

    if( !props.location.state ) {
      window.location.href = '/minilove/';
      return;
    }

    let timestamp =  new Date().getTime();

    this.state = {
      id: props.location.state.id,
      image: props.location.state.image,
      sposoSkin: 1,
      sposoHead: 1,
      sposoBody: 0,
      sposaSkin: 1,
      sposaHead: 1,
      sposaBody: 0,
      sposo: {
        headFront: "",
      },
      sposa: {
        headFront: "",
      },
      rotateViewM: 15,
      rotateViewW: 15,
      timestamp: new Date().getTime(),
      showModal: false,
      showWait: false,
      hideSubmit: false
    }

    this.setSkin = this.setSkin.bind(this);
    this.setHead = this.setHead.bind(this);
    this.setBody = this.setBody.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.rotateView = this.rotateView.bind(this);
    this.deleteMinilove = this.deleteMinilove.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
    document.getElementsByClassName("row-action-footer")[0].style.display = "none";

    let valueBase64 = {
      id: this.state.id,
      image: this.state.image,
      path: 'minilove',
      pathTwo: 'custom',
      token: localStorage.getItem("bml_session")
    }

    axios.post(process.env.REACT_APP_API_URL + '/get-base64-minilove/', valueBase64 )
        .then(response => {
          this.state.sposo.headFront = response.data.rls.sposo;
          this.state.sposa.headFront = response.data.rls.sposa;
          var img1 = document.querySelector('#front-sposo');
          img1.style.backgroundImage  = 'url("' + response.data.rls.sposo + '")';
          var img2 = document.querySelector('#front-sposa');
          img2.style.backgroundImage  = 'url("' + response.data.rls.sposa + '")';
        });
  }

  setSkin( gender, n ) {
    if( gender == 1 ) {
      this.setState({
        sposoSkin: n
      });
    }
    else {
      this.setState({
        sposaSkin: n
      });
    }
  }

  setHead( gender, n ) {
    if( gender == 1 ) {
      this.setState({
        sposoHead: n
      });
    }
    else {
      this.setState({
        sposaHead: n
      });
    }
  }

  setBody( gender, n ) {
    if( gender == 1 ) {
      this.setState({
        sposoBody: n
      });
    }
    else {
      this.setState({
        sposaBody: n
      });
    }
  }

  rotateView( gender, n ) {
    let mul = -15;
    if( n == 1 ) {
      mul = 15;
    }
    if( gender == 1 ) {
      let now = this.state.rotateViewM;
      this.setState({
        rotateViewM: (now) + mul
      });
    }
    else {
      let now = this.state.rotateViewW;
      this.setState({
        rotateViewW: (now) + mul
      });
    }
  }

  handleSubmit( event ) {
    let input = {
      token: localStorage.getItem("bml_session"),
      id: this.state.id,
      image: this.state.image,
      sposoSkin: this.state.sposoSkin,
      sposoHead: this.state.sposoHead,
      sposoBody: this.state.sposoBody,
      sposaSkin: this.state.sposaSkin,
      sposaHead: this.state.sposaHead,
      sposaBody: this.state.sposaBody,
    };

    this.setState({
      hideSubmit: true,
      showWait: true
    });

    axios.post(process.env.REACT_APP_API_URL + '/minilove-wedding/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.props.history.push(
              '/minilove/step-4',
              {
                id: this.state.id,
                image: this.state.image
              }
            );
          }
          else {
            // NO
            document.getElementsByTagName("form")[0].style.display = "none";
            this.setState({
              success: false,
              error: true,
              showWait: false,
              hideSubmit: false
            });
          }

        });
    event.preventDefault();
  }

  deleteMinilove( event ) {
    this.setState({
      showModal: true
    });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <div className="Site Minilove_3">
          <div className="row bg-white">
            <h3>Scegli gli abiti e i capelli</h3>

            <div className="row">
              <div className="col-md-1 list-block">
                <div onClick={ e => this.setHead(1, 1) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-1-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(1, 2) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-2-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(1, 3) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-3-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(1, 4) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-4-2.jpg)' } }></div>
              </div>
              <div className="col-md-4">
                <div className="minilove-rotate-view">
                  <small className="text-center">Ruota in<br />senso<br /></small>
                  <img src="/img/rotate-1.png"  width="50" onClick={ e => this.rotateView(1, 1) } />
                  <small className="text-center"><br />antiorario</small>
                </div>
                <div className="minilove-container">
                  <div className="minilove minilove-rotating" style={{ transform: 'rotateX(-20deg) rotateY(' + this.state.rotateViewM + 'deg)'}}>
                    <div className="cube cube-face">
                      <div className="cube-face cube-face-front-top" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoHead + '-5.png)' } }>front</div>
                      <div className="cube-face cube-face-front" id="front-sposo">front</div>
                      <div className="cube-face cube-face-front-skin" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-1.png)' } }>front</div>
                      <div className="cube-face cube-face-back" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoHead + '-6.jpg)' } }>back</div>
                      <div className="cube-face cube-face-left" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-' + this.state.sposoHead + '-2.jpg)' } }>left</div>
                      <div className="cube-face cube-face-right" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-' + this.state.sposoHead + '-3.jpg)' } }>right</div>
                      <div className="cube-face cube-face-top" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoHead + '-4.jpg)' } }>top</div>
                      <div className="cube-face cube-face-bottom" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-0.jpg)' } }>bottom</div>
                    </div>
                    <div className="cube cube-body">
                      <div className="cube-face cube-face-front" style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-' + this.state.sposoBody + '-1.jpg)' } }>front</div>
                      <div className="cube-face cube-face-back" style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-' + this.state.sposoBody + '-4.jpg)' } }>back</div>
                      <div className="cube-face cube-face-left" style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-' + this.state.sposoBody + '-2.jpg' } }>left</div>
                      <div className="cube-face cube-face-right" style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-' + this.state.sposoBody + '-3.jpg' } }>right</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col block">
                    <div onClick={ e => this.setSkin(1, 1) } style={ { backgroundImage : 'url(/img/minilove/head/head-1-0-0.jpg)' } }></div>
                    <div onClick={ e => this.setSkin(1, 2) } style={ { backgroundImage : 'url(/img/minilove/head/head-2-0-0.jpg)' } }></div>
                    <div onClick={ e => this.setSkin(1, 3) } style={ { backgroundImage : 'url(/img/minilove/head/head-3-0-0.jpg)' } }></div>
                    <div onClick={ e => this.setSkin(1, 4) } style={ { backgroundImage : 'url(/img/minilove/head/head-4-0-0.jpg)' } }></div>
                  </div>
                </div>
                <div className="minilove-rotate-view minilove-rotate-view-m-1">
                  <small className="text-center">Ruota in<br />senso<br /></small>
                  <img src="/img/rotate-2.png" width="50" onClick={ e => this.rotateView(1, 2) } />
                  <small className="text-center"><br />orario</small>
                </div>
              </div>
              <div className="col-md-1 list-block">
                <div onClick={ e => this.setBody(1, 0) } style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-0-1.jpg)' } }></div>
                <div onClick={ e => this.setBody(1, 1) } style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-1-1.jpg)' } }></div>
                <div onClick={ e => this.setBody(1, 2) } style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-2-1.jpg)' } }></div>
                <div onClick={ e => this.setBody(1, 3) } style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-3-1.jpg)' } }></div>
                <div onClick={ e => this.setBody(1, 4) } style={ { backgroundImage : 'url(/img/minilove/sposo/minilove-' + this.state.sposoSkin + '-4-1.jpg)' } }></div>
              </div>
              <div className="col-md-1 list-block">
                <div onClick={ e => this.setHead(2, 1) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-1-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(2, 2) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-2-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(2, 3) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-3-2.jpg)' } }></div>
                <div onClick={ e => this.setHead(2, 4) } style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposoSkin + '-4-2.jpg)' } }></div>
              </div>
              <div className="col-md-4">
                <div className="minilove-rotate-view">
                  <small className="text-center">Ruota in<br />senso<br /></small>
                  <img src="/img/rotate-1.png"  width="50" onClick={ e => this.rotateView(2, 1) } />
                  <small className="text-center"><br />antiorario</small>
                </div>
                <div className="minilove-container">
                  <div className="minilove minilove-rotating-negative" style={{ transform: 'rotateX(-20deg) rotateY(' + this.state.rotateViewW + 'deg)'}}>
                    <div className="cube cube-face">
                      <div className="cube-face cube-face-front-top" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaHead + '-5.png)' } }>front</div>
                      <div className="cube-face cube-face-front" id="front-sposa">front</div>
                      <div className="cube-face cube-face-front-skin" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaSkin + '-1.png)' } }>front</div>
                      <div className="cube-face cube-face-back" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaHead + '-6.jpg)' } }>back</div>
                      <div className="cube-face cube-face-left" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaSkin + '-' + this.state.sposaHead + '-2.jpg)' } }>left</div>
                      <div className="cube-face cube-face-right" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaSkin + '-' + this.state.sposaHead + '-3.jpg)' } }>right</div>
                      <div className="cube-face cube-face-top" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaHead + '-4.jpg)' } }>top</div>
                      <div className="cube-face cube-face-bottom" style={ { backgroundImage : 'url(/img/minilove/head/head-' + this.state.sposaSkin + '-0.jpg)' } }>bottom</div>
                    </div>
                    <div className="cube cube-body">
                      <div className="cube-face cube-face-front" style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-' + this.state.sposaBody + '-1.jpg)' } }>front</div>
                      <div className="cube-face cube-face-back" style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-' + this.state.sposaBody + '-4.jpg)' } }>back</div>
                      <div className="cube-face cube-face-left" style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-' + this.state.sposaBody + '-2.jpg' } }>left</div>
                      <div className="cube-face cube-face-right" style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-' + this.state.sposaBody + '-3.jpg' } }>right</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                <div className="col block">
                  <div onClick={ e => this.setSkin(2, 1) } style={ { backgroundImage : 'url(/img/minilove/head/head-1-0-0.jpg)' } }></div>
                  <div onClick={ e => this.setSkin(2, 2) } style={ { backgroundImage : 'url(/img/minilove/head/head-2-0-0.jpg)' } }></div>
                  <div onClick={ e => this.setSkin(2, 3) } style={ { backgroundImage : 'url(/img/minilove/head/head-3-0-0.jpg)' } }></div>
                  <div onClick={ e => this.setSkin(2, 4) } style={ { backgroundImage : 'url(/img/minilove/head/head-4-0-0.jpg)' } }></div>
                </div>
                </div>
                <div className="minilove-rotate-view minilove-rotate-view-m-1">
                  <small className="text-center">Ruota in<br />senso<br /></small>
                  <img src="/img/rotate-2.png" width="50" onClick={ e => this.rotateView(2, 2) } />
                  <small className="text-center"><br />orario</small>
                </div>
              </div>
              <div className="col-md-1 list-block">
              <div onClick={ e => this.setBody(2, 0) } style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-0-1.jpg)' } }></div>
              <div onClick={ e => this.setBody(2, 1) } style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-1-1.jpg)' } }></div>
              <div onClick={ e => this.setBody(2, 2) } style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-2-1.jpg)' } }></div>
              <div onClick={ e => this.setBody(2, 3) } style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-3-1.jpg)' } }></div>
              <div onClick={ e => this.setBody(2, 4) } style={ { backgroundImage : 'url(/img/minilove/sposa/minilove-' + this.state.sposaSkin + '-4-1.jpg)' } }></div>
              </div>
            </div>

            <p className="message-cut" style={ this.state.showWait == false ? { display: 'none' } : { display: 'block' }}>Vi stiamo vestendo...</p>

            <button
              type="button"
              className="btn-submit btn-minilove-delete"
              style={ this.state.hideSubmit == true ? { display: 'none' } : { display: 'block' }}
              onClick={ e => this.deleteMinilove(e) } ><FontAwesomeIcon icon="trash" /> Elimina e ricomincia</button>

            <button
              type="button"
              className="btn-submit btn-minilove-save"
              style={ this.state.hideSubmit == true ? { display: 'none' } : { display: 'block', backgroundColor: '#ef5aa7' }}
              onClick={ e => this.handleSubmit(e) } >Salva <FontAwesomeIcon icon="chevron-right" /></button>

          </div>
        </div>
        <ModalConfirm show={ this.state.showModal } id={ this.state.id } image={ this.state.image } />
      </div>
    );
  }
}

export default Step3;
