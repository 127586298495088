import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGlassCheers, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

library.add({
  faCheck,
  faGlassCheers,
  faSignInAlt
});

class RegisterSuccess extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="register_ok" className="container" style={this.props.loginSposi !== '' ? { display: 'block' } : { display: 'none' } }>
        <h2 className="text-right"><strong>Registrazione effettuata <FontAwesomeIcon icon="check" /></strong></h2>
        <div className="row row-content">
          <p>Complimenti, il tuo matrimonio è stato registrato correttamente! <FontAwesomeIcon icon="glass-cheers" /></p>
          <br /><br />
          <p>Adesso segnatevi i seguenti accessi e mi raccomando, attenti alle MAIUSCOLE e minuscole!</p>
          <br /><br />
          <p>Accesso per gli Sposi: <strong>{ this.props.loginSposi }</strong></p>
          <p>Accesso per gli Invitati: <strong>{ this.props.loginInvitati }</strong></p>
          <br /><br />
          <p className="text-center"><u>ATTENZIONE!</u></p>
          <p className="text-center">
            Prima di fare il login, devi convalidare il tuo account. Ti abbiamo invitato
            una email, se non la vedi controlla nello SPAM!<br />Clicca nel link che troverai
            all'interno e quindi, fai login!
          </p>

          <div className="center-single-btn">
            <Link to="/">
              <div className="button">Accedi <FontAwesomeIcon icon="sign-in-alt" /></div>
            </Link>
          </div>
        </div>
      </div>
  )};
}

export default RegisterSuccess;
