import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class ListTodoSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Lista Impegni - bemylove - Segna le attività da svolgere</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Crea una lista degli impegni per non dimenticarti nulla e man mano che svolgi le attività segnale" />
          <meta property="og:title" content="Lista Impegni - bemylove - Segna le attività da svolgere" />
          <meta property="og:url" content="https://bemylove.it/lista-impegni" />
          <link rel="canonical" href="https://bemylove.it/lista-impegni"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Lista impegni</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/YzDQNoJSPh4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Il <strong>matrimonio</strong>, si sa, deve essere organizzato sempre nei minimi
                dettagli e avere a portata di mano, direttamente dal proprio
                smartphone la <strong>lista degli impegni</strong> per una sposa è il top!
                Con <strong>bemylove</strong> puoi tenere conto di tutte le cose da fare e di
                quelle già fatte con un semplice click.<br /><br />
                Tieni l'organizzazione del tuo matrimonio sotto controllo.
                Crea una <strong>lista degli impegni</strong> per non dimenticarti nulla e man
                mano che svolgi le attività, segnale come fatte!<br /><br />
                Ritirare le bomboniere, scegliere la location, passare dal fioraio,
                comprare le fedi, l'estetista, il parrucchiere, l’auto, gli abiti
                di nozze, ingaggiare la band... Quante cose si devono fare per
                arrivare al fatidico giorno del vostro matrimonio e fare in
                modo che sia tutto perfetto? Tenete traccia dei vostri impegni
                da affrontare durante l'organizzazione del matrimonio e man
                mano che svolgete le attività segnateli per avere tutto sotto controllo!
                Un pratico contatore vi permetterà di capire quanto vi manca per il 100%!<br /><br />
                Non puoi dimenticare qualcosa da fare se stai organizzando il
                tuo matrimonio con <strong>bemylove</strong>!<br /><br />
                <h3>L’esperienza di Chiara (la sposa):</h3>
                Io e il mio compagno Alessandro abbiamo conosciuto la piattaforma
                <strong>bemylove</strong> grazie ad una nostra cugina che lo aveva utilizzato per
                l’organizzazione del suo matrimonio.  <br />
                Quando abbiamo iniziato i preparativi per le <strong>nozze</strong>, ci siamo resi
                conto che una <strong>lista degli impegni</strong> sarebbe stata molto utile per
                noi per evitare di dimenticare le innumerevoli cose che bisogna
                fare per la riuscita ottimale delle nozze. Così dopo aver
                effettuato la registrazione alla piattaforma, nella sezione
                <strong>Lista Impegni</strong> abbiamo iniziato a inserire le cose da fare.  <br /><br />
                Dapprima, alla domanda “Cosa?” bisogna inserire le varie cose da
                fare ad esempio Comprare le fedi, prenotare il ristorante,
                noleggiare l’auto.  <br />
                Cliccando su Aggiungi, la voce verrà automaticamente inserita in
                fondo alla lista.  <br />
                Dopodichè bisogna salvare i cambiamenti effettuati. Man mano che
                le cose da fare vengono sbrigate, é possibile cliccare
                sull’apposita icona a forma di crocetta che contrassegnerà
                la voce come “fatto” e successivamente salvare i cambiamenti
                effettuati. é possibile inoltre eliminare le varie voci o
                modificarle a piacimento.  <br />
                Sicuramente senza l’aiuto di <strong>bemylove</strong>, l’organizzazione delle
                nostre nozze non sarebbe stata così facile e divertente!
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ListTodoSite;
