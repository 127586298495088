import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import ModalCard from '../../play-card/modal-card/ModalCard';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faHeart } from '@fortawesome/free-solid-svg-icons';

class Step0 extends Component {
  _isMounted = false;

  constructor(props) {

    super(props);

    this.state = {
      show: false,
      email: '',
      success: false,
      error: false,
      head: 1,
      body: 1,
      noAccess: false,
      ctaDesk: 'block',
      ctaMobile: 'none'
    };

    this.start = this.start.bind(this);

  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    let imageList = [
      '/img/minilove/head/head-1.jpg',
      '/img/minilove/head/head-2.jpg',
      '/img/minilove/head/head-3.jpg',
      '/img/minilove/head/head-4.jpg',
      '/img/minilove/sposo/minilove-1-2.jpg',
      '/img/minilove/sposo/minilove-1-3.jpg',
      '/img/minilove/sposo/minilove-1-4.jpg',
      '/img/minilove/sposo/minilove-1-1.jpg',
      '/img/minilove/sposo/minilove-1-2.jpg',
      '/img/minilove/sposo/minilove-1-3.jpg',
      '/img/minilove/sposo/minilove-1-4.jpg',
      '/img/minilove/head/head-1-w.jpg',
      '/img/minilove/head/head-2-w.jpg',
      '/img/minilove/head/head-3-w.jpg',
      '/img/minilove/head/head-4-w.jpg',
      '/img/minilove/sposa/minilove-1-1.jpg',
      '/img/minilove/sposa/minilove-1-2.jpg',
      '/img/minilove/sposa/minilove-1-3.jpg',
      '/img/minilove/sposa/minilove-1-4.jpg'
    ];

    imageList.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });

    window.setInterval(() => {
      let head = this.state.head;
      let body = this.state.body;
      if( body == 4 ) {
        head = 0;
        body = 0;
      }
      this.setState({
        head: head + 1,
        body: body + 1
      });
    }, 4000);
    if( localStorage.getItem('bml_session') ) {
      document.getElementsByClassName("row-action-footer")[0].style.display = "none";
    }

    if( window.innerWidth < 1000 ) {
      this.setState({
        ctaDesk: 'none',
        ctaMobile: 'block'
      });
    }

  }

  componentWillUnmount() {
     this._isMounted = false;
   }

  start(event) {

    if( !localStorage.getItem("bml_session") ) {
        this.setState({
          show: true
        });
        return true;
    }
    else {

      let input = {
        token: localStorage.getItem("bml_session")
      };

      axios.post(process.env.REACT_APP_API_URL + '/minilove-goto/', input)
          .then(response => {

            if( response.data.rls == null ) {
              this.props.history.push(
                '/minilove/step-1'
              );
              return;
            }

            if(response.data.error.status === false) {
              // OK
              if( response.data.rls.is_crop == true && response.data.rls.is_end == false ) {
                this.props.history.push(
                  '/minilove/step-3',
                  {
                    id: response.data.rls.user_id,
                    image: response.data.rls.image
                  }
                );
              }
              else if( response.data.rls.is_crop == true && response.data.rls.is_end == true ) {
                  this.props.history.push(
                    '/minilove/step-4',
                    {
                      id: response.data.rls.user_id,
                      image: response.data.rls.image
                    }
                  );
              }
              else {
                this.props.history.push(
                  '/minilove/step-2',
                  {
                    id: response.data.rls.user_id,
                    image: response.data.rls.image
                  }
                );
              }
            }
            else {
              // NO
              //document.getElementsByTagName("form")[0].style.display = "none";

              if( response.data.error.rls == 'no') {
                this.setState({
                  success: false,
                  error: true,
                  noAccess: true
                });
                return;
              }

              this.setState({
                success: false,
                error: true
              });
            }

          });

    }
  }

  render() {
    return (
      <div>
        <div className="Site Minilove_0" style={{ paddingTop: '85px', backgroundColor: 'white' }}>
          <div className="cover" style={{ backgroundImage: 'none', paddingTop: '48px', paddingBottom: '9px', marginBottom: '0'}}>
            <h1 className="text-center">Minilove</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12 bg-white">
              <div className="minilove-container">
                <div className="minilove minilove-rotating man">
                  <div className="cube head">
                    <div className="cube-face animation-face cube-face-front" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>front</div>
                    <div className="cube-face animation-face cube-face-back" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>back</div>
                    <div className="cube-face animation-face cube-face-left" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>left</div>
                    <div className="cube-face animation-face cube-face-right" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>right</div>
                    <div className="cube-face animation-face cube-face-top" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>top</div>
                    <div className="cube-face animation-face cube-face-bottom" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + ".jpg')"}}>bottom</div>
                  </div>
                  <div className="cube corpo">
                    <div className="cube-face animation-body cube-face-front" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>front</div>
                    <div className="cube-face animation-body cube-face-back" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>back</div>
                    <div className="cube-face animation-body cube-face-left" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>left</div>
                    <div className="cube-face animation-body cube-face-right" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>right</div>
                    <div className="cube-face animation-body cube-face-top" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>top</div>
                    <div className="cube-face animation-body cube-face-bottom" style={{ backgroundImage: "url('/img/minilove/sposo/minilove-1-" + this.state.body + ".jpg')"}}>bottom</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 bg-white">
              <div>
                <h4 className="text-center">
                  Divertitevi a costruire il vostro <strong>MiniLove!</strong>
                </h4>
                <ul className="list-minilove">
                  <li><FontAwesomeIcon icon="heart" /> Fatevi un selfie</li>
                  <li><FontAwesomeIcon icon="heart" /> Scegliete la pettinatura e gli abiti</li>
                  <li><FontAwesomeIcon icon="heart" /> Stampate le figure</li>
                  <li><FontAwesomeIcon icon="heart" /> Ritagliate e piegate</li>
                  <li><FontAwesomeIcon icon="heart" /> Condivideteli!</li>
                </ul>
                <div className="col center-single-btn" style={{ display: this.state.ctaDesk }}>
                  <button type="button" className="btn-submit" onClick={(e) => this.start()}>Inizia <FontAwesomeIcon icon="chevron-right" /></button>
                </div>
                <p className="waitSave text-center" style={ !this.state.noAccess ? { display: 'none'} : { display: 'block'} }>Questa sezione è riservata agli sposi!</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 bg-white">
              <div className="minilove-container">
                <div className="minilove minilove-rotating-negative woman">
                  <div className="cube head">
                    <div className="cube-face animation-face cube-face-front" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>front</div>
                    <div className="cube-face animation-face cube-face-back" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>back</div>
                    <div className="cube-face animation-face cube-face-left" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>left</div>
                    <div className="cube-face animation-face cube-face-right" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>right</div>
                    <div className="cube-face animation-face cube-face-top" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>top</div>
                    <div className="cube-face animation-face cube-face-bottom" style={{ backgroundImage: "url('/img/minilove/head/head-" + this.state.head + "-w.jpg')"}}>bottom</div>
                  </div>
                  <div className="cube corpo">
                    <div className="cube-face animation-body cube-face-front" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>front</div>
                    <div className="cube-face animation-body cube-face-back" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>back</div>
                    <div className="cube-face animation-body cube-face-left" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>left</div>
                    <div className="cube-face animation-body cube-face-right" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>right</div>
                    <div className="cube-face animation-body cube-face-top" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>top</div>
                    <div className="cube-face animation-body cube-face-bottom" style={{ backgroundImage: "url('/img/minilove/sposa/minilove-1-" + this.state.body + ".jpg')"}}>bottom</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col center-single-btn" style={{ display: this.state.ctaMobile }}>
            <button type="button" className="btn-submit" onClick={(e) => this.start()}>Inizia <FontAwesomeIcon icon="chevron-right" /></button>
            <br /><br />
          </div>
        </div>
        <ModalCard show={ this.state.show } message="1" />
      </div>
    );
  }
}

export default Step0;
