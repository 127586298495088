import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import MetaTags from 'react-meta-tags';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faUser } from '@fortawesome/free-solid-svg-icons/index';
import { library } from '@fortawesome/fontawesome-svg-core/index';

library.add({
  faUser
});

class ModalCardRls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      sentence: this.props.sentence,
      sentenceValue: this.props.value,
      card: this.props.card
    };

    this.handleClose = this.handleClose.bind(this);

  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {

    if( nextProps.show !== this.state.show ) {
        this.setState({
          show: nextProps.show,
          sentence: nextProps.sentence,
          sentenceValue: nextProps.value,
          card: nextProps.card
        });
        return true;
    }
    return false;
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSubmit( event ) {
    event.preventDefault();

  }

  render() {
    return (
      <div className="modal show-modal modal-card" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>

        <MetaTags>
          <title>Come andrà il mio matrimonio? - bemylove</title>
          <meta name="description" content="Scoprilo con il gioco di carte di bemylove.it! Calcola la percentuale di Amore, Fortuna, Salute e Denaro che avrai nel tuo matrimonio! Cosa aspetti? Iscriviti e gioca con noi!" />
          <meta property="og:title" content="Scopri come andrà il tuo matrimonio - bemylove" />
          <meta property="og:url" content="https://bemylove.it/come-andra-il-mio-matrimonio" />
          <link rel="canonical" href="https://bemylove.it/come-andra-il-mio-matrimonio"/>
        </MetaTags>
        <div className="modal-content">
          <span className="close-button" onClick={this.handleClose}>&times;</span>
            <br />
            <h5 className="text-center">Come andrà il mio matrimonio?</h5>
            <h1 className="text-center">{ this.state.card } ~ { this.state.sentenceValue }%</h1>
            <h2 className="text-center">{ this.state.sentence }</h2><br /><br />
            <div className="row">
              <div className="button-share">
                <FacebookShareButton
                   className="network__share-button"
                   url='https://bemylove.it/come-andra-il-mio-matrimonio'
                   quote={ '"' + this.state.card + ' ~ ' + this.state.sentenceValue + '%\n' + this.state.sentence + '" - @bemylove.it' }
                  >
                    <FacebookIcon
                      // size='64'
                      round={true}
                    />
                  </FacebookShareButton>
              </div>
              <div className="button-share" style={{ display: 'none' }}>
                <TwitterShareButton
                   className="network__share-button"
                   url='https://bemylove.it/come-andra-il-mio-matrimonio'
                   quote={ '"' + this.state.card + ' ~ ' + this.state.sentenceValue + '%\n' + this.state.sentence + '" - @bemylove.it' }
                  >
                    <TwitterIcon
                      // size='64'
                      round={true}
                    />
                  </TwitterShareButton>
              </div>
              <div className="button-share" style={{ display: 'none' }}>
                <TelegramShareButton
                   className="network__share-button"
                   url='https://bemylove.it/come-andra-il-mio-matrimonio'
                   quote={ '"' + this.state.card + ' ~ ' + this.state.sentenceValue + '%\n' + this.state.sentence + '" - @bemylove.it' }
                  >
                    <TelegramIcon
                      // size='64'
                      round={true}
                    />
                  </TelegramShareButton>
              </div>
              <div className="button-share">
                <WhatsappShareButton
                   className="network__share-button"
                   url='https://bemylove.it/come-andra-il-mio-matrimonio'
                   quote={ '"' + this.state.card + ' ~ ' + this.state.sentenceValue + '%\n' + this.state.sentence + '" - @bemylove.it' }
                  >
                    <WhatsappIcon
                      // size='64'
                      round={true}
                    />
                  </WhatsappShareButton>
              </div>
            </div>

        </div>
      </div>
    );
  }
}

export default ModalCardRls;
