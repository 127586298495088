import React, { Component } from 'react';

import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Interested from '../partials/Interested';

import MetaTags from 'react-meta-tags';

class MenuSite extends Component {
  constructor(props) {

    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>Menù di nozze - bemylove - Comunica cosa mangeranno!</title>
          <meta name="description" content="Sarà possibile far sapere agli invitati del matrimonio ogni dettaglio del menù e permettergli  di poter segnalare intolleranze o preferenze alimentari!" />
          <meta property="og:title" content="Menù di nozze - bemylove - Comunica cosa mangeranno!" />
          <meta property="og:url" content="https://bemylove.it/menu-di-nozze" />
          <link rel="canonical" href="https://bemylove.it/menu-di-nozze"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site">
          <div className="cover">
            <h1 className="text-center">Menù di nozze</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 bg-white bg-logo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3jvYdC_9kFw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 bg-white">
              <div>
                <p>
                Grazie alla sezione <strong>Menù di nozze</strong> è possibile far
                sapere agli invitati ogni dettaglio del menù! Questo permette anche
                gli invitati di poter segnalare intolleranze o preferenze alimentari
                in modo che sia gli sposi che gli invitati avranno tutti ciò
                che vorranno e quindi rendere perfetto e indimenticabile il
                vostro matrimonio.<br /><br />
                <h3>L’esperienza di Cristina (la sposa):</h3>
                “Io e Antonio abbiamo deciso di utilizzare <strong>bemylove</strong> per
                l’organizzazione del nostro matrimonio trovata un po' per caso
                mentre navigavamo nel web.<br /><br />
                Tra le varie funzioni che ci sono piaciute di più c’è stata
                la sezione Menù.<br />
                Il banchetto nunziale è forse la parte più importante di un
                matrimonio. Chi vorrebbe partecipare ad un matrimonio senza poi
                poter andare a festeggiare con amici e parenti la nuova unione?
                Infatti eravamo davvero entusiasti di dare la possibilità ai
                nostri ospiti di sapere in anticipo cosa ci sarebbe stato al
                banchetto nunziale. Soprattutto per dare loro la possibilità di
                informarci in merito a intolleranze o allergie.<br /><br />
                Inserire le informazioni nella sezione Menù è davvero semplice.
                In un primo momento si trovano le voci “Cosa?” e l’indicazione
                sul tipo di pasto. Basta inserire tutte le informazioni e cliccare
                sul pulsante “Aggiungi”. Automaticamente la voce verrà aggiunta
                in fondo alla lista. C’è la possibilità anche di spostare le varie
                voci all’interno della lista, modificarle o eventualmente
                eliminarle del tutto. Alla fine basterà cliccare sul tasto
                “Salva cambiamenti” ed il vostro Menù sarà pronto per essere
                visionato dai vostri invitati.<br /><br />
                Dopo aver comunicato la bella notizia ai nostri invitati,
                abbiamo dato loro gli accessi per la piattaforma. In particolare
                nella sezione Menù, scorrendo tra le varie portate, i nostri
                invitati hanno potuto vedere che tipo di alimenti sarebbero stati
                presenti nel menù nunziale. Lo stesso giorno sono stata
                contattata da una mia cugina che, accorgendosi della presenza
                di crostacei nel nostro banchetto, mi informava dell’allergia
                di suo figlio a questo alimento. In questo modo abbiamo potuto
                informare il ristorante ed evitare spiacevoli contrattempi
                durante il nostro giorno speciale.”
                </p>
                <p className="text-right">
                  <img src="img/sabrina.png" className="author" width="50" alt="Sabrina di bemylove" title="Sabrina di bemylove" />
                  <i><strong>Sabrina di bemylove</strong></i>
                </p>
              </div>
            </div>
            <Interested />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default MenuSite;
