import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import { Link } from "react-router-dom";

import MetaTags from 'react-meta-tags';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight } from '@fortawesome/free-solid-svg-icons';

class ResetPassWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      sendEmail: false,
      error: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.setState({error: false});
    axios.post(process.env.REACT_APP_API_URL + '/reset-password/', { email: this.state.value } )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.sendEmail( response.data.user_id );
            this.setState({sendEmail: true});
          }
          else {
            // NO
            this.setState({error: true});
          }
        });
    event.preventDefault();
  }

  sendEmail( user_id ) {

    let input = {
      user_id: user_id,
      url: window.location.host
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-reset-password/', input);

  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>Reset Password - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Reset Password - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div id="login" className="">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row" style={ this.state.sendEmail ? { display: 'block' } : { display: 'none' } }>
            <div className="col-md-6 offset-md-3 text-center code-password">
              <p>Ti abbiamo inviato un email!</p>
            </div>
          </div>
            <div className="row" style={ !this.state.sendEmail ? { display: 'block' } : { display: 'none' } }>
              <div className="col-md-6 offset-md-3">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group pos-ipad">
                    <label htmlFor="access-app">Inserisci l'email con cui avete registrato il vostro matrimonio</label>
                    <input
                      type="text"
                       ref="email" type="email" id="email"
                      aria-describedby="Inserisci l'email con cui avete registrato il vostro matrimonio"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      value={this.state.value}
                      onChange={this.handleChange} />
                    <p id="error-login" style={this.state.error ? { display: 'block' } : {} }>Email non corretta o account non attivo.</p>
                    <small>ATTENZIONE: Il recupero della password è permesso solo agli sposi. Se sei un invitato, chiedi agli sposi il "Codice accesso Invitati" per accedere.</small>
                  </div>
                  <div className="row">
                    <div className="col-md-5 offset-md-4">
                      <button type="submit" className="btn-submit">Reset <FontAwesomeIcon icon="chevron-right" /></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default ResetPassWord;
