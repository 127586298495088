import React, { Component } from 'react';
import moment from 'moment';


const SVGCircle = ({ radius }) => (
    <svg className="countdown-svg">
        <path
            fill="none"
            stroke="#d77eac"
            strokeWidth="4"
            d={describeArc(150, 50, 48, 0, radius)}
        />
    </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);
    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers

function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      to: this.props.to,
      ctrl: this.props.ctrl
    };

    this.count = this.count.bind(this);

  }

  componentWillUnmount() {
      if (this.interval) {
          clearInterval(this.interval);
      }
    }

  componentWillReceiveProps(nextProps) {
    if( nextProps.to !== this.state.to ) {
        this.setState({
          to: nextProps.to
        });
        this.count( nextProps.to );
        return true;
    }
    return false;
  }

  count( date ) {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1]+","+myDate[2]+","+myDate[0];
    var countDownDate = new Date(newDate + ' 00:00:00');

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      // + minutes + "m " + seconds + "s ";
      this.setState({ days, hours, minutes, seconds });


      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);

        //document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  }

  render() {

    const { days, hours, minutes, seconds } = this.state;

    // Mapping the date values to radius values
    const daysRadius = mapNumber(days, 30, 0, 0, 360);
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    if ( days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0 ) {
      return (
        <div>
          <div className={ this.state.ctrl == 0 ? 'row-count content-final-countdown' : 'row-content content-final-countdown' }>
            <div className="row">
              <div className="col-lg-12">
                <h3 className="text-center">Auguri!!</h3>
              </div>
              <div className="content-img-countdown">
                <img src="/img/cat_heart.png" />
                <img src="/img/heart.png" />
                <img src="/img/cat_heart.png" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={ this.state.ctrl == 0 ? 'row-count' : 'row-content' }>
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center">Il Matrimonio sarà tra</h3>
            </div>
            <div className="col-lg-12">
              <div className="countdown-wrapper">
                  {(days || days == 0) && (
                      <div className="countdown-item">
                          <SVGCircle radius={days <= 30 ? daysRadius : 0} />
                          {days}
                          <span>Giorni</span>
                      </div>
                  )}
                  {(hours || hours == 0) && (
                      <div className="countdown-item">
                          <SVGCircle radius={hoursRadius} />
                          {hours}
                          <span>Ore</span>
                      </div>
                  )}
                  {(minutes || minutes == 0) && (
                      <div className="countdown-item">
                          <SVGCircle radius={minutesRadius} />
                          {minutes}
                          <span>Minuti</span>
                      </div>
                  )}
                  {(seconds || seconds == 0) && (
                      <div className="countdown-item">
                          <SVGCircle radius={secondsRadius} />
                          {seconds}
                          <span>Secondi</span>
                      </div>
                  )}
              </div>
            </div>
          </div>
        </div>
    </div>
  )};
}

export default Countdown;
