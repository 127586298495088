import React, { Component } from 'react';
import axios from 'axios';

import Footer from '../partials/Footer';
import Header from '../partials/Header';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faHeart } from '@fortawesome/free-solid-svg-icons';

import MetaTags from 'react-meta-tags';

class Survey1 extends Component {
  constructor(props) {

    super(props);

    this.state = {
      success: false,
      error: false,
      vote: 0,
      q1: 'Perché ti sei iscritto?',
      q2: 'Quale sezione è più utile e perché?',
      q3: 'Dai un voto generale al sito da 1 a 5',
      q4: 'Dicci in cosa potremmo migliorarci.'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.hoverVote = this.hoverVote.bind(this);
    this.hoverOutVote = this.hoverOutVote.bind(this);
    this.setVote = this.setVote.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementsByClassName("nav-cta")[0].style.display = "none";
  }

  handleSubmit(event) {
    let input = {
      q1: this.state.q1,
      a1: this.refs.a1.value,
      q2: this.state.q2,
      a2: this.refs.a2.value,
      q3: this.state.q3,
      a3: this.state.vote,
      q4: this.state.q4,
      a4: this.refs.a4.value,
      email: this.refs.email.value
    };

    axios.post(process.env.REACT_APP_API_URL + '/survey-1/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK

            let inputAdmin = {
              ctrl: 'survey'
            };

            axios.post(process.env.REACT_APP_API_URL + '/send-email-admin/', inputAdmin);

            this.setState({
              success: true,
              error: false,
            });
            document.getElementsByTagName("form")[0].style.display = "none";

            window.scrollTo(0, 0);
          }
          else {
            // NO
            document.getElementsByTagName("form")[0].style.display = "none";
            this.setState({
              success: false,
              error: true,
            });
          }

        });
    event.preventDefault();
  }

  hoverVote( n ) {
    for( let i = 0; i < n; i++ ) {
      document.getElementsByClassName("vote")[i].style.color = 'rgba(167, 57, 114, 1)';
    }
  }

  hoverOutVote( n ) {
    if( this.state.vote == 0 ) {
      for( let i = 0; i <= 5; i++ ) {
        if( document.getElementsByClassName("vote")[i] ) {
          document.getElementsByClassName("vote")[i].style.color = '#d77eac';
        }
      }
    }
    else {
      for( let i = this.state.vote; i <= 5; i++ ) {
        if( document.getElementsByClassName("vote")[i] ) {
          document.getElementsByClassName("vote")[i].style.color = '#d77eac';
        }
      }
    }
  }

  hoverVote( n ) {
    for( let i = 0; i < n; i++ ) {
      document.getElementsByClassName("vote")[i].style.color = 'rgba(167, 57, 114, 1)';
    }
  }

  setVote( n ) {

    for( let i = 0; i <= 5; i++ ) {
      if( document.getElementsByClassName("vote")[i] ) {
        document.getElementsByClassName("vote")[i].style.color = '#d77eac';
      }
    }
    this.setState({
      vote: n
    });
    for( let i = 0; i < n; i++ ) {
      document.getElementsByClassName("vote")[i].style.color = 'rgba(167, 57, 114, 1)';
    }
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="bemylove - Il tuo wedding planner digitale!" />
          <meta property="og:url" content="https://bemylove.it/" />
          <link rel="canonical" href="https://bemylove.it/"/>
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site UnSubscribe">
          <div className="cover" style={{ backgroundImage: 'none', paddingBottom: '9px', marginBottom: '0'}}>
            <h1 className="text-center">Cosa ne pensi?</h1>
          </div>
          <div className="row" style={{ backgroundColor: 'white' }}>
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <p style={ this.state.success == false && this.state.error == false ? { display: 'block', width: '100%' } : { display: 'none' } }>
              Ciao,<br />
              è da un po' che non ti vediamo su <strong>bemylove.it!</strong><br /><br />
              Per noi è importante creare uno strumento utile per i futuri sposi e
              vorremmo migliorarci sempre più per potervi aiutare nell'organizzazione del vostro grande giorno.<br /><br />
              Ci farebbe piacere sapere cosa ne pensi del nostro sito.
              Rispondi a queste semplici domande, ci vorrà solo qualche minuto.
              </p>
            </div>
            <form style={{ width: '100%'}}>
              <div className="row">
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      { this.state.q1 }<br />
                    </label>
                    <textarea ref="a1" id="a1" rows="6" autoComplete="off"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      { this.state.q2 }<br />
                    </label>
                    <textarea ref="a2" id="a2" rows="6" autoComplete="off"></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      { this.state.q3 }:<br />
                      <small style={{ color: 'black' }}>(1 pessimo - 5 utilissimo)</small><br />
                    </label>
                    <div className="content-vote">
                      <FontAwesomeIcon icon="heart" className="vote" onMouseOver={(e) => this.hoverVote(1)} onMouseOut={(e) => this.hoverOutVote( 1 )} onClick={(e) => this.setVote( 1 )} />
                      <FontAwesomeIcon icon="heart" className="vote" onMouseOver={(e) => this.hoverVote(2)} onMouseOut={(e) => this.hoverOutVote( 2 )} onClick={(e) => this.setVote( 2 )} />
                      <FontAwesomeIcon icon="heart" className="vote" onMouseOver={(e) => this.hoverVote(3)} onMouseOut={(e) => this.hoverOutVote( 3 )} onClick={(e) => this.setVote( 3 )} />
                      <FontAwesomeIcon icon="heart" className="vote" onMouseOver={(e) => this.hoverVote(4)} onMouseOut={(e) => this.hoverOutVote( 4 )} onClick={(e) => this.setVote( 4 )} />
                      <FontAwesomeIcon icon="heart" className="vote" onMouseOver={(e) => this.hoverVote(5)} onMouseOut={(e) => this.hoverOutVote( 5 )} onClick={(e) => this.setVote( 5 )} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <div className="form-group">
                    <label htmlFor="descrizione">
                      { this.state.q4 }<br />
                    </label>
                    <textarea ref="a4" id="a4" rows="6" autoComplete="off"></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                    <div className="form-group">
                      <label htmlFor="email">
                        Se vuoi che il tuo account abbia l'accesso illiminato a tutte
                        le aree del sito, inserisci qui la tua email:<br />
                      </label>
                      <input ref="email" type="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" autoComplete="off" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <p className="text-right" style={{ marginBottom: '0' }}>A presto</p>
                  <p className="text-right" style={{ marginTop: '0' }}><i><strong>Sabrina di bemylove</strong></i></p>
                </div>
              </div>

              <div className="col center-single-btn">
                <button type="button" onClick={(e) =>this.handleSubmit( e ) } className="btn-submit">Invia sondaggio <FontAwesomeIcon icon="chevron-right" /></button>
              </div>
              <br /><br />
            </form>
            <div style={ this.state.success == true ? { display: 'block', width: '100%' } : { display: 'none' } }>
              <h2 className="text-center">Grazie!</h2>
              <p className="text-center text-rsl">
                Se hai inserito la tua email, entro 24 ore verrà attivato beSilver,
                l'opzione che ti permetterà di accedere a tutte le aree di <strong>bemylove</strong> gratuitamente per sempre.
              </p>
              <br /><br />
            </div>
            <div style={ this.state.error == true ? { display: 'block', width: '100%' } : { display: 'none' } }>
              <p className="text-center text-rsl">
                Si è verificato un errore durante l'invio della survey.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Survey1;
