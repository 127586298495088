import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Interested from '../../partials/Interested';

import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

class ArticleThirteen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthImage: 250
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      widthImage: document.querySelector('.content').offsetWidth
    });
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>bemylove - Cerchi il fotografo per il matrimonio? Scegli quello giusto grazie ai nostri consigli!</title>
          <meta name="description" content="Il fotografo è prima di tutto un artista. Egli deve saper cogliere tutti i momenti essenziali del vostro matrimonio e fonderli insieme con il suo stile, realizzando dei ricordi magici sotto forma di foto e video che vi emozioneranno ogni volta che li rivedrete." />
          <meta property="og:title" content="Cerchi il fotografo per il matrimonio? Scegli quello giusto grazie ai nostri consigli!" />
          <meta property="og:url" content="https://bemylove.it/blog/fotografo-per-il-matrimonio-come-scegliere-quello-giusto" />
          <link rel="canonical" href="https://bemylove.it/blog/fotografo-per-il-matrimonio-come-scegliere-quello-giusto" />
        </MetaTags>
        <Header ctrl="0" />
        <div className="Site Article">
          <div className="cover" style={{ backgroundImage: 'url(../media/blog/thirteen/bemylove-one.jpg)'}}>
            <div className="opacity"></div>
            <h1>Cerchi il fotografo per il matrimonio? Scegli quello giusto grazie ai nostri consigli! </h1>
            {/* Tra i 30 e i 70 caratteri. */}
          </div>
          <div className="content-page-article">
            <div className="col-sponsor">
              <div className="sponsor">
                {/* Pubblicità 1 */}
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='3579461867'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
            <div className="col-article">
              <div className="time">
                <time>
                  20/01/2021
                </time>
              </div>
              <div className="content">
                Il fotografo è prima di tutto un artista. Egli deve saper cogliere
                tutti i momenti essenziali del vostro matrimonio e fonderli insieme
                con il suo stile, realizzando dei ricordi magici sotto forma di
                foto e video che vi emozioneranno ogni volta che li rivedrete.
                {/* Massimo 160 caratteri. */}
                <hr />
                <a href="https://bemylove.it/controllo-budget" target="_blank">
                  Hai già calcolato il budget per il fotografo? Inserisci questa
                  spesa nel controllo del budget di Bemylove e non pensarci più!
                </a>
                <hr />
                Scegliere il fotografo è davvero un passo importante nell’organizzazione
                del matrimonio. Volete che le vostre foto e il video delle nozze
                abbiano uno stile tradizionale, spontaneo o creativo?
                Quali dettagli volete che risalti nei suoi scatti? Scegliete
                innanzitutto gli elementi e lo stile che desiderate dare ai
                vostri ricordi.
                <br /><br />
                <div>
                  <img src="../media/blog/thirteen/one.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                </div>
                <br /><br />
                Potete scegliere un fotografo dallo stile tradizionale, colui che
                coglie tutti i momenti salienti della tradizione, dall’arrivo
                degli sposi fino al taglio della torta e alla distribuzione delle
                bomboniere. Egli conosce bene i tempi e i momenti che devono
                essere catturati, inoltre sarà sempre presente e vi chiederà di
                mettervi in posa, ma sarà un servizio veloce e probabilmente
                anche abbastanza economico. Esistono anche vari fotografi dallo
                stile spontaneo. Un fotografo con questo stile saprà cogliere
                in modo magico tutti momenti delle nozze. A meno che non lo
                concordiate, probabilmente non vi chiederà una posa tradizionale.
                Ma, scatterà continuamente; cercando di cogliere l’emozione, la
                sorpresa, la gioia, l’amore in voi e nei vostri invitati.
                Se invece amate le foto fuori dal comune, scegliere un fotografo
                creativo sarà la scelta giusta. Egli riempirà l’album di foto
                con giochi di luce e foto inaspettate. Il fotografo creativo
                pone la sua attenzione ad elementi come la luce, le atmosfere,
                alla ricerca del particolare e del gesto. È molto attento alle
                espressioni. Per questo probabilmente sarà meno economico rispetto
                ad altri, ma ne varrà la pena.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='9269684507'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Se avete ben chiaro lo stile, dovete considerare che durante il
                matrimonio, il fotografo dovrà essere in grado di riprendere anche
                tutte le altre situazioni della giornata come i preparativi, il
                ricevimento ecc, non solo i momenti di coppia. In ognuno di
                questi momenti il fotografo dovrà saper far fronte a tutti i
                generi fotografici. È bene quindi sfogliare le varie gallery
                presenti sul sito in cui vi sarà l’estratto dei servizi dei suoi
                matrimoni.
                <br /><br />
                <img src="../media/blog/thirteen/two.jpg" width={ this.state.widthImage } title="bemylove" alt="bemylove" />
                <br /><br />
                Poiché anche questa sarà una spesa che dovrà essere ben programmata
                in anticipo, definisci un budget e tieni a mente che mediamente
                si deve investire il 10% del budget per assicurarsi un
                professionista di altissimo livello che faccia un lavoro perfetto,
                regalandoti un album di nozze unico e personalizzato.
                <br /><br />
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-3719438501500992'
                    slot='8518196842'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <br /><br />
                Ricercate poi il professionista sui vari portali di fornitori
                per matrimoni e prendete appuntamento con lui per un colloquio
                di persona. Prepara delle domande per capire come lavorerà durante
                il matrimonio. Se vuoi che tutto sia personalizzato, pensa a
                qualche suggerimento da dare al fotografo perché lavori nel modo
                che preferisci. Pensiamo che dobbiate infatti creare un feeling
                con il vostro fotografo poiché quando arriverà il grande momento,
                dovrai fidarti del fotografo e dei suoi collaboratori, dando loro
                la totale libertà di scelta e di iniziativa mentre voi siete alle
                prese con tutte le emozioni di quel grande giorno.
                <br /><br />
              </div>
            </div>
            <div className="col-sponsor">
              <div className="sponsor">
                <AdSense.Google
                  client='ca-pub-3719438501500992'
                  slot='8439044486'
                  style={{ display: 'block' }}
                  format='auto'
                  responsive='true'
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <Link to="/cose-da-sapere-prima-di-sposarsi">
            <div className="button" style={{backgroundColor : '#ef5aa7', width: '250px', margin: '0 auto', textAlign: 'center'}}>
              Scopri altre curiosità sul matrimonio
            </div>
          </Link>
        </div>
        <hr />
        <Interested />
        <Footer />
      </div>
    );
  }
}

export default ArticleThirteen;
