import React, { Component } from 'react';
import axios from 'axios';
// import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import { Link } from "react-router-dom";

import MetaTags from 'react-meta-tags';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight } from '@fortawesome/free-solid-svg-icons';

class GetPassWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false,
      viewCode: false,
      code: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.setState({error: false});
    let input = {
      token: this.props.match.params.token,
      code: this.state.value
    };

    axios.post(process.env.REACT_APP_API_URL + '/view-code/', input )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.setState({
              viewCode: true,
              code: response.data.code
            });
          }
          else {
            // NO
            this.setState({error: true});
          }
        });
    event.preventDefault();
  }

  sendEmail( user_id ) {

    // let input = {
    //   user_id: user_id,
    //   url: window.location.host
    // };
    //
    // axios.post(process.env.REACT_APP_API_URL + '/send-email-reset-password/', input);

  }

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>Reset Password - bemylove - Il tuo wedding planner digitale!</title>
          <meta name="description" content="Tieni l'organizzazione del tuo matrimonio sotto controllo. Comunica in maniera semplice con gli invitati!" />
          <meta property="og:title" content="Reset Password - bemylove - Il tuo wedding planner digitale!" />
        </MetaTags>
        <div id="login" className="">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img src="/img/bemylove_logo.png" width="250" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
          <div className="row" style={ this.state.viewCode ? { display: 'block' } : { display: 'none' } }>
            <div className="col-md-6 offset-md-3 text-center code-password">
              Il codice di accesso Sposi è: <br /><h2>{ this.state.code }</h2>
              <p>Salvalo e inseriscilo al login!</p>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <Link to="/login">
                  <div className="btn-submit text-center" style={{backgroundColor : '#ef5aa7'}}>Accedi <FontAwesomeIcon icon="sign-in-alt" /></div>
                </Link>
              </div>
            </div>
          </div>
            <div className="row" style={ !this.state.viewCode ? { display: 'block' } : { display: 'none' } }>
              <div className="col-md-6 offset-md-3">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group pos-ipad">
                    <label htmlFor="access-app">Inserisci il codice ricevuto per email</label>
                    <input
                      type="text"type="text"
                      id="access-app"
                      aria-describedby="Inserisci qui il Codice"
                      maxLength="8"
                      value={this.state.value}
                      onChange={this.handleChange} />
                    <p id="error-login" style={this.state.error ? { display: 'block' } : {} }>Codice errato.</p>
                    <small>ATTENZIONE: Il recupero della password è permesso solo agli sposi. Se sei un invitato, chiedi agli sposi il "Codice accesso Invitati" per accedere.</small>
                  </div>
                  <div className="row">
                    <div className="col-md-5 offset-md-4">
                      <button type="submit" className="btn-submit">Visualizza <FontAwesomeIcon icon="chevron-right" /></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default GetPassWord;
