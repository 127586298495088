import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

library.add({
  faPlay
});

class VideoTutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: props.url
    }

  }

  render() {
    return (
      <a href={ this.state.url } target="_blank" className="close-button button-play tooltip" rel="noopener noreferrer">
        <FontAwesomeIcon icon="play" />
        <span className="tooltiptext">Guarda il video</span>
      </a>
  )};
}

export default VideoTutorial;
