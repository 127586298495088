import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import Countdown from '../../partials/countdown/Countdown';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

library.add(
  faHeart
)

class HomeLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      general: {},
      image64: '',
      hideDatePc: window.innerWidth < 1000 ? true : false,
      hideDateMobile: window.innerWidth < 1000 ? false : true
    };
  }

  componentDidMount() {

    if( this.state.hideDatePc ) {
        document.querySelectorAll(".Married")[0].classList.remove("container");
        document.querySelectorAll("#register")[0].removeAttribute("id");
        //document.querySelectorAll(".imageWedding")[0].setAttribute("style", "height: 100vh; max-height: 100vh; padding-top: 60px;");
        document.querySelectorAll(".imageWedding")[0].classList.add("imageWeddingMobile");
    }

    let value = {
      id: this.state.id,
      token: localStorage.getItem("bml_session")
    }
    axios.post(process.env.REACT_APP_API_URL + '/get-general/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            let sposo = response.data.rls.sposo.split(" ")[0];
            let sposa = response.data.rls.sposa.split(" ")[0];
            response.data.rls.sposo = sposo;
            response.data.rls.sposa = sposa;
            this.setState({
              general: response.data.rls
            });

            let valueBase64 = {
              id: this.state.id,
              image: this.state.general.image,
              path: 'photo',
              token: localStorage.getItem("bml_session")
            }

            axios.post(process.env.REACT_APP_API_URL + '/get-base64/', valueBase64 )
                .then(response => {
                  this.setState({
                    image64: response.data.rls
                  });
                });

            if(response.data.rls.image == undefined) {
              document.querySelectorAll(".imageWedding")[0].setAttribute("style", "background-image: url(https://source.unsplash.com/weekly?wedding)");
            }


          }
          else {
            // NO
            localStorage.removeItem("bml_session");
            window.top.location.href = '/';
          }
        });
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({
    //   image: nextProps.image
    // });
  }

  render() {
    return (
      <div id="register" className="General">
      <div className="imageWedding" style={{backgroundImage: 'url("' + this.state.image64 + '")' }}>
        <h4>{ this.state.general.sposo } & { this.state.general.sposa }</h4>
        <Countdown to={ this.state.general.data } ctrl="0" />
        <div className="bottonCover">
        <div className="row text-center">
          <div className="col-12" style={ this.state.hideDateMobile == true ? { display: 'none'} : { display: 'block'} }>
            <Moment format="DD/MM/YYYY">
              {this.state.general.data}
            </Moment>
          </div>
          <div className="col-6 col-lg-4">
            <a href={"https://www.google.it/maps/place/" + this.state.general.chiesa } target="_blank" rel="noopener noreferrer" className="bml-icon-large"><FontAwesomeIcon icon="church" /></a>
          </div>
          <div className="col-lg-4" style={ this.state.hideDatePc == true ? { display: 'none'} : { display: 'block'} }>
            <Moment format="DD/MM/YYYY">
              {this.state.general.data}
            </Moment>
          </div>
          <div className="col-6 col-lg-4">
            <a href={"https://www.google.it/maps/place/" + this.state.general.ricevimento } target="_blank" rel="noopener noreferrer" className="bml-icon-large"><FontAwesomeIcon icon="glass-cheers" /></a>
          </div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default HomeLog;
